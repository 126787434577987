export const trainStations = {
  TrainStation: [
    {
      Advertised: true,
      AdvertisedLocationName: "Alingsås",
      Geometry: {
        WGS84: "POINT (12.53218546120595 57.92690516993427)",
      },
      LocationSignature: "A",
      OfficialLocationName: "Alingsås",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Annedal",
      Geometry: {
        WGS84: "POINT (12.92963402035937 56.12755457831888)",
      },
      LocationSignature: "Aal",
      OfficialLocationName: "Annedal",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Arbrå",
      Geometry: {
        WGS84: "POINT (16.37983461815908 61.47103479246544)",
      },
      LocationSignature: "Ab",
      OfficialLocationName: "Arbrå",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Abborrträsk",
      Geometry: {
        WGS84: "POINT (19.41057490018656 65.44645107836006)",
      },
      LocationSignature: "Abb",
      OfficialLocationName: "Abborrträsk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Avesta centrum",
      Geometry: {
        WGS84: "POINT (16.17060693896951 60.1472930337373)",
      },
      LocationSignature: "Acm",
      OfficialLocationName: "Avesta centrum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Alfta",
      Geometry: {
        WGS84: "POINT (16.071339801158693 61.3455456378038)",
      },
      LocationSignature: "Aft",
      OfficialLocationName: "Alfta",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Anneberg",
      Geometry: {
        WGS84: "POINT (12.10077643689166 57.538628927780955)",
      },
      LocationSignature: "Ag",
      OfficialLocationName: "Anneberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Agnesberg",
      Geometry: {
        WGS84: "POINT (12.007126103824072 57.78105359528368)",
      },
      LocationSignature: "Agb",
      OfficialLocationName: "Agnesberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Algutsgården",
      Geometry: {
        WGS84: "POINT (12.651869975240636 57.98813629760002)",
      },
      LocationSignature: "Agg",
      OfficialLocationName: "Algutsgården",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Aleholm",
      Geometry: {
        WGS84: "POINT (14.663463013204055 57.398216699534224)",
      },
      LocationSignature: "Ahm",
      OfficialLocationName: "Aleholm",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Aitik",
      Geometry: {
        WGS84: "POINT (20.768235953833813 67.0740146634426)",
      },
      LocationSignature: "Aik",
      OfficialLocationName: "Aitik",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Arvidsjaur",
      Geometry: {
        WGS84: "POINT (19.168451956162333 65.58870575519173)",
      },
      LocationSignature: "Ajr",
      OfficialLocationName: "Arvidsjaur",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Abisko Östra",
      Geometry: {
        WGS84: "POINT (18.830346214296274 68.3486410812835)",
      },
      LocationSignature: "Ak",
      OfficialLocationName: "Abisko östra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Abisko turiststation",
      Geometry: {
        WGS84: "POINT (18.78326035226819 68.35731624140332)",
      },
      LocationSignature: "Akt",
      OfficialLocationName: "Abisko turiststation",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Arlöv",
      Geometry: {
        WGS84: "POINT (13.068412053761493 55.633863798691216)",
      },
      LocationSignature: "Al",
      OfficialLocationName: "Arlöv",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Alvhem",
      Geometry: {
        WGS84: "POINT (12.157685045241994 58.010841208432964)",
      },
      LocationSignature: "Alh",
      OfficialLocationName: "Alvhem",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Astrid Lindgrens värld",
      Geometry: {
        WGS84: "POINT (15.840063951305682 57.67270729523255)",
      },
      LocationSignature: "Alv",
      OfficialLocationName: "Astrid Lindgrens värld",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Almnäs",
      Geometry: {
        WGS84: "POINT (17.507802824548527 59.169404149769846)",
      },
      LocationSignature: "Alä",
      OfficialLocationName: "Almnäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Almedal",
      Geometry: {
        WGS84: "POINT (12.003992324656123 57.68402897333347)",
      },
      LocationSignature: "Am",
      OfficialLocationName: "Almedal",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Arnemark",
      Geometry: {
        WGS84: "POINT (21.217933827304552 65.49127557165512)",
      },
      LocationSignature: "Amk",
      OfficialLocationName: "Arnemark",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Arvån",
      Geometry: {
        WGS84: "POINT (19.003010968874246 64.55835734303155)",
      },
      LocationSignature: "An",
      OfficialLocationName: "Arvån",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Aneby",
      Geometry: {
        WGS84: "POINT (14.81189626871035 57.837434576066045)",
      },
      LocationSignature: "Any",
      OfficialLocationName: "Aneby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Anundsjö",
      Geometry: {
        WGS84: "POINT (18.143823651881625 63.42664884864176)",
      },
      LocationSignature: "Anö",
      OfficialLocationName: "Anundsjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Aspeå",
      Geometry: {
        WGS84: "POINT (17.51364565759834 63.3876933956481)",
      },
      LocationSignature: "Ap",
      OfficialLocationName: "Aspeå",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Aplared",
      Geometry: {
        WGS84: "POINT (13.074416477742151 57.6512374770022)",
      },
      LocationSignature: "Apd",
      OfficialLocationName: "Aplared",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Aspen",
      Geometry: {
        WGS84: "POINT (12.240512241673448 57.75442205677955)",
      },
      LocationSignature: "Apn",
      OfficialLocationName: "Aspen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Aptas",
      Geometry: {
        WGS84: "POINT (20.443372763603172 67.76409670297427)",
      },
      LocationSignature: "Apt",
      OfficialLocationName: "Aptas",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Arvika",
      Geometry: {
        WGS84: "POINT (12.59080337767365 59.65363416376943)",
      },
      LocationSignature: "Ar",
      OfficialLocationName: "Arvika",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Arnäsvall",
      Geometry: {
        WGS84: "POINT (18.78594341960494 63.308020684331325)",
      },
      LocationSignature: "Ara",
      OfficialLocationName: "Arnäsvall",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Arboga",
      Geometry: {
        WGS84: "POINT (15.840525578708135 59.39718938751229)",
      },
      LocationSignature: "Arb",
      OfficialLocationName: "Arboga",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Arlanda C",
      Geometry: {
        WGS84: "POINT (17.928489392445165 59.64907212086778)",
      },
      LocationSignature: "Arnc",
      OfficialLocationName: "Arlanda central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Arlanda nedre",
      Geometry: {
        WGS84: "POINT (17.923106648878097 59.63271292158976)",
      },
      LocationSignature: "Arne",
      OfficialLocationName: "Arlanda nedre",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Arlanda N",
      Geometry: {
        WGS84: "POINT (17.929839291864006 59.651521946991636)",
      },
      LocationSignature: "Arnn",
      OfficialLocationName: "Arlanda norra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Arlanda S",
      Geometry: {
        WGS84: "POINT (17.92832852166955 59.646820357358884)",
      },
      LocationSignature: "Arns",
      OfficialLocationName: "Arlanda södra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Assberg",
      Geometry: {
        WGS84: "POINT (12.668601530814378 57.49435172146559)",
      },
      LocationSignature: "Asb",
      OfficialLocationName: "Assberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Aspedalen",
      Geometry: {
        WGS84: "POINT (12.258478364737194 57.76247806662745)",
      },
      LocationSignature: "Asd",
      OfficialLocationName: "Aspedalen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Attarp",
      Geometry: {
        WGS84: "POINT (13.848710498300447 56.137202938010475)",
      },
      LocationSignature: "Atp",
      OfficialLocationName: "Attarp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Auktsjaur",
      Geometry: {
        WGS84: "POINT (19.39392891939268 65.73868142148088)",
      },
      LocationSignature: "Auk",
      OfficialLocationName: "Auktsjaur",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Alvesta",
      Geometry: {
        WGS84: "POINT (14.556191772126336 56.89802192941306)",
      },
      LocationSignature: "Av",
      OfficialLocationName: "Alvesta",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Avaviken",
      Geometry: {
        WGS84: "POINT (18.65092372526849 65.60511983140147)",
      },
      LocationSignature: "Ava",
      OfficialLocationName: "Avaviken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Almvik",
      Geometry: {
        WGS84: "POINT (16.466310525988412 57.829056229282116)",
      },
      LocationSignature: "Avk",
      OfficialLocationName: "Almvik",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Avesta Krylbo",
      Geometry: {
        WGS84: "POINT (16.216147851939283 60.12953324348506)",
      },
      LocationSignature: "Avky",
      OfficialLocationName: "Avesta Krylbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Axmarby",
      Geometry: {
        WGS84: "POINT (17.113590037738035 61.00738639069932)",
      },
      LocationSignature: "Ax",
      OfficialLocationName: "Axmarby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Axmar Bruk",
      Geometry: {
        WGS84: "POINT (17.133398856743455 61.04975303693471)",
      },
      LocationSignature: "Ax.br",
      OfficialLocationName: "Axmar Bruk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Alby",
      Geometry: {
        WGS84: "POINT (15.474700355693072 62.49014843973216)",
      },
      LocationSignature: "Ay",
      OfficialLocationName: "Alby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Alväng",
      Geometry: {
        WGS84: "POINT (15.566005650156104 59.37901056704983)",
      },
      LocationSignature: "Aä",
      OfficialLocationName: "Alväng",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Björnlunda",
      Geometry: {
        WGS84: "POINT (17.154658166569 59.065550410038206)",
      },
      LocationSignature: "B",
      OfficialLocationName: "Björnlunda",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Barkåkra",
      Geometry: {
        WGS84: "POINT (12.82463192214527 56.29365574765699)",
      },
      LocationSignature: "Baa",
      OfficialLocationName: "Barkåkra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Badsta",
      Geometry: {
        WGS84: "POINT (14.291454388831607 59.54748591611669)",
      },
      LocationSignature: "Bad",
      OfficialLocationName: "Badsta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Backsjön",
      Geometry: {
        WGS84: "POINT (17.299451329057007 63.32472291232866)",
      },
      LocationSignature: "Baö",
      OfficialLocationName: "Backsjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Blyberg",
      Geometry: {
        WGS84: "POINT (14.13778147665282 61.153276117780926)",
      },
      LocationSignature: "Bbe",
      OfficialLocationName: "Blyberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Blidstena",
      Geometry: {
        WGS84: "POINT (16.37646058090267 58.039256537785676)",
      },
      LocationSignature: "Bda",
      OfficialLocationName: "Blidstena",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Badabruk",
      Geometry: {
        WGS84: "POINT (13.096135539789634 60.06218737497479)",
      },
      LocationSignature: "Bdb",
      OfficialLocationName: "Badabruk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bodafors",
      Geometry: {
        WGS84: "POINT (14.694780136411504 57.506100139535356)",
      },
      LocationSignature: "Bdf",
      OfficialLocationName: "Bodafors",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Boden C",
      Geometry: {
        WGS84: "POINT (21.708053326414298 65.82880233547093)",
      },
      LocationSignature: "Bdn",
      OfficialLocationName: "Boden central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Broddbo",
      Geometry: {
        WGS84: "POINT (16.481882451382702 59.98691585315015)",
      },
      LocationSignature: "Bdo",
      OfficialLocationName: "Broddbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bodens södra",
      Geometry: {
        WGS84: "POINT (21.64736689362446 65.82547532973614)",
      },
      LocationSignature: "Bds",
      OfficialLocationName: "Bodens södra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bredaryd",
      Geometry: {
        WGS84: "POINT (13.737651016592572 57.17653675673727)",
      },
      LocationSignature: "Bdy",
      OfficialLocationName: "Bredaryd",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Backe",
      Geometry: {
        WGS84: "POINT (16.426909512343318 63.80732185864034)",
      },
      LocationSignature: "Be",
      OfficialLocationName: "Backe",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bengtsfors",
      Geometry: {
        WGS84: "POINT (12.234869527899678 59.02890400421548)",
      },
      LocationSignature: "Bef",
      OfficialLocationName: "Bengtsfors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bredviken",
      Geometry: {
        WGS84: "POINT (23.233153872313103 65.83738296570233)",
      },
      LocationSignature: "Ben",
      OfficialLocationName: "Bredviken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bruksberget",
      Geometry: {
        WGS84: "POINT (22.713627361914185 66.00364973657891)",
      },
      LocationSignature: "Bet",
      OfficialLocationName: "Bruksberget",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Brunflo",
      Geometry: {
        WGS84: "POINT (14.83258392961194 63.07597329456922)",
      },
      LocationSignature: "Bf",
      OfficialLocationName: "Brunflo",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Billingsfors",
      Geometry: {
        WGS84: "POINT (12.249669582513393 58.98196437736831)",
      },
      LocationSignature: "Bfo",
      OfficialLocationName: "Billingsfors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bergfors",
      Geometry: {
        WGS84: "POINT (19.78268785649656 68.14727058449392)",
      },
      LocationSignature: "Bfs",
      OfficialLocationName: "Bergfors",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Berga",
      Geometry: {
        WGS84: "POINT (16.033442979973792 57.21653280773408)",
      },
      LocationSignature: "Bg",
      OfficialLocationName: "Berga",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Berghem",
      Geometry: {
        WGS84: "POINT (12.598364454832875 57.467036793064715)",
      },
      LocationSignature: "Bgm",
      OfficialLocationName: "Berghem",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bryngenäs",
      Geometry: {
        WGS84: "POINT (12.487553829743899 57.89776038409289)",
      },
      LocationSignature: "Bgs",
      OfficialLocationName: "Bryngenäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bälgviken",
      Geometry: {
        WGS84: "POINT (16.460636576860814 59.25267821131035)",
      },
      LocationSignature: "Bgv",
      OfficialLocationName: "Bälgviken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bräkne-Hoby",
      Geometry: {
        WGS84: "POINT (15.116058353150356 56.231370135773936)",
      },
      LocationSignature: "Bhb",
      OfficialLocationName: "Bräkne-Hoby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bohus",
      Geometry: {
        WGS84: "POINT (12.01956438502742 57.86400556610953)",
      },
      LocationSignature: "Bhs",
      OfficialLocationName: "Bohus",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Birsta",
      Geometry: {
        WGS84: "POINT (17.312612828803847 62.4471206965861)",
      },
      LocationSignature: "Bia",
      OfficialLocationName: "Birsta",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Billeberga",
      Geometry: {
        WGS84: "POINT (12.995848263441582 55.883760199238544)",
      },
      LocationSignature: "Bib",
      OfficialLocationName: "Billeberga",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Billesholm",
      Geometry: {
        WGS84: "POINT (12.976830160760777 56.05640540346406)",
      },
      LocationSignature: "Bih",
      OfficialLocationName: "Billesholm",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Björna",
      Geometry: {
        WGS84: "POINT (18.596871908021676 63.551403054899886)",
      },
      LocationSignature: "Bj",
      OfficialLocationName: "Björna",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bjästa",
      Geometry: {
        WGS84: "POINT (18.444524046377495 63.211586609396704)",
      },
      LocationSignature: "Bja",
      OfficialLocationName: "Bjästa",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Björneborg",
      Geometry: {
        WGS84: "POINT (14.250384990924363 59.24435507850853)",
      },
      LocationSignature: "Bjb",
      OfficialLocationName: "Björneborg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bjørnfjell",
      Geometry: {
        WGS84: "POINT (18.071086739827635 68.43214638196257)",
      },
      LocationSignature: "Bjf",
      OfficialLocationName: "Bjørnfjell",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bjurhem",
      Geometry: {
        WGS84: "POINT (12.280053125646678 58.42846620040976)",
      },
      LocationSignature: "Bjh",
      OfficialLocationName: "Bjurhem",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bjärnum",
      Geometry: {
        WGS84: "POINT (13.704058721808993 56.29292688043267)",
      },
      LocationSignature: "Bjm",
      OfficialLocationName: "Bjärnum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Björnkulla",
      Geometry: {
        WGS84: "POINT (17.928581196472948 59.20375584268418)",
      },
      LocationSignature: "Bjn",
      OfficialLocationName: "Björnkulla",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Björketorp",
      Geometry: {
        WGS84: "POINT (12.523944501737624 57.42792596794638)",
      },
      LocationSignature: "Bjo",
      OfficialLocationName: "Björketorp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bjuv",
      Geometry: {
        WGS84: "POINT (12.911074025042494 56.08256743731184)",
      },
      LocationSignature: "Bjuv",
      OfficialLocationName: "Bjuv",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Björköby",
      Geometry: {
        WGS84: "POINT (14.910562163614289 57.5210524686644)",
      },
      LocationSignature: "Bjy",
      OfficialLocationName: "Björköby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Björnsjö",
      Geometry: {
        WGS84: "POINT (18.460313302983852 63.50766867606913)",
      },
      LocationSignature: "Bjö",
      OfficialLocationName: "Björnsjö",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Barkarby",
      Geometry: {
        WGS84: "POINT (17.86367834371055 59.4054464125127)",
      },
      LocationSignature: "Bkb",
      OfficialLocationName: "Barkarby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ballingslöv",
      Geometry: {
        WGS84: "POINT (13.848391969821044 56.21403392444775)",
      },
      LocationSignature: "Bl",
      OfficialLocationName: "Ballingslöv",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Blädinge",
      Geometry: {
        WGS84: "POINT (14.504356836603698 56.84150760056195)",
      },
      LocationSignature: "Bld",
      OfficialLocationName: "Blädinge",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Borlänge C",
      Geometry: {
        WGS84: "POINT (15.42547212652746 60.48294313492728)",
      },
      LocationSignature: "Blg",
      OfficialLocationName: "Borlänge central",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Björkliden",
      Geometry: {
        WGS84: "POINT (18.686353433375523 68.4083872371577)",
      },
      LocationSignature: "Bln",
      OfficialLocationName: "Björkliden",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Burlöv",
      Geometry: {
        WGS84: "POINT (13.080395596992465 55.640918231225484)",
      },
      LocationSignature: "Blv",
      OfficialLocationName: "Burlöv",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Blomstermåla",
      Geometry: {
        WGS84: "POINT (16.332114010417143 56.98071116533791)",
      },
      LocationSignature: "Bma",
      OfficialLocationName: "Blomstermåla",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Blomberg",
      Geometry: {
        WGS84: "POINT (13.32052028569632 58.54015271258101)",
      },
      LocationSignature: "Bmb",
      OfficialLocationName: "Blomberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bromölla",
      Geometry: {
        WGS84: "POINT (14.48161953808914 56.06716458747122)",
      },
      LocationSignature: "Bml",
      OfficialLocationName: "Bromölla",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Båramo",
      Geometry: {
        WGS84: "POINT (14.090655698146163 57.45356036001008)",
      },
      LocationSignature: "Bmo",
      OfficialLocationName: "Båramo",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bollnäs",
      Geometry: {
        WGS84: "POINT (16.391720410683643 61.349484744524425)",
      },
      LocationSignature: "Bn",
      OfficialLocationName: "Bollnäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brunna",
      Geometry: {
        WGS84: "POINT (17.42894154212918 59.86135632825085)",
      },
      LocationSignature: "Bna",
      OfficialLocationName: "Brunna",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Blattnicksele",
      Geometry: {
        WGS84: "POINT (17.589835935876906 65.34475583583315)",
      },
      LocationSignature: "Bnk",
      OfficialLocationName: "Blattnicksele",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Borgstena",
      Geometry: {
        WGS84: "POINT (13.014832928384235 57.882975231064876)",
      },
      LocationSignature: "Boa",
      OfficialLocationName: "Borgstena",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Boda",
      Geometry: {
        WGS84: "POINT (17.008064210049056 61.5904846664003)",
      },
      LocationSignature: "Boda",
      OfficialLocationName: "Boda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bofors",
      Geometry: {
        WGS84: "POINT (14.546926136891264 59.33331086848282)",
      },
      LocationSignature: "Bof",
      OfficialLocationName: "Bofors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bodaborg",
      Geometry: {
        WGS84: "POINT (16.375557510628752 62.45934815754367)",
      },
      LocationSignature: "Bog",
      OfficialLocationName: "Bodaborg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bomansberget",
      Geometry: {
        WGS84: "POINT (17.24179487616588 60.65076181533711)",
      },
      LocationSignature: "Bom",
      OfficialLocationName: "Bomansberget",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Botorp",
      Geometry: {
        WGS84: "POINT (14.463138775048815 59.31669335842499)",
      },
      LocationSignature: "Bop",
      OfficialLocationName: "Botorp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bor",
      Geometry: {
        WGS84: "POINT (14.168643533803387 57.11613155753609)",
      },
      LocationSignature: "Bor",
      OfficialLocationName: "Bor",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Born",
      Geometry: {
        WGS84: "POINT (14.642970546979571 61.13527041775652)",
      },
      LocationSignature: "Born",
      OfficialLocationName: "Born",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bollebygd",
      Geometry: {
        WGS84: "POINT (12.570010936961378 57.66753050378058)",
      },
      LocationSignature: "Boy",
      OfficialLocationName: "Bollebygd",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brännarp",
      Geometry: {
        WGS84: "POINT (12.72809880501553 56.78812573736821)",
      },
      LocationSignature: "Bp",
      OfficialLocationName: "Brännarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brista",
      Geometry: {
        WGS84: "POINT (17.867244912541 59.609974570890515)",
      },
      LocationSignature: "Bra",
      OfficialLocationName: "Brista",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brännland",
      Geometry: {
        WGS84: "POINT (20.056619107214942 63.88064942031483)",
      },
      LocationSignature: "Brd",
      OfficialLocationName: "Brännland",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bränninge",
      Geometry: {
        WGS84: "POINT (17.63523655054902 59.14050116995778)",
      },
      LocationSignature: "Bre",
      OfficialLocationName: "Bränninge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brännberg",
      Geometry: {
        WGS84: "POINT (21.22570634677169 65.78307636345775)",
      },
      LocationSignature: "Brg",
      OfficialLocationName: "Brännberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brattheden",
      Geometry: {
        WGS84: "POINT (16.181052461125322 59.78803488900513)",
      },
      LocationSignature: "Brh",
      OfficialLocationName: "Brattheden",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Brinellskolan",
      Geometry: {
        WGS84: "POINT (14.700936325969137 57.66942027446127)",
      },
      LocationSignature: "Brin",
      OfficialLocationName: "Brinellskolan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brokind",
      Geometry: {
        WGS84: "POINT (15.68424108161552 58.220144591128324)",
      },
      LocationSignature: "Brk",
      OfficialLocationName: "Brokind",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brålanda",
      Geometry: {
        WGS84: "POINT (12.348971725150546 58.56004200574592)",
      },
      LocationSignature: "Brl",
      OfficialLocationName: "Brålanda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brånan",
      Geometry: {
        WGS84: "POINT (14.409008789134221 62.69381192594492)",
      },
      LocationSignature: "Brn",
      OfficialLocationName: "Brånan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bro Nygård",
      Geometry: {
        WGS84: "POINT (17.672002311768015 59.49768936938677)",
      },
      LocationSignature: "Brny",
      OfficialLocationName: "Bro Nygård",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bro",
      Geometry: {
        WGS84: "POINT (17.63295834870672 59.51230001368409)",
      },
      LocationSignature: "Bro",
      OfficialLocationName: "Bro",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brattsbacka",
      Geometry: {
        WGS84: "POINT (19.185762533975904 63.78478636886831)",
      },
      LocationSignature: "Brs",
      OfficialLocationName: "Brattsbacka",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bankeryd",
      Geometry: {
        WGS84: "POINT (14.128233659324156 57.860359884866135)",
      },
      LocationSignature: "Bry",
      OfficialLocationName: "Bankeryd",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bjurå",
      Geometry: {
        WGS84: "POINT (22.14949819573756 66.03555266980803)",
      },
      LocationSignature: "Brå",
      OfficialLocationName: "Bjurå",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bredsjö",
      Geometry: {
        WGS84: "POINT (14.726917964388218 59.83203417649348)",
      },
      LocationSignature: "Brö",
      OfficialLocationName: "Bredsjö",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Borås C",
      Geometry: {
        WGS84: "POINT (12.931025017416324 57.72033164629185)",
      },
      LocationSignature: "Bs",
      OfficialLocationName: "Borås central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brastad",
      Geometry: {
        WGS84: "POINT (11.485280073022983 58.38219657133507)",
      },
      LocationSignature: "Bsa",
      OfficialLocationName: "Brastad",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bensjöbacken",
      Geometry: {
        WGS84: "POINT (15.454293299792115 62.70355707570307)",
      },
      LocationSignature: "Bsb",
      OfficialLocationName: "Bensjöbacken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Borås C",
      Geometry: {
        WGS84: "POINT (12.931025017416324 57.72033164629185)",
      },
      LocationSignature: "Bsc",
      OfficialLocationName: "Borås central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brunnsgård",
      Geometry: {
        WGS84: "POINT (15.106897221371735 57.42255129843334)",
      },
      LocationSignature: "Bsd",
      OfficialLocationName: "Brunnsgård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bispgården",
      Geometry: {
        WGS84: "POINT (16.6208847104738 63.02866497620661)",
      },
      LocationSignature: "Bsg",
      OfficialLocationName: "Bispgården",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bestorp",
      Geometry: {
        WGS84: "POINT (15.731784496661543 58.23577032146121)",
      },
      LocationSignature: "Bsp",
      OfficialLocationName: "Bestorp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bastuträsk",
      Geometry: {
        WGS84: "POINT (20.041511423083406 64.78903850483329)",
      },
      LocationSignature: "Bst",
      OfficialLocationName: "Bastuträsk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bjärka-Säby",
      Geometry: {
        WGS84: "POINT (15.752823546749028 58.270633139893796)",
      },
      LocationSignature: "Bsä",
      OfficialLocationName: "Bjärka-Säby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Baggetorp",
      Geometry: {
        WGS84: "POINT (16.06491302338619 59.00961559339919)",
      },
      LocationSignature: "Bt",
      OfficialLocationName: "Baggetorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brattby",
      Geometry: {
        WGS84: "POINT (19.881636766662776 63.910972937880366)",
      },
      LocationSignature: "Btb",
      OfficialLocationName: "Brattby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bratteborg",
      Geometry: {
        WGS84: "POINT (14.12535619399881 57.57761215306066)",
      },
      LocationSignature: "Btg",
      OfficialLocationName: "Bratteborg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Basthagen",
      Geometry: {
        WGS84: "POINT (15.974171193251644 58.20549848298768)",
      },
      LocationSignature: "Bth",
      OfficialLocationName: "Basthagen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Biskopstorp",
      Geometry: {
        WGS84: "POINT (12.858906409770492 56.74961513327154)",
      },
      LocationSignature: "Btp",
      OfficialLocationName: "Biskopstorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Betåsen",
      Geometry: {
        WGS84: "POINT (16.81365079409296 63.55201773431901)",
      },
      LocationSignature: "Btå",
      OfficialLocationName: "Betåsen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brunsbergstunneln",
      Geometry: {
        WGS84: "POINT (12.946590551026139 59.62239272612645)",
      },
      LocationSignature: "Bu",
      OfficialLocationName: "Brunsbergstunneln",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Buddbyn",
      Geometry: {
        WGS84: "POINT (21.70836865781637 65.86301074157309)",
      },
      LocationSignature: "Bud",
      OfficialLocationName: "Buddbyn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brunsberg",
      Geometry: {
        WGS84: "POINT (12.961313407331897 59.617628286934746)",
      },
      LocationSignature: "Buk",
      OfficialLocationName: "Brunsberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Buresjön",
      Geometry: {
        WGS84: "POINT (17.85301760854898 65.55365738014117)",
      },
      LocationSignature: "Bur",
      OfficialLocationName: "Buresjön",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Buterud",
      Geometry: {
        WGS84: "POINT (12.400256256684678 58.85311314783119)",
      },
      LocationSignature: "But",
      OfficialLocationName: "Buterud",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Barva",
      Geometry: {
        WGS84: "POINT (16.792764360140207 59.34341581743384)",
      },
      LocationSignature: "Bva",
      OfficialLocationName: "Barva",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Blackvreten",
      Geometry: {
        WGS84: "POINT (17.907555581954057 59.60760320890258)",
      },
      LocationSignature: "Bvr",
      OfficialLocationName: "Blackvreten",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Boxholm",
      Geometry: {
        WGS84: "POINT (15.053630708431465 58.19291890976195)",
      },
      LocationSignature: "Bx",
      OfficialLocationName: "Boxholm",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Byvalla",
      Geometry: {
        WGS84: "POINT (16.416298319631643 60.34701403883435)",
      },
      LocationSignature: "By",
      OfficialLocationName: "Byvalla",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Byarum",
      Geometry: {
        WGS84: "POINT (14.143768479917531 57.536254261754145)",
      },
      LocationSignature: "Bym",
      OfficialLocationName: "Byarum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Byälven",
      Geometry: {
        WGS84: "POINT (12.922128233057077 59.138625190765964)",
      },
      LocationSignature: "Byä",
      OfficialLocationName: "Byälven",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bruzaholm",
      Geometry: {
        WGS84: "POINT (15.275182946234725 57.641637411144806)",
      },
      LocationSignature: "Bz",
      OfficialLocationName: "Bruzaholm",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bergåsa",
      Geometry: {
        WGS84: "POINT (15.600635395304282 56.18181708915532)",
      },
      LocationSignature: "Båa",
      OfficialLocationName: "Bergåsa",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bålsta",
      Geometry: {
        WGS84: "POINT (17.531884549633357 59.56943227548269)",
      },
      LocationSignature: "Bål",
      OfficialLocationName: "Bålsta",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Båstad",
      Geometry: {
        WGS84: "POINT (12.90521282050196 56.43083500323259)",
      },
      LocationSignature: "Bån",
      OfficialLocationName: "Båstad norra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brågarp",
      Geometry: {
        WGS84: "POINT (13.226554466853987 55.64998435127479)",
      },
      LocationSignature: "Båp",
      OfficialLocationName: "Brågarp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bräcke",
      Geometry: {
        WGS84: "POINT (15.417618065421234 62.75000390773784)",
      },
      LocationSignature: "Bä",
      OfficialLocationName: "Bräcke",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bäckebron",
      Geometry: {
        WGS84: "POINT (13.166164371563617 59.66103813249547)",
      },
      LocationSignature: "Bäb",
      OfficialLocationName: "Bäckebron",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bäckefors",
      Geometry: {
        WGS84: "POINT (12.159292585129252 58.8043367046528)",
      },
      LocationSignature: "Bäf",
      OfficialLocationName: "Bäckefors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bällsta",
      Geometry: {
        WGS84: "POINT (18.07156718518678 59.52411556085246)",
      },
      LocationSignature: "Bäl",
      OfficialLocationName: "Bällsta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brännögård",
      Geometry: {
        WGS84: "POINT (13.100795510458294 57.0055108065048)",
      },
      LocationSignature: "Bög",
      OfficialLocationName: "Brännögård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Björkhult",
      Geometry: {
        WGS84: "POINT (15.66432478782094 57.8485363954598)",
      },
      LocationSignature: "Bök",
      OfficialLocationName: "Björkhult",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bodsjön",
      Geometry: {
        WGS84: "POINT (15.421300060153808 62.814644925553836)",
      },
      LocationSignature: "Bön",
      OfficialLocationName: "Bodsjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Björbo",
      Geometry: {
        WGS84: "POINT (14.728809880527523 60.45312371248342)",
      },
      LocationSignature: "Böo",
      OfficialLocationName: "Björbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Callans såg",
      Geometry: {
        WGS84: "POINT (15.99304382956859 62.51347474762201)",
      },
      LocationSignature: "Cas",
      OfficialLocationName: "Callans såg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Charlottenberg",
      Geometry: {
        WGS84: "POINT (12.29926408738461 59.8831304389512)",
      },
      LocationSignature: "Cg",
      OfficialLocationName: "Charlottenberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Karlskrona C",
      Geometry: {
        WGS84: "POINT (15.585251349670157 56.16705684627342)",
      },
      LocationSignature: "Ck",
      OfficialLocationName: "Karlskrona central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Copenhagen Airport",
      Geometry: {
        WGS84: "POINT (12.675191463290993 55.63157018507572)",
      },
      LocationSignature: "Cph",
      OfficialLocationName: "Copenhagen Airport",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kristianstad C",
      Geometry: {
        WGS84: "POINT (14.150831275057898 56.03222164074742)",
      },
      LocationSignature: "Cr",
      OfficialLocationName: "Kristianstads central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kristianstad GBG",
      Geometry: {
        WGS84: "POINT (14.15692646018577 56.04119478519893)",
      },
      LocationSignature: "Crgb",
      OfficialLocationName: "Kristianstad GBG",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kristianstads södra",
      Geometry: {
        WGS84: "POINT (14.163111814280695 56.02165801353563)",
      },
      LocationSignature: "Crs",
      OfficialLocationName: "Kristianstads södra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Stockholm Central",
      Geometry: {
        WGS84: "POINT (18.057267018575825 59.329970842872264)",
      },
      LocationSignature: "Cst",
      OfficialLocationName: "Stockholms central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Degerön",
      Geometry: {
        WGS84: "POINT (15.151806606000406 58.7015035633366)",
      },
      LocationSignature: "D",
      OfficialLocationName: "Degerön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Dammstorp",
      Geometry: {
        WGS84: "POINT (13.283171027373214 55.80190303367346)",
      },
      LocationSignature: "Dat",
      OfficialLocationName: "Dammstorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Degerbäcken",
      Geometry: {
        WGS84: "POINT (21.40180729266409 65.83949169042661)",
      },
      LocationSignature: "Dbn",
      OfficialLocationName: "Degerbäcken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Berlin",
      Geometry: {
        WGS84: "POINT (13.408053276651962 52.5186101055932)",
      },
      LocationSignature: "De.ber",
      OfficialLocationName: "Berlin",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hamburg",
      Geometry: {
        WGS84: "POINT (15.993679018678007 53.551082635921276)",
      },
      LocationSignature: "De.ham",
      OfficialLocationName: "Hamburg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Dala-Floda",
      Geometry: {
        WGS84: "POINT (14.789965497460905 60.488735658603474)",
      },
      LocationSignature: "Dfl",
      OfficialLocationName: "Dala-Floda",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Degerfors",
      Geometry: {
        WGS84: "POINT (14.440355348694279 59.22887627953472)",
      },
      LocationSignature: "Dg",
      OfficialLocationName: "Degerfors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Degermyr",
      Geometry: {
        WGS84: "POINT (19.60594176206061 63.87191259538911)",
      },
      LocationSignature: "Dgm",
      OfficialLocationName: "Degermyr",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Dalgränsen",
      Geometry: {
        WGS84: "POINT (16.45347931001231 60.38275841284857)",
      },
      LocationSignature: "Dgn",
      OfficialLocationName: "Dalgränsen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Daglösen",
      Geometry: {
        WGS84: "POINT (14.191440670749305 59.642141978237994)",
      },
      LocationSignature: "Dgö",
      OfficialLocationName: "Daglösen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Diö",
      Geometry: {
        WGS84: "POINT (14.214230624461944 56.63308576093105)",
      },
      LocationSignature: "Dis",
      OfficialLocationName: "Diö Södra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Diö Norra",
      Geometry: {
        WGS84: "POINT (14.221130155853638 56.63606700565262)",
      },
      LocationSignature: "Diö",
      OfficialLocationName: "Diö Norra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Dala-Järna",
      Geometry: {
        WGS84: "POINT (14.362710503857672 60.54767200408118)",
      },
      LocationSignature: "Dj",
      OfficialLocationName: "Dala-Järna",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Dockmyr",
      Geometry: {
        WGS84: "POINT (15.739025090276016 62.95461573375121)",
      },
      LocationSignature: "Dk",
      OfficialLocationName: "Dockmyr",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Copenhagen Airport",
      Geometry: {
        WGS84: "POINT (12.646945195249197 55.628613606316236)",
      },
      LocationSignature: "Dk.cph",
      OfficialLocationName: "Copenhagen Airport",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Helsingör",
      Geometry: {
        WGS84: "POINT (12.600007547221239 56.03333697104826)",
      },
      LocationSignature: "Dk.hg",
      OfficialLocationName: "Helsingör",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Höje Taastrup",
      Geometry: {
        WGS84: "POINT (18.297928018816638 55.646015186923115)",
      },
      LocationSignature: "Dk.höj",
      OfficialLocationName: "Höje Taastrup",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Köpenhamn H",
      Geometry: {
        WGS84: "POINT (12.568332091787717 55.67611154768882)",
      },
      LocationSignature: "Dk.kh",
      OfficialLocationName: "Köpenhamn",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Österport",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Dk.kk",
      OfficialLocationName: "Österport",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ringsted",
      Geometry: {
        WGS84: "POINT (17.78890644854053 55.444898506915564)",
      },
      LocationSignature: "Dk.rin",
      OfficialLocationName: "Ringsted",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tårnby",
      Geometry: {
        WGS84: "POINT (12.599996951760893 55.633330413220996)",
      },
      LocationSignature: "Dk.tåt",
      OfficialLocationName: "Tårnby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Örestad",
      Geometry: {
        WGS84: "POINT (12.576662812506168 55.628332371047975)",
      },
      LocationSignature: "Dk.øre",
      OfficialLocationName: "Örestad",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Dingle",
      Geometry: {
        WGS84: "POINT (11.579114455639367 58.529574092963166)",
      },
      LocationSignature: "Dl",
      OfficialLocationName: "Dingle",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Dals Långed",
      Geometry: {
        WGS84: "POINT (12.306247608273843 58.92344672881881)",
      },
      LocationSignature: "Dld",
      OfficialLocationName: "Dals Långed",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Dannemora",
      Geometry: {
        WGS84: "POINT (17.848019671309068 60.196093645075614)",
      },
      LocationSignature: "Dma",
      OfficialLocationName: "Dannemora",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Dagarn",
      Geometry: {
        WGS84: "POINT (15.717053946258057 59.908066683094674)",
      },
      LocationSignature: "Dn",
      OfficialLocationName: "Dagarn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Dynäs",
      Geometry: {
        WGS84: "POINT (17.737695151856368 62.96631789575966)",
      },
      LocationSignature: "Dns",
      OfficialLocationName: "Dynäs",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Derome",
      Geometry: {
        WGS84: "POINT (12.320145360130217 57.23055867486589)",
      },
      LocationSignature: "Dre",
      OfficialLocationName: "Derome",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Dals Rostock",
      Geometry: {
        WGS84: "POINT (12.355291043412036 58.71347461625014)",
      },
      LocationSignature: "Drt",
      OfficialLocationName: "Dals Rostock",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Dingtuna",
      Geometry: {
        WGS84: "POINT (16.389779014511614 59.57156442671425)",
      },
      LocationSignature: "Dt",
      OfficialLocationName: "Dingtuna",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Dorotea",
      Geometry: {
        WGS84: "POINT (16.40589652947879 64.2581772034087)",
      },
      LocationSignature: "Dta",
      OfficialLocationName: "Dorotea",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Dorotea camping",
      Geometry: {
        WGS84: "POINT (16.390056697411868 64.25634507080142)",
      },
      LocationSignature: "Dtc",
      OfficialLocationName: "Dorotea Campingplats",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Duved",
      Geometry: {
        WGS84: "POINT (12.91748796979737 63.38976512101647)",
      },
      LocationSignature: "Du",
      OfficialLocationName: "Duved",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Duvbo",
      Geometry: {
        WGS84: "POINT (17.932196987513084 59.37074867405395)",
      },
      LocationSignature: "Duo",
      OfficialLocationName: "Duvbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Dysjön",
      Geometry: {
        WGS84: "POINT (15.515540589911376 62.62796570903685)",
      },
      LocationSignature: "Dy",
      OfficialLocationName: "Dysjön",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Djurås",
      Geometry: {
        WGS84: "POINT (15.138550072374871 60.56008289353142)",
      },
      LocationSignature: "Då",
      OfficialLocationName: "Djurås",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Dånviken",
      Geometry: {
        WGS84: "POINT (17.71433976088987 59.18989801084126)",
      },
      LocationSignature: "Dån",
      OfficialLocationName: "Dånviken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Drömme",
      Geometry: {
        WGS84: "POINT (18.22015181180026 63.25051045911559)",
      },
      LocationSignature: "Döe",
      OfficialLocationName: "Drömme",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Döljebro",
      Geometry: {
        WGS84: "POINT (16.686870042833114 60.994000948919116)",
      },
      LocationSignature: "Döl",
      OfficialLocationName: "Döljebro",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Dösjebro",
      Geometry: {
        WGS84: "POINT (13.0328995389856 55.822984922965325)",
      },
      LocationSignature: "Dös",
      OfficialLocationName: "Dösjebro",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Eslöv",
      Geometry: {
        WGS84: "POINT (13.305373334593368 55.83768138425141)",
      },
      LocationSignature: "E",
      OfficialLocationName: "Eslöv",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Eldsberga",
      Geometry: {
        WGS84: "POINT (12.998062853920722 56.59086921205017)",
      },
      LocationSignature: "Ea",
      OfficialLocationName: "Eldsberga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Enstaberga",
      Geometry: {
        WGS84: "POINT (16.849075264036287 58.751014164260674)",
      },
      LocationSignature: "Ebg",
      OfficialLocationName: "Enstaberga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Edsbjörke",
      Geometry: {
        WGS84: "POINT (13.170993179161853 59.90125247075355)",
      },
      LocationSignature: "Ebk",
      OfficialLocationName: "Edsbjörke",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ekeby",
      Geometry: {
        WGS84: "POINT (17.7553140479258 59.776942406174726)",
      },
      LocationSignature: "Eby",
      OfficialLocationName: "Ekeby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ed",
      Geometry: {
        WGS84: "POINT (11.932872334363978 58.91348999950911)",
      },
      LocationSignature: "Ed",
      OfficialLocationName: "Ed",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Edsbyn",
      Geometry: {
        WGS84: "POINT (15.820974265740876 61.37274960801646)",
      },
      LocationSignature: "Edn",
      OfficialLocationName: "Edsbyn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Edsbyns södra",
      Geometry: {
        WGS84: "POINT (15.833337863840919 61.37402111321816)",
      },
      LocationSignature: "Eds",
      OfficialLocationName: "Edsbyns södra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Edsbyverken",
      Geometry: {
        WGS84: "POINT (15.843274977786594 61.37200268305428)",
      },
      LocationSignature: "Edv",
      OfficialLocationName: "Edsbyverken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Erikslund",
      Geometry: {
        WGS84: "POINT (15.912559695218755 62.5273896737415)",
      },
      LocationSignature: "Ei",
      OfficialLocationName: "Erikslund",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Eksjö",
      Geometry: {
        WGS84: "POINT (14.97542837041476 57.66375298049744)",
      },
      LocationSignature: "Ek",
      OfficialLocationName: "Eksjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ekolsund",
      Geometry: {
        WGS84: "POINT (17.370210482695857 59.61406263439008)",
      },
      LocationSignature: "Eko",
      OfficialLocationName: "Ekolsund",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ekeryd",
      Geometry: {
        WGS84: "POINT (14.105880339757105 57.60942871154519)",
      },
      LocationSignature: "Eky",
      OfficialLocationName: "Ekeryd",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Edsvalla",
      Geometry: {
        WGS84: "POINT (13.212631680519577 59.43577310869102)",
      },
      LocationSignature: "El",
      OfficialLocationName: "Edsvalla",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Emmaboda",
      Geometry: {
        WGS84: "POINT (15.534935513798624 56.629789589946874)",
      },
      LocationSignature: "Em",
      OfficialLocationName: "Emmaboda",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Edane",
      Geometry: {
        WGS84: "POINT (12.829341349582482 59.62680902459878)",
      },
      LocationSignature: "En",
      OfficialLocationName: "Edane",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Enafors",
      Geometry: {
        WGS84: "POINT (12.337325380480964 63.28977888246751)",
      },
      LocationSignature: "Ens",
      OfficialLocationName: "Enafors",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Enköping",
      Geometry: {
        WGS84: "POINT (17.088656482917344 59.64457984560813)",
      },
      LocationSignature: "Ep",
      OfficialLocationName: "Enköping",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ervalla",
      Geometry: {
        WGS84: "POINT (15.247585921008847 59.416983448109406)",
      },
      LocationSignature: "Er",
      OfficialLocationName: "Ervalla",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Eneryda",
      Geometry: {
        WGS84: "POINT (14.339704009280497 56.70786237946959)",
      },
      LocationSignature: "Era",
      OfficialLocationName: "Eneryda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Erikstad",
      Geometry: {
        WGS84: "POINT (12.41062152958858 58.631439908562854)",
      },
      LocationSignature: "Erk",
      OfficialLocationName: "Erikstad",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ekenässjön",
      Geometry: {
        WGS84: "POINT (15.018916911499558 57.490505417101765)",
      },
      LocationSignature: "Esn",
      OfficialLocationName: "Ekenässjön",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Eskilstuna C",
      Geometry: {
        WGS84: "POINT (16.505455902288542 59.36938759161493)",
      },
      LocationSignature: "Et",
      OfficialLocationName: "Eskilstuna central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ekträsk",
      Geometry: {
        WGS84: "POINT (19.80964490267291 64.49615515707109)",
      },
      LocationSignature: "Etk",
      OfficialLocationName: "Ekträsk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Falköping C",
      Geometry: {
        WGS84: "POINT (13.552006182330869 58.175743838976565)",
      },
      LocationSignature: "F",
      OfficialLocationName: "Falköpings central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Finnerödja",
      Geometry: {
        WGS84: "POINT (14.44291593047966 58.926191505433486)",
      },
      LocationSignature: "Fa",
      OfficialLocationName: "Finnerödja",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Flodala",
      Geometry: {
        WGS84: "POINT (13.52174537803268 57.47065140824144)",
      },
      LocationSignature: "Faa",
      OfficialLocationName: "Flodala",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Falkenberg godsstation",
      Geometry: {
        WGS84: "POINT (12.487511687459685 56.90163792167969)",
      },
      LocationSignature: "Fabg",
      OfficialLocationName: "Falkenberg godsstation",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Falkenberg",
      Geometry: {
        WGS84: "POINT (12.508724315820682 56.91950664179583)",
      },
      LocationSignature: "Fabp",
      OfficialLocationName: "Falkenberg personstation",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fästad",
      Geometry: {
        WGS84: "POINT (16.37400859788474 57.943962315024706)",
      },
      LocationSignature: "Fad",
      OfficialLocationName: "Fästad",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Falerum",
      Geometry: {
        WGS84: "POINT (16.207194185243125 58.14363842076243)",
      },
      LocationSignature: "Fal",
      OfficialLocationName: "Falerum",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Farsta strand",
      Geometry: {
        WGS84: "POINT (18.104137121732695 59.23670496034276)",
      },
      LocationSignature: "Fas",
      OfficialLocationName: "Farsta strand",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Forsbacka",
      Geometry: {
        WGS84: "POINT (16.88846122745535 60.63974250128173)",
      },
      LocationSignature: "Fb",
      OfficialLocationName: "Forsbacka",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Floby",
      Geometry: {
        WGS84: "POINT (13.332227221530667 58.13874361406276)",
      },
      LocationSignature: "Fby",
      OfficialLocationName: "Floby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Framnäs city",
      Geometry: {
        WGS84: "POINT (13.147312245825955 58.50771209626946)",
      },
      LocationSignature: "Fc",
      OfficialLocationName: "Framnäs city",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Floda",
      Geometry: {
        WGS84: "POINT (12.361481828587378 57.81000255630886)",
      },
      LocationSignature: "Fd",
      OfficialLocationName: "Floda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Furudal",
      Geometry: {
        WGS84: "POINT (15.134970289693332 61.16969966669289)",
      },
      LocationSignature: "Fda",
      OfficialLocationName: "Furudal",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Frändefors",
      Geometry: {
        WGS84: "POINT (12.279719498996997 58.494254516855136)",
      },
      LocationSignature: "Fdf",
      OfficialLocationName: "Frändefors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fredriksdal",
      Geometry: {
        WGS84: "POINT (14.594446905739815 57.6158745862139)",
      },
      LocationSignature: "Fdl",
      OfficialLocationName: "Fredriksdal",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fellingsbro",
      Geometry: {
        WGS84: "POINT (15.59503810973915 59.43282614654759)",
      },
      LocationSignature: "Feb",
      OfficialLocationName: "Fellingsbro",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Fågelsjö",
      Geometry: {
        WGS84: "POINT (14.682078466488159 61.80160457201157)",
      },
      LocationSignature: "Feö",
      OfficialLocationName: "Fågelsjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Frufällan",
      Geometry: {
        WGS84: "POINT (12.981226972481714 57.777090545540204)",
      },
      LocationSignature: "Ffl",
      OfficialLocationName: "Frufällan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Finnforsfallet",
      Geometry: {
        WGS84: "POINT (20.333101780753033 64.77737419955295)",
      },
      LocationSignature: "Ffs",
      OfficialLocationName: "Finnforsfallet",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Finspång",
      Geometry: {
        WGS84: "POINT (15.775494771471818 58.707198609971215)",
      },
      LocationSignature: "Fg",
      OfficialLocationName: "Finspång",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Fagersta C",
      Geometry: {
        WGS84: "POINT (15.815465855713567 59.990548754843736)",
      },
      LocationSignature: "Fgc",
      OfficialLocationName: "Fagersta central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fågelsta",
      Geometry: {
        WGS84: "POINT (15.039382028679153 58.45864456118405)",
      },
      LocationSignature: "Fgl",
      OfficialLocationName: "Fågelsta",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Fagersta Norra",
      Geometry: {
        WGS84: "POINT (15.775344885430442 60.00864199153848)",
      },
      LocationSignature: "Fgn",
      OfficialLocationName: "Fagersta Norra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fagersanna",
      Geometry: {
        WGS84: "POINT (14.297844300054503 58.46929423626765)",
      },
      LocationSignature: "Fgs",
      OfficialLocationName: "Fagersanna",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Fagerås",
      Geometry: {
        WGS84: "POINT (13.218175056020563 59.5197774491083)",
      },
      LocationSignature: "Fgå",
      OfficialLocationName: "Fagerås",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fångsjöbacken",
      Geometry: {
        WGS84: "POINT (16.780963397008676 63.05404223804751)",
      },
      LocationSignature: "Fgö",
      OfficialLocationName: "Fångsjöbacken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Forshem",
      Geometry: {
        WGS84: "POINT (13.49182635604396 58.61808317519236)",
      },
      LocationSignature: "Fhm",
      OfficialLocationName: "Forshem",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fiskeby",
      Geometry: {
        WGS84: "POINT (16.123924534642104 58.60507461015913)",
      },
      LocationSignature: "Fi",
      OfficialLocationName: "Fiskeby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Filipstad",
      Geometry: {
        WGS84: "POINT (14.159470306207027 59.70834942046663)",
      },
      LocationSignature: "Fid",
      OfficialLocationName: "Filipstad",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fillan",
      Geometry: {
        WGS84: "POINT (17.38197713382528 62.426186975145185)",
      },
      LocationSignature: "Fil",
      OfficialLocationName: "Fillan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Finja",
      Geometry: {
        WGS84: "POINT (13.695489589255935 56.16967273033296)",
      },
      LocationSignature: "Fin",
      OfficialLocationName: "Finja",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Filsbäck",
      Geometry: {
        WGS84: "POINT (13.247201112574858 58.49316981651637)",
      },
      LocationSignature: "Fiä",
      OfficialLocationName: "Filsbäck",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Fjällåsen",
      Geometry: {
        WGS84: "POINT (20.09370320790869 67.51935696998564)",
      },
      LocationSignature: "Fjå",
      OfficialLocationName: "Fjällåsen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Fjälkinge",
      Geometry: {
        WGS84: "POINT (14.298079224161043 56.04144049573795)",
      },
      LocationSignature: "Fki",
      OfficialLocationName: "Fjälkinge",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Fåker",
      Geometry: {
        WGS84: "POINT (14.5743926077072 62.99320059694873)",
      },
      LocationSignature: "Fkr",
      OfficialLocationName: "Fåker",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Flädie",
      Geometry: {
        WGS84: "POINT (13.073995127687617 55.723689424454264)",
      },
      LocationSignature: "Fl",
      OfficialLocationName: "Flädie",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Flemingsberg",
      Geometry: {
        WGS84: "POINT (17.947841798445623 59.21926148988484)",
      },
      LocationSignature: "Flb",
      OfficialLocationName: "Flemingsberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Flen",
      Geometry: {
        WGS84: "POINT (16.58527823224592 59.056946593019575)",
      },
      LocationSignature: "Fle",
      OfficialLocationName: "Flen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Friluftsgården",
      Geometry: {
        WGS84: "POINT (12.251632094858746 58.95078133646354)",
      },
      LocationSignature: "Flgd",
      OfficialLocationName: "Friluftsgården",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Falun C",
      Geometry: {
        WGS84: "POINT (15.641726698028318 60.603247307025484)",
      },
      LocationSignature: "Fln",
      OfficialLocationName: "Falun central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Flackarp",
      Geometry: {
        WGS84: "POINT (13.158110078373847 55.686685859249955)",
      },
      LocationSignature: "Flp",
      OfficialLocationName: "Flackarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Flisby",
      Geometry: {
        WGS84: "POINT (14.800735027043244 57.78774517173931)",
      },
      LocationSignature: "Fls",
      OfficialLocationName: "Flisby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Forserum",
      Geometry: {
        WGS84: "POINT (14.474112547817956 57.69785663130731)",
      },
      LocationSignature: "Fm",
      OfficialLocationName: "Forserum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Falköpings norra",
      Geometry: {
        WGS84: "POINT (13.577451792839108 58.195118942752835)",
      },
      LocationSignature: "Fn",
      OfficialLocationName: "Falköpings norra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Frånö",
      Geometry: {
        WGS84: "POINT (17.827686335147686 62.906153993471605)",
      },
      LocationSignature: "Fnö",
      OfficialLocationName: "Frånö",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Forsheda",
      Geometry: {
        WGS84: "POINT (13.832917516042627 57.163272965327344)",
      },
      LocationSignature: "Fod",
      OfficialLocationName: "Forsheda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Folkesta",
      Geometry: {
        WGS84: "POINT (16.414317837235856 59.402740779512925)",
      },
      LocationSignature: "Fok",
      OfficialLocationName: "Folkesta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Foodia",
      Geometry: {
        WGS84: "POINT (13.185365613650545 55.630248507363994)",
      },
      LocationSignature: "Foo",
      OfficialLocationName: "Foodia",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Fristad",
      Geometry: {
        WGS84: "POINT (13.008626149200433 57.827424983928175)",
      },
      LocationSignature: "Fra",
      OfficialLocationName: "Fristad",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Frinnaryd",
      Geometry: {
        WGS84: "POINT (14.82189512087188 57.93739432104851)",
      },
      LocationSignature: "Frd",
      OfficialLocationName: "Frinnaryd",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fredrikslund",
      Geometry: {
        WGS84: "POINT (15.950602714500736 56.73750315503763)",
      },
      LocationSignature: "Fre",
      OfficialLocationName: "Fredrikslund",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Fritsla",
      Geometry: {
        WGS84: "POINT (12.789767544953259 57.557743030698056)",
      },
      LocationSignature: "Frl",
      OfficialLocationName: "Fritsla",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Frykåsen",
      Geometry: {
        WGS84: "POINT (13.184790839941465 59.62645623035352)",
      },
      LocationSignature: "Frå",
      OfficialLocationName: "Frykåsen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Fors",
      Geometry: {
        WGS84: "POINT (16.311520708368544 60.206499483429354)",
      },
      LocationSignature: "Fs",
      OfficialLocationName: "Fors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fosieby",
      Geometry: {
        WGS84: "POINT (13.03147299943742 55.56225766736854)",
      },
      LocationSignature: "Fsb",
      OfficialLocationName: "Fosieby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Forsmo",
      Geometry: {
        WGS84: "POINT (17.179547308764644 63.26394352143384)",
      },
      LocationSignature: "Fsm",
      OfficialLocationName: "Forsmo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Flens övre",
      Geometry: {
        WGS84: "POINT (16.59487330032069 59.06155355922566)",
      },
      LocationSignature: "Fsö",
      OfficialLocationName: "Flens övre",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Fränsta",
      Geometry: {
        WGS84: "POINT (16.167448853511388 62.49601260341061)",
      },
      LocationSignature: "Ft",
      OfficialLocationName: "Fränsta",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Furulund",
      Geometry: {
        WGS84: "POINT (13.097179205268668 55.77508872467578)",
      },
      LocationSignature: "Fud",
      OfficialLocationName: "Furulund",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Furet",
      Geometry: {
        WGS84: "POINT (12.88852141420451 56.68470888329857)",
      },
      LocationSignature: "Fur",
      OfficialLocationName: "Furet",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Frövi",
      Geometry: {
        WGS84: "POINT (15.363694373556891 59.46761996907322)",
      },
      LocationSignature: "Fv",
      OfficialLocationName: "Frövi",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Furuvik",
      Geometry: {
        WGS84: "POINT (17.329682449998796 60.65141639541498)",
      },
      LocationSignature: "Fvk",
      OfficialLocationName: "Furuvik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Frillesås",
      Geometry: {
        WGS84: "POINT (12.169561951524733 57.31864042189737)",
      },
      LocationSignature: "Få",
      OfficialLocationName: "Frillesås",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fliskär",
      Geometry: {
        WGS84: "POINT (17.24605760768639 60.686483377579414)",
      },
      LocationSignature: "Fär",
      OfficialLocationName: "Fliskär",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Förslöv",
      Geometry: {
        WGS84: "POINT (12.801364209710243 56.355434840273475)",
      },
      LocationSignature: "För",
      OfficialLocationName: "Förslöv",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Göteborg C",
      Geometry: {
        WGS84: "POINT (11.973894352578421 57.70890077552247)",
      },
      LocationSignature: "G",
      OfficialLocationName: "Göteborg Central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lejonspåret",
      Geometry: {
        WGS84: "POINT (11.987907565368483 57.71425038052419)",
      },
      LocationSignature: "G.ls",
      OfficialLocationName: "Lejonspåret",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Graninge",
      Geometry: {
        WGS84: "POINT (16.86095395212202 63.09000281439235)",
      },
      LocationSignature: "Ga",
      OfficialLocationName: "Graninge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Granstanda",
      Geometry: {
        WGS84: "POINT (16.366054922604807 60.58478050844472)",
      },
      LocationSignature: "Gad",
      OfficialLocationName: "Granstanda",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gamleby",
      Geometry: {
        WGS84: "POINT (16.40963701133929 57.89699350080666)",
      },
      LocationSignature: "Gal",
      OfficialLocationName: "Gamleby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gantofta",
      Geometry: {
        WGS84: "POINT (12.809001260660173 55.98904561073578)",
      },
      LocationSignature: "Gan",
      OfficialLocationName: "Gantofta",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gamlestaden",
      Geometry: {
        WGS84: "POINT (12.004010744486573 57.72687400606088)",
      },
      LocationSignature: "Gas",
      OfficialLocationName: "Gamlestaden",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Garnudden",
      Geometry: {
        WGS84: "POINT (17.77081470259223 59.19335122369514)",
      },
      LocationSignature: "Gau",
      OfficialLocationName: "Garnudden",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Göteborg Norra",
      Geometry: {
        WGS84: "POINT (11.99221235399647 57.717624341022436)",
      },
      LocationSignature: "Gb",
      OfficialLocationName: "Göteborg Norra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Göteborg Combiterminal",
      Geometry: {
        WGS84: "POINT (11.99221235399647 57.717624341022436)",
      },
      LocationSignature: "Gbco",
      OfficialLocationName: "Göteborg Combiterminal",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Göteborg Marieholm",
      Geometry: {
        WGS84: "POINT (12.00495146069922 57.73540764234302)",
      },
      LocationSignature: "Gbm",
      OfficialLocationName: "Göteborg Marieholm",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Geijersdal",
      Geometry: {
        WGS84: "POINT (13.966922605331646 59.59843800483559)",
      },
      LocationSignature: "Gdl",
      OfficialLocationName: "Geijersdal",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gröndalsviken",
      Geometry: {
        WGS84: "POINT (17.931729038968886 58.89925320225162)",
      },
      LocationSignature: "Gdv",
      OfficialLocationName: "Gröndalsviken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gårdsjö",
      Geometry: {
        WGS84: "POINT (14.333802396998625 58.872130487582865)",
      },
      LocationSignature: "Gdö",
      OfficialLocationName: "Gårdsjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gottne",
      Geometry: {
        WGS84: "POINT (18.390689062882306 63.43571862622725)",
      },
      LocationSignature: "Ge",
      OfficialLocationName: "Gottne",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gåsträsken",
      Geometry: {
        WGS84: "POINT (22.34771115515606 66.04714037252128)",
      },
      LocationSignature: "Gen",
      OfficialLocationName: "Gåsträsken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Grängesberg",
      Geometry: {
        WGS84: "POINT (15.003432099602906 60.075037517036044)",
      },
      LocationSignature: "Gg",
      OfficialLocationName: "Grängesberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Grängesbergs malmbangård",
      Geometry: {
        WGS84: "POINT (14.98169590738792 60.06361479399566)",
      },
      LocationSignature: "Ggm",
      OfficialLocationName: "Grängesbergs malmbangård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Grohed",
      Geometry: {
        WGS84: "POINT (11.898994865404319 58.29974409177728)",
      },
      LocationSignature: "Ghd",
      OfficialLocationName: "Grohed",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gula huset",
      Geometry: {
        WGS84: "POINT (18.004324812598888 59.375450332677566)",
      },
      LocationSignature: "Ghu",
      OfficialLocationName: "Gula huset",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Grythyttan",
      Geometry: {
        WGS84: "POINT (14.530485448013597 59.70384228044989)",
      },
      LocationSignature: "Ghy",
      OfficialLocationName: "Grythyttan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Grythyttans norra",
      Geometry: {
        WGS84: "POINT (14.515034028270259 59.73887919466521)",
      },
      LocationSignature: "Ghyn",
      OfficialLocationName: "Grythyttans norra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gistad",
      Geometry: {
        WGS84: "POINT (15.914390095843844 58.478465933750286)",
      },
      LocationSignature: "Gi",
      OfficialLocationName: "Gistad",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gideåbacka",
      Geometry: {
        WGS84: "POINT (19.083947586527735 63.33765963796577)",
      },
      LocationSignature: "Gia",
      OfficialLocationName: "Gideåbacka",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Grillby",
      Geometry: {
        WGS84: "POINT (17.233992978801197 59.62896063211938)",
      },
      LocationSignature: "Gib",
      OfficialLocationName: "Grillby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gimonäs",
      Geometry: {
        WGS84: "POINT (20.315893185363944 63.787311016796835)",
      },
      LocationSignature: "Gim",
      OfficialLocationName: "Gimonäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Göteborg Kville",
      Geometry: {
        WGS84: "POINT (11.982571941979433 57.72729937181729)",
      },
      LocationSignature: "Gk",
      OfficialLocationName: "Göteborg Kville",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Glommersträsk",
      Geometry: {
        WGS84: "POINT (19.60791605079268 65.26759468330694)",
      },
      LocationSignature: "Glk",
      OfficialLocationName: "Glommersträsk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gullträsk",
      Geometry: {
        WGS84: "POINT (21.1910168000432 66.19559857426735)",
      },
      LocationSignature: "Glt",
      OfficialLocationName: "Gullträsk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Glumslöv",
      Geometry: {
        WGS84: "POINT (12.814172051479417 55.944011512306396)",
      },
      LocationSignature: "Glv",
      OfficialLocationName: "Glumslöv",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gemla",
      Geometry: {
        WGS84: "POINT (14.646249049504545 56.87076516795602)",
      },
      LocationSignature: "Gm",
      OfficialLocationName: "Gemla",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gimo",
      Geometry: {
        WGS84: "POINT (18.184191124633394 60.175932379539134)",
      },
      LocationSignature: "Gmo",
      OfficialLocationName: "Gimo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gamlarp",
      Geometry: {
        WGS84: "POINT (14.686840574075086 57.68079404770021)",
      },
      LocationSignature: "Gmp",
      OfficialLocationName: "Gamlarp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Grums",
      Geometry: {
        WGS84: "POINT (13.113764769946908 59.35300150793408)",
      },
      LocationSignature: "Gms",
      OfficialLocationName: "Grums",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gnesta",
      Geometry: {
        WGS84: "POINT (17.312841134340005 59.04854927680454)",
      },
      LocationSignature: "Gn",
      OfficialLocationName: "Gnesta",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gunnesbo",
      Geometry: {
        WGS84: "POINT (13.168516659784945 55.72575550055752)",
      },
      LocationSignature: "Gnb",
      OfficialLocationName: "Gunnesbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Genevad",
      Geometry: {
        WGS84: "POINT (13.031802074007457 56.570964240279295)",
      },
      LocationSignature: "Gnd",
      OfficialLocationName: "Genevad",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gagnef",
      Geometry: {
        WGS84: "POINT (15.081034651318967 60.59875555885544)",
      },
      LocationSignature: "Gnf",
      OfficialLocationName: "Gagnef",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gullringen",
      Geometry: {
        WGS84: "POINT (15.701300316997514 57.80496951464619)",
      },
      LocationSignature: "Gng",
      OfficialLocationName: "Gullringen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gnarp",
      Geometry: {
        WGS84: "POINT (17.242918328115653 62.055350730775736)",
      },
      LocationSignature: "Gnp",
      OfficialLocationName: "Gnarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Grönåsen",
      Geometry: {
        WGS84: "POINT (17.389495424120316 63.33555294151033)",
      },
      LocationSignature: "Gnå",
      OfficialLocationName: "Grönåsen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gnosjö",
      Geometry: {
        WGS84: "POINT (13.734999980662558 57.35851714517695)",
      },
      LocationSignature: "Gnö",
      OfficialLocationName: "Gnosjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Godegård",
      Geometry: {
        WGS84: "POINT (15.165163531307268 58.745305676713755)",
      },
      LocationSignature: "Go",
      OfficialLocationName: "Godegård",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Grästorp",
      Geometry: {
        WGS84: "POINT (12.685777460021194 58.334593433452206)",
      },
      LocationSignature: "Gop",
      OfficialLocationName: "Grästorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gripenberg",
      Geometry: {
        WGS84: "POINT (14.865017811514887 57.99859763984454)",
      },
      LocationSignature: "Gp",
      OfficialLocationName: "Gripenberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Geogr. Polcirkeln",
      Geometry: {
        WGS84: "POINT (19.91226157994108 66.55331600928857)",
      },
      LocationSignature: "Gpc",
      OfficialLocationName: "Geografiska polcirkeln",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Granbergsdal",
      Geometry: {
        WGS84: "POINT (14.601029876095229 59.40038147407568)",
      },
      LocationSignature: "Gr",
      OfficialLocationName: "Granbergsdal",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Grevaryd",
      Geometry: {
        WGS84: "POINT (14.588881843618251 57.149852748454826)",
      },
      LocationSignature: "Grd",
      OfficialLocationName: "Grevaryd",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Grimsås",
      Geometry: {
        WGS84: "POINT (13.543621611946001 57.48241449095928)",
      },
      LocationSignature: "Grm",
      OfficialLocationName: "Grimsås",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Göringen",
      Geometry: {
        WGS84: "POINT (15.373236362409553 61.22622532734179)",
      },
      LocationSignature: "Grn",
      OfficialLocationName: "Göringen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gubbero",
      Geometry: {
        WGS84: "POINT (11.998249637855931 57.70922034978586)",
      },
      LocationSignature: "Gro",
      OfficialLocationName: "Gubbero",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gransjö",
      Geometry: {
        WGS84: "POINT (21.40800587939518 66.06392539616478)",
      },
      LocationSignature: "Grs",
      OfficialLocationName: "Gransjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Grundbro",
      Geometry: {
        WGS84: "POINT (17.102236921191274 59.29039081077763)",
      },
      LocationSignature: "Gru",
      OfficialLocationName: "Grundbro",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gryttje",
      Geometry: {
        WGS84: "POINT (17.280734526502403 62.0717495118879)",
      },
      LocationSignature: "Gry",
      OfficialLocationName: "Gryttje",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Grötingen",
      Geometry: {
        WGS84: "POINT (15.48472656000008 62.854005647916374)",
      },
      LocationSignature: "Grö",
      OfficialLocationName: "Grötingen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gunsen",
      Geometry: {
        WGS84: "POINT (21.09788141599772 64.71788194870659)",
      },
      LocationSignature: "Gse",
      OfficialLocationName: "Gunsen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Göteborg Skandiahamnen",
      Geometry: {
        WGS84: "POINT (11.859241531778217 57.69934701410272)",
      },
      LocationSignature: "Gsh",
      OfficialLocationName: "Göteborg Skandiahamnen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gastsjön",
      Geometry: {
        WGS84: "POINT (15.856463462337892 62.958287953538054)",
      },
      LocationSignature: "Gsn",
      OfficialLocationName: "Gastsjön",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gärsnäs",
      Geometry: {
        WGS84: "POINT (14.181556623063974 55.546497528179785)",
      },
      LocationSignature: "Gss",
      OfficialLocationName: "Gärsnäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gammelstad",
      Geometry: {
        WGS84: "POINT (22.026120624438043 65.64102367572148)",
      },
      LocationSignature: "Gst",
      OfficialLocationName: "Gammelstad",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Göteborg Sävenäs",
      Geometry: {
        WGS84: "POINT (12.018618481158455 57.7247293992538)",
      },
      LocationSignature: "Gsv",
      OfficialLocationName: "Göteborg Sävenäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Garsås",
      Geometry: {
        WGS84: "POINT (14.819261810298539 60.91330105191376)",
      },
      LocationSignature: "Gså",
      OfficialLocationName: "Garsås",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Grimstorp",
      Geometry: {
        WGS84: "POINT (14.697163474137138 57.556840604305144)",
      },
      LocationSignature: "Gt",
      OfficialLocationName: "Grimstorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gustafs",
      Geometry: {
        WGS84: "POINT (15.594716980892446 60.40136915544786)",
      },
      LocationSignature: "Gtf",
      OfficialLocationName: "Gustafs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gamla Tortuna",
      Geometry: {
        WGS84: "POINT (16.718895209887446 59.67384884019208)",
      },
      LocationSignature: "Gttu",
      OfficialLocationName: "Gamla Tortuna",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Getå",
      Geometry: {
        WGS84: "POINT (16.263400115761858 58.66658319287491)",
      },
      LocationSignature: "Gtå",
      OfficialLocationName: "Getå",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gullberna",
      Geometry: {
        WGS84: "POINT (15.614336642925235 56.187608808677815)",
      },
      LocationSignature: "Gua",
      OfficialLocationName: "Gullberna",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gussi",
      Geometry: {
        WGS84: "POINT (17.096435496228107 61.234539903888354)",
      },
      LocationSignature: "Gui",
      OfficialLocationName: "Gussi",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gunnarn",
      Geometry: {
        WGS84: "POINT (17.67494183435194 65.0023050549634)",
      },
      LocationSignature: "Gun",
      OfficialLocationName: "Gunnarn",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gällivare",
      Geometry: {
        WGS84: "POINT (20.65107450057759 67.13332488572043)",
      },
      LocationSignature: "Gv",
      OfficialLocationName: "Gällivare",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Göteborg Volvo",
      Geometry: {
        WGS84: "POINT (11.822599074862227 57.72570260327838)",
      },
      LocationSignature: "Gvo",
      OfficialLocationName: "Göteborg Volvo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gäddmyr",
      Geometry: {
        WGS84: "POINT (20.192992600928097 67.67948279641986)",
      },
      LocationSignature: "Gy",
      OfficialLocationName: "Gäddmyr",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Grycksbo",
      Geometry: {
        WGS84: "POINT (15.480261558376503 60.68746989850865)",
      },
      LocationSignature: "Gyo",
      OfficialLocationName: "Grycksbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gyttorp",
      Geometry: {
        WGS84: "POINT (14.971226414337908 59.50166298232147)",
      },
      LocationSignature: "Gyt",
      OfficialLocationName: "Gyttorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gålnäs",
      Geometry: {
        WGS84: "POINT (18.608223958290907 63.28187782095671)",
      },
      LocationSignature: "Gån",
      OfficialLocationName: "Gålnäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gåvetorp",
      Geometry: {
        WGS84: "POINT (14.579365197864306 56.94246358553825)",
      },
      LocationSignature: "Gåp",
      OfficialLocationName: "Gåvetorp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gävle C",
      Geometry: {
        WGS84: "POINT (17.15175003854908 60.67608613345503)",
      },
      LocationSignature: "Gä",
      OfficialLocationName: "Gävle central",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tåghallen",
      Geometry: {
        WGS84: "POINT (17.15175003854908 60.67608613345503)",
      },
      LocationSignature: "Gä.f",
      OfficialLocationName: "Gävle-Tåghallen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gasklockorna",
      Geometry: {
        WGS84: "POINT (17.15175003854908 60.67608613345503)",
      },
      LocationSignature: "Gä.g",
      OfficialLocationName: "Gävle gasklockorna",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gävle hamn",
      Geometry: {
        WGS84: "POINT (17.15175003854908 60.67608613345503)",
      },
      LocationSignature: "Gä.h",
      OfficialLocationName: "Gävle hamn",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Järnvägsmuseum",
      Geometry: {
        WGS84: "POINT (17.15175003854908 60.67608613345503)",
      },
      LocationSignature: "Gä.j",
      OfficialLocationName: "Gävle järnvägsmuseum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gävle godsbangård",
      Geometry: {
        WGS84: "POINT (17.15560867915861 60.68121546394273)",
      },
      LocationSignature: "Gäb",
      OfficialLocationName: "Gävle godsbangård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gärdala",
      Geometry: {
        WGS84: "POINT (15.714820771436147 58.17641401320808)",
      },
      LocationSignature: "Gäd",
      OfficialLocationName: "Gärdala",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gräsberg",
      Geometry: {
        WGS84: "POINT (15.223250669201263 60.22429720511952)",
      },
      LocationSignature: "Gäg",
      OfficialLocationName: "Gräsberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Gällö",
      Geometry: {
        WGS84: "POINT (15.229499500583367 62.91123382377774)",
      },
      LocationSignature: "Gö",
      OfficialLocationName: "Gällö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gårdsjön",
      Geometry: {
        WGS84: "POINT (17.378936989786084 62.19843774346928)",
      },
      LocationSignature: "Gön",
      OfficialLocationName: "Gårdsjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hamra",
      Geometry: {
        WGS84: "POINT (12.310470907034384 57.07907688361387)",
      },
      LocationSignature: "Haa",
      OfficialLocationName: "Hamra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hallstavik",
      Geometry: {
        WGS84: "POINT (18.595816739384155 60.05076878308979)",
      },
      LocationSignature: "Hak",
      OfficialLocationName: "Hallstavik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hallinden",
      Geometry: {
        WGS84: "POINT (11.527838629410684 58.45946774282714)",
      },
      LocationSignature: "Hal",
      OfficialLocationName: "Hallinden",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Harasjön",
      Geometry: {
        WGS84: "POINT (17.840943136058986 63.17341755476807)",
      },
      LocationSignature: "Han",
      OfficialLocationName: "Harasjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Harrå",
      Geometry: {
        WGS84: "POINT (20.102669103346678 67.40620304350975)",
      },
      LocationSignature: "Har",
      OfficialLocationName: "Harrå",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Helsingborg C",
      Geometry: {
        WGS84: "POINT (12.695170384465698 56.043537688378294)",
      },
      LocationSignature: "Hb",
      OfficialLocationName: "Helsingborgs central",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Högboda",
      Geometry: {
        WGS84: "POINT (13.051155006569576 59.55751992948036)",
      },
      LocationSignature: "Hbd",
      OfficialLocationName: "Högboda",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ramlösa",
      Geometry: {
        WGS84: "POINT (12.72190928115989 56.02711985824322)",
      },
      LocationSignature: "Hbgb",
      OfficialLocationName: "Helsingborgs godsbangård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Holsbybrunn",
      Geometry: {
        WGS84: "POINT (15.195256753851522 57.427754273274175)",
      },
      LocationSignature: "Hbr",
      OfficialLocationName: "Holsbybrunn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Högbränna",
      Geometry: {
        WGS84: "POINT (19.42840637826548 63.768713877112965)",
      },
      LocationSignature: "Hbä",
      OfficialLocationName: "Högbränna",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Halmstad C",
      Geometry: {
        WGS84: "POINT (12.864818980582479 56.66960291358604)",
      },
      LocationSignature: "Hd",
      OfficialLocationName: "Halmstad central",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hedemora",
      Geometry: {
        WGS84: "POINT (15.980235493393721 60.274104352676964)",
      },
      LocationSignature: "Hdm",
      OfficialLocationName: "Hedemora",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Holmsveden",
      Geometry: {
        WGS84: "POINT (16.719091859105532 61.11970885732857)",
      },
      LocationSignature: "Hdn",
      OfficialLocationName: "Holmsveden",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Halmstad rangerbangård",
      Geometry: {
        WGS84: "POINT (12.886287671660318 56.65347024477897)",
      },
      LocationSignature: "Hdr",
      OfficialLocationName: "Halmstad rangerbangård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Heberg",
      Geometry: {
        WGS84: "POINT (12.644467933715198 56.87335944579451)",
      },
      LocationSignature: "He",
      OfficialLocationName: "Heberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Helenelund",
      Geometry: {
        WGS84: "POINT (17.96311989514949 59.40853596468148)",
      },
      LocationSignature: "Hel",
      OfficialLocationName: "Helenelund",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Helmershus",
      Geometry: {
        WGS84: "POINT (14.025608545463442 57.145404210077544)",
      },
      LocationSignature: "Hes",
      OfficialLocationName: "Helmershus",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hestra",
      Geometry: {
        WGS84: "POINT (13.59575379990811 57.443107911165725)",
      },
      LocationSignature: "Het",
      OfficialLocationName: "Hestra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hultsfred",
      Geometry: {
        WGS84: "POINT (15.847113344881812 57.485911214421485)",
      },
      LocationSignature: "Hf",
      OfficialLocationName: "Hultsfred",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hemfosa",
      Geometry: {
        WGS84: "POINT (17.976580294148828 59.06878868253162)",
      },
      LocationSignature: "Hfa",
      OfficialLocationName: "Hemfosa",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hamrångefjärden",
      Geometry: {
        WGS84: "POINT (17.062537893894397 60.90110477056057)",
      },
      LocationSignature: "Hfj",
      OfficialLocationName: "Hamrångefjärden",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hofors",
      Geometry: {
        WGS84: "POINT (16.266177340357707 60.56958253355308)",
      },
      LocationSignature: "Hfr",
      OfficialLocationName: "Hofors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Holmfors",
      Geometry: {
        WGS84: "POINT (21.687644142854545 65.90315243140822)",
      },
      LocationSignature: "Hfs",
      OfficialLocationName: "Holmfors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hagaström",
      Geometry: {
        WGS84: "POINT (17.068096210568964 60.67041669469857)",
      },
      LocationSignature: "Hg",
      OfficialLocationName: "Hagaström",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hagge",
      Geometry: {
        WGS84: "POINT (15.310046165940761 60.13137782373592)",
      },
      LocationSignature: "Hge",
      OfficialLocationName: "Hagge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hagalund",
      Geometry: {
        WGS84: "POINT (18.005611850474132 59.37208683071199)",
      },
      LocationSignature: "Hgl",
      OfficialLocationName: "Hagalund",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Häggenås",
      Geometry: {
        WGS84: "POINT (14.879218440596285 63.38587369620883)",
      },
      LocationSignature: "Hgs",
      OfficialLocationName: "Häggenås",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Häggvik",
      Geometry: {
        WGS84: "POINT (17.933708229792504 59.443229737156024)",
      },
      LocationSignature: "Hgv",
      OfficialLocationName: "Häggvik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Högsjö",
      Geometry: {
        WGS84: "POINT (15.722857462745507 59.050072589442934)",
      },
      LocationSignature: "Hgö",
      OfficialLocationName: "Högsjö",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hallstahammar",
      Geometry: {
        WGS84: "POINT (16.220194436890022 59.61465592812777)",
      },
      LocationSignature: "Hh",
      OfficialLocationName: "Hallstahammar",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hyllie",
      Geometry: {
        WGS84: "POINT (12.976334321741824 55.563147971625746)",
      },
      LocationSignature: "Hie",
      OfficialLocationName: "Hyllie",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hillared",
      Geometry: {
        WGS84: "POINT (13.157952390028395 57.62990367225478)",
      },
      LocationSignature: "Hil",
      OfficialLocationName: "Hillared",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hjulsbro",
      Geometry: {
        WGS84: "POINT (15.716545033933006 58.379396969068345)",
      },
      LocationSignature: "Hj",
      OfficialLocationName: "Hjulsbro",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hjärup",
      Geometry: {
        WGS84: "POINT (13.136180346678703 55.673808781669145)",
      },
      LocationSignature: "Hjp",
      OfficialLocationName: "Hjärup",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Huskvarna",
      Geometry: {
        WGS84: "POINT (14.264765068323074 57.78236557293537)",
      },
      LocationSignature: "Hka",
      OfficialLocationName: "Huskvarna",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hudiksvall",
      Geometry: {
        WGS84: "POINT (17.109181613113915 61.724663725804646)",
      },
      LocationSignature: "Hkl",
      OfficialLocationName: "Hudiksvall",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hackås",
      Geometry: {
        WGS84: "POINT (14.522167638926167 62.92420050886644)",
      },
      LocationSignature: "Hks",
      OfficialLocationName: "Hackås",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hökön",
      Geometry: {
        WGS84: "POINT (14.223998211191663 56.473960396294764)",
      },
      LocationSignature: "Hkö",
      OfficialLocationName: "Hökön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Horndal",
      Geometry: {
        WGS84: "POINT (16.41370906955419 60.304762753071586)",
      },
      LocationSignature: "Hl",
      OfficialLocationName: "Horndal",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hällefors",
      Geometry: {
        WGS84: "POINT (14.51805003042553 59.77774567193047)",
      },
      LocationSignature: "Hlf",
      OfficialLocationName: "Hällefors",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hällekis",
      Geometry: {
        WGS84: "POINT (13.433023594898264 58.630054794438095)",
      },
      LocationSignature: "Hlk",
      OfficialLocationName: "Hällekis",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Helgum",
      Geometry: {
        WGS84: "POINT (16.949907263391403 63.15101679583848)",
      },
      LocationSignature: "Hlm",
      OfficialLocationName: "Helgum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hasslarp",
      Geometry: {
        WGS84: "POINT (12.816196275437939 56.13570240154904)",
      },
      LocationSignature: "Hlp",
      OfficialLocationName: "Hasslarp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Horndals Bruk",
      Geometry: {
        WGS84: "POINT (16.413462325466064 60.29611740417893)",
      },
      LocationSignature: "Hlr",
      OfficialLocationName: "Horndals bruk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hällnäs",
      Geometry: {
        WGS84: "POINT (19.625887590833766 64.30547419760393)",
      },
      LocationSignature: "Hls",
      OfficialLocationName: "Hällnäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Herrhult",
      Geometry: {
        WGS84: "POINT (14.310746226759328 59.649890565307196)",
      },
      LocationSignature: "Hlt",
      OfficialLocationName: "Herrhult",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hällevadsholm",
      Geometry: {
        WGS84: "POINT (11.535223797959048 58.58000967062267)",
      },
      LocationSignature: "Hlv",
      OfficialLocationName: "Hällevadsholm",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hilleby",
      Geometry: {
        WGS84: "POINT (17.171504767081576 60.73038474780878)",
      },
      LocationSignature: "Hly",
      OfficialLocationName: "Hilleby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hölö",
      Geometry: {
        WGS84: "POINT (17.534812607709043 59.02593382032083)",
      },
      LocationSignature: "Hlö",
      OfficialLocationName: "Hölö",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hässleholm",
      Geometry: {
        WGS84: "POINT (13.76320718304113 56.15790593929379)",
      },
      LocationSignature: "Hm",
      OfficialLocationName: "Hässleholm",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hökmora",
      Geometry: {
        WGS84: "POINT (16.157295597154448 60.06212654825961)",
      },
      LocationSignature: "Hma",
      OfficialLocationName: "Hökmora",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hissmofors",
      Geometry: {
        WGS84: "POINT (14.457833939407195 63.3198087067008)",
      },
      LocationSignature: "Hmfs",
      OfficialLocationName: "Hissmofors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hammar",
      Geometry: {
        WGS84: "POINT (17.763252204127426 63.017310680594186)",
      },
      LocationSignature: "Hmr",
      OfficialLocationName: "Hammar",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Holmsjö",
      Geometry: {
        WGS84: "POINT (15.542248211571938 56.42223781610486)",
      },
      LocationSignature: "Hmö",
      OfficialLocationName: "Holmsjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hälsingenybo",
      Geometry: {
        WGS84: "POINT (15.832936281139391 62.0706661748438)",
      },
      LocationSignature: "Hnb",
      OfficialLocationName: "Hälsingenybo",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Handen",
      Geometry: {
        WGS84: "POINT (18.134083407444244 59.16628512713939)",
      },
      LocationSignature: "Hnd",
      OfficialLocationName: "Handen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hällenyland",
      Geometry: {
        WGS84: "POINT (17.788211226119564 62.602978789540096)",
      },
      LocationSignature: "Hnl",
      OfficialLocationName: "Hällenyland",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hennan",
      Geometry: {
        WGS84: "POINT (15.902394024486547 62.02185238590994)",
      },
      LocationSignature: "Hnn",
      OfficialLocationName: "Hennan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hinsnoret",
      Geometry: {
        WGS84: "POINT (15.62834644219119 60.542461767843314)",
      },
      LocationSignature: "Hno",
      OfficialLocationName: "Hinsnoret",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hälleforsnäs",
      Geometry: {
        WGS84: "POINT (16.50255929862843 59.16199646274857)",
      },
      LocationSignature: "Hnä",
      OfficialLocationName: "Hälleforsnäs",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Habo",
      Geometry: {
        WGS84: "POINT (14.07695059451763 57.90639312655905)",
      },
      LocationSignature: "Ho",
      OfficialLocationName: "Habo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Holmsund",
      Geometry: {
        WGS84: "POINT (20.36898396458765 63.70847632450499)",
      },
      LocationSignature: "Hod",
      OfficialLocationName: "Holmsund",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hok",
      Geometry: {
        WGS84: "POINT (14.274726052387866 57.514131758823424)",
      },
      LocationSignature: "Hok",
      OfficialLocationName: "Hok",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hova",
      Geometry: {
        WGS84: "POINT (14.215176192920854 58.854777417969984)",
      },
      LocationSignature: "Hova",
      OfficialLocationName: "Hova",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Holmån",
      Geometry: {
        WGS84: "POINT (17.648333850475723 63.39129339565775)",
      },
      LocationSignature: "Hoå",
      OfficialLocationName: "Holmån",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Haparanda",
      Geometry: {
        WGS84: "POINT (24.131132904589016 65.82811132917702)",
      },
      LocationSignature: "Hp",
      OfficialLocationName: "Haparanda",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hallsberg",
      Geometry: {
        WGS84: "POINT (15.111247817253778 59.06710905898814)",
      },
      LocationSignature: "Hpbg",
      OfficialLocationName: "Hallsbergs personbangård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hallsberg Posten",
      Geometry: {
        WGS84: "POINT (15.04628637056594 59.05520435892093)",
      },
      LocationSignature: "Hpn",
      OfficialLocationName: "Hallsberg Posten",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Herrljunga",
      Geometry: {
        WGS84: "POINT (13.02094869281716 58.07905700496917)",
      },
      LocationSignature: "Hr",
      OfficialLocationName: "Herrljunga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hallsbergs rangerbangård",
      Geometry: {
        WGS84: "POINT (15.059332698873588 59.05865656514161)",
      },
      LocationSignature: "Hrbg",
      OfficialLocationName: "Hallsbergs rangerbangård",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Horred",
      Geometry: {
        WGS84: "POINT (12.477653813819135 57.35372200414021)",
      },
      LocationSignature: "Hre",
      OfficialLocationName: "Horred",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hörle",
      Geometry: {
        WGS84: "POINT (14.053825698105854 57.25907535789099)",
      },
      LocationSignature: "Hrl",
      OfficialLocationName: "Hörle",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hargshamn",
      Geometry: {
        WGS84: "POINT (18.47277817775976 60.16919033845417)",
      },
      LocationSignature: "Hrm",
      OfficialLocationName: "Hargshamn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Harrträsk",
      Geometry: {
        WGS84: "POINT (20.714304373794427 67.03345645834735)",
      },
      LocationSignature: "Hrt",
      OfficialLocationName: "Harrträsk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hasselfors",
      Geometry: {
        WGS84: "POINT (14.646340796958789 59.08564171188329)",
      },
      LocationSignature: "Hs",
      OfficialLocationName: "Hasselfors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hovsta",
      Geometry: {
        WGS84: "POINT (15.218693351704676 59.34911820364725)",
      },
      LocationSignature: "Hsa",
      OfficialLocationName: "Hovsta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hedensbyn",
      Geometry: {
        WGS84: "POINT (21.010731848230883 64.74481379667887)",
      },
      LocationSignature: "Hsb",
      OfficialLocationName: "Hedensbyn",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Härnösand C",
      Geometry: {
        WGS84: "POINT (17.928386206800027 62.635273037522474)",
      },
      LocationSignature: "Hsd",
      OfficialLocationName: "Härnösands central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hanaskog",
      Geometry: {
        WGS84: "POINT (14.093180199486671 56.1583593511671)",
      },
      LocationSignature: "Hsk",
      OfficialLocationName: "Hanaskog",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hasslerör",
      Geometry: {
        WGS84: "POINT (13.93953951212381 58.75019368983026)",
      },
      LocationSignature: "Hsr",
      OfficialLocationName: "Hasslerör",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hussjöby",
      Geometry: {
        WGS84: "POINT (17.60574621331764 62.5474798344926)",
      },
      LocationSignature: "Hsy",
      OfficialLocationName: "Hussjöby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hörnsjö",
      Geometry: {
        WGS84: "POINT (19.507442105150965 63.79534881715025)",
      },
      LocationSignature: "Hsö",
      OfficialLocationName: "Hörnsjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hednoret",
      Geometry: {
        WGS84: "POINT (21.531870499839012 65.82344585334077)",
      },
      LocationSignature: "Ht",
      OfficialLocationName: "Hednoret",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hoting",
      Geometry: {
        WGS84: "POINT (16.19910961846013 64.11559302950539)",
      },
      LocationSignature: "Htg",
      OfficialLocationName: "Hoting",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hillerstorp",
      Geometry: {
        WGS84: "POINT (13.883628094733824 57.31359393143125)",
      },
      LocationSignature: "Hto",
      OfficialLocationName: "Hillerstorp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Huddinge",
      Geometry: {
        WGS84: "POINT (17.978043145418106 59.23574545252347)",
      },
      LocationSignature: "Hu",
      OfficialLocationName: "Huddinge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hulingsryd",
      Geometry: {
        WGS84: "POINT (15.835164283666364 57.500992797223894)",
      },
      LocationSignature: "Hud",
      OfficialLocationName: "Hulingsryd",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hult",
      Geometry: {
        WGS84: "POINT (15.128790453454673 57.644485113790374)",
      },
      LocationSignature: "Hul",
      OfficialLocationName: "Hult",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Husum",
      Geometry: {
        WGS84: "POINT (19.156712114729547 63.34436390236012)",
      },
      LocationSignature: "Hum",
      OfficialLocationName: "Husum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Husums norra",
      Geometry: {
        WGS84: "POINT (19.167593858081148 63.348342743358096)",
      },
      LocationSignature: "Humn",
      OfficialLocationName: "Husums norra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Huvudsta",
      Geometry: {
        WGS84: "POINT (18.000647047100582 59.35475926458733)",
      },
      LocationSignature: "Huv",
      OfficialLocationName: "Huvudsta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hundsjön",
      Geometry: {
        WGS84: "POINT (21.80267183992477 65.96524062908958)",
      },
      LocationSignature: "Huö",
      OfficialLocationName: "Hundsjön",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hästveda",
      Geometry: {
        WGS84: "POINT (13.934798054448995 56.28856744170602)",
      },
      LocationSignature: "Hv",
      OfficialLocationName: "Hästveda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hjältevad",
      Geometry: {
        WGS84: "POINT (15.343975994340779 57.62892028334813)",
      },
      LocationSignature: "Hvd",
      OfficialLocationName: "Hjältevad",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hovmantorp",
      Geometry: {
        WGS84: "POINT (15.139584184013192 56.78621973567994)",
      },
      LocationSignature: "Hvp",
      OfficialLocationName: "Hovmantorp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Håverud",
      Geometry: {
        WGS84: "POINT (12.412909610909477 58.824596941853684)",
      },
      LocationSignature: "Hvr",
      OfficialLocationName: "Håverud",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hovslätt",
      Geometry: {
        WGS84: "POINT (14.123948495898295 57.73488563402116)",
      },
      LocationSignature: "Hvs",
      OfficialLocationName: "Hovslätt",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Heby",
      Geometry: {
        WGS84: "POINT (16.853164760744242 59.94043449604126)",
      },
      LocationSignature: "Hy",
      OfficialLocationName: "Heby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hyltebruk",
      Geometry: {
        WGS84: "POINT (13.23640399744783 56.99820444907104)",
      },
      LocationSignature: "Hyb",
      OfficialLocationName: "Hyltebruk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hyllstofta",
      Geometry: {
        WGS84: "POINT (13.285879333428305 56.132464043840365)",
      },
      LocationSignature: "Hyl",
      OfficialLocationName: "Hyllstofta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Håsjö",
      Geometry: {
        WGS84: "POINT (16.272961072451853 63.023398107603875)",
      },
      LocationSignature: "Hå",
      OfficialLocationName: "Håsjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hålbergsliden",
      Geometry: {
        WGS84: "POINT (19.521930930464205 65.36882506933857)",
      },
      LocationSignature: "Håb",
      OfficialLocationName: "Hålbergsliden",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Harmånger",
      Geometry: {
        WGS84: "POINT (17.268878320893517 61.90351483279147)",
      },
      LocationSignature: "Håg",
      OfficialLocationName: "Harmånger",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Håmojåkk",
      Geometry: {
        WGS84: "POINT (20.16792836710721 67.32408484884022)",
      },
      LocationSignature: "Håk",
      OfficialLocationName: "Håmojåkk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hållsta",
      Geometry: {
        WGS84: "POINT (16.458614128862813 59.29522004440194)",
      },
      LocationSignature: "Hål",
      OfficialLocationName: "Hållsta",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Håkantorp",
      Geometry: {
        WGS84: "POINT (12.907646836085119 58.30666392985773)",
      },
      LocationSignature: "Håp",
      OfficialLocationName: "Håkantorp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hindås",
      Geometry: {
        WGS84: "POINT (12.450955810908908 57.70442998342633)",
      },
      LocationSignature: "Hås",
      OfficialLocationName: "Hindås",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hästbo",
      Geometry: {
        WGS84: "POINT (16.45525827028166 60.449039637054895)",
      },
      LocationSignature: "Hä",
      OfficialLocationName: "Hästbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hämrasviken",
      Geometry: {
        WGS84: "POINT (18.040899891040258 63.22405919270636)",
      },
      LocationSignature: "Häb",
      OfficialLocationName: "Hämrasviken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Härad",
      Geometry: {
        WGS84: "POINT (16.913184715386098 59.35707314496891)",
      },
      LocationSignature: "Häd",
      OfficialLocationName: "Härad",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Häggsjön",
      Geometry: {
        WGS84: "POINT (17.731409943857592 62.57452228599368)",
      },
      LocationSignature: "Hän",
      OfficialLocationName: "Häggsjön",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Häljarp",
      Geometry: {
        WGS84: "POINT (12.912557712645304 55.86082407095729)",
      },
      LocationSignature: "Häp",
      OfficialLocationName: "Häljarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Härryda",
      Geometry: {
        WGS84: "POINT (12.303603168813506 57.68966585115586)",
      },
      LocationSignature: "Häy",
      OfficialLocationName: "Härryda",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Höör",
      Geometry: {
        WGS84: "POINT (13.543109047891285 55.93751670416943)",
      },
      LocationSignature: "Hö",
      OfficialLocationName: "Höör",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hörnefors",
      Geometry: {
        WGS84: "POINT (19.91234286770667 63.63300132951182)",
      },
      LocationSignature: "Höf",
      OfficialLocationName: "Hörnefors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Högbysjön",
      Geometry: {
        WGS84: "POINT (18.890170583101582 63.312705948471624)",
      },
      LocationSignature: "Hög",
      OfficialLocationName: "Högbysjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Höja",
      Geometry: {
        WGS84: "POINT (14.134466415702088 59.29983732783484)",
      },
      LocationSignature: "Höja",
      OfficialLocationName: "Höja",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Högdalen",
      Geometry: {
        WGS84: "POINT (18.041982001377352 59.25736898830077)",
      },
      LocationSignature: "Hön",
      OfficialLocationName: "Högdalen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hössjön",
      Geometry: {
        WGS84: "POINT (19.972302169250447 63.65532085791973)",
      },
      LocationSignature: "Hös",
      OfficialLocationName: "Hössjön",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Högsby",
      Geometry: {
        WGS84: "POINT (16.02746458305682 57.16585029054388)",
      },
      LocationSignature: "Höy",
      OfficialLocationName: "Högsby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ivarsbjörke",
      Geometry: {
        WGS84: "POINT (13.143941145310153 59.95954274073778)",
      },
      LocationSignature: "Ibe",
      OfficialLocationName: "Ivarsbjörke",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Iggesund",
      Geometry: {
        WGS84: "POINT (17.08136443313033 61.64478751337991)",
      },
      LocationSignature: "Id",
      OfficialLocationName: "Iggesund",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ingatorp",
      Geometry: {
        WGS84: "POINT (15.41708200931187 57.63448514560571)",
      },
      LocationSignature: "Igt",
      OfficialLocationName: "Ingatorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ingmår",
      Geometry: {
        WGS84: "POINT (13.175282506542827 59.878471531003896)",
      },
      LocationSignature: "Imr",
      OfficialLocationName: "Ingmår",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Insjön",
      Geometry: {
        WGS84: "POINT (15.094405490730756 60.67551212386021)",
      },
      LocationSignature: "In",
      OfficialLocationName: "Insjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Igelstorp",
      Geometry: {
        WGS84: "POINT (13.978324156114546 58.397698044356865)",
      },
      LocationSignature: "Ip",
      OfficialLocationName: "Igelstorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Isätra",
      Geometry: {
        WGS84: "POINT (16.744076539590626 59.93766490310803)",
      },
      LocationSignature: "Ist",
      OfficialLocationName: "Isätra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Bolzano",
      Geometry: {
        WGS84: "POINT (11.256706733550708 46.51639110712813)",
      },
      LocationSignature: "It.bol",
      OfficialLocationName: "Bolzano",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Venedig",
      Geometry: {
        WGS84: "POINT (12.323499057612219 45.44496302123378)",
      },
      LocationSignature: "It.ven",
      OfficialLocationName: "Venedig",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Jonsered östra",
      Geometry: {
        WGS84: "POINT (12.192176120766124 57.75121155089343)",
      },
      LocationSignature: "J",
      OfficialLocationName: "Jonsered östra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Järbo",
      Geometry: {
        WGS84: "POINT (16.6031015877392 60.714956127452034)",
      },
      LocationSignature: "Jb",
      OfficialLocationName: "Järbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Johannisberg",
      Geometry: {
        WGS84: "POINT (16.02888014143338 62.503996331218886)",
      },
      LocationSignature: "Jbg",
      OfficialLocationName: "Johannisberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Jädersbruk",
      Geometry: {
        WGS84: "POINT (15.798392368600274 59.40722470168111)",
      },
      LocationSignature: "Jbk",
      OfficialLocationName: "Jädersbruk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Jordbro",
      Geometry: {
        WGS84: "POINT (18.126627633514342 59.14233539839546)",
      },
      LocationSignature: "Jbo",
      OfficialLocationName: "Jordbro",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Jakobshyttan",
      Geometry: {
        WGS84: "POINT (15.15942054779714 58.8080360293133)",
      },
      LocationSignature: "Jho",
      OfficialLocationName: "Jakobshyttan",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Jokkmokk",
      Geometry: {
        WGS84: "POINT (19.84009503767736 66.60709505802845)",
      },
      LocationSignature: "Jk",
      OfficialLocationName: "Jokkmokk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Jakobsberg",
      Geometry: {
        WGS84: "POINT (17.833119618641522 59.42380587742837)",
      },
      LocationSignature: "Jkb",
      OfficialLocationName: "Jakobsberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Järlåsa",
      Geometry: {
        WGS84: "POINT (17.203120446229246 59.885027833296036)",
      },
      LocationSignature: "Jla",
      OfficialLocationName: "Järlåsa",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Jamtli",
      Geometry: {
        WGS84: "POINT (14.631637648178222 63.1869490920272)",
      },
      LocationSignature: "Jli",
      OfficialLocationName: "Jamtli",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Järlebo",
      Geometry: {
        WGS84: "POINT (17.74190324047218 60.134390894972256)",
      },
      LocationSignature: "Jlo",
      OfficialLocationName: "Järlebo",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Järna",
      Geometry: {
        WGS84: "POINT (17.568388900751486 59.09381755899731)",
      },
      LocationSignature: "Jn",
      OfficialLocationName: "Järna",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Järneträsk",
      Geometry: {
        WGS84: "POINT (20.74009219931711 65.63896334921236)",
      },
      LocationSignature: "Jnt",
      OfficialLocationName: "Järneträsk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Järpen",
      Geometry: {
        WGS84: "POINT (13.472155593714973 63.34240277581487)",
      },
      LocationSignature: "Jpn",
      OfficialLocationName: "Järpen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Järpås",
      Geometry: {
        WGS84: "POINT (12.969950503573184 58.378983944947926)",
      },
      LocationSignature: "Jps",
      OfficialLocationName: "Järpås",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Järvsö",
      Geometry: {
        WGS84: "POINT (16.172093563786927 61.71569443229966)",
      },
      LocationSignature: "Jr",
      OfficialLocationName: "Järvsö",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Jörn",
      Geometry: {
        WGS84: "POINT (20.029360452861017 65.05332358161681)",
      },
      LocationSignature: "Jrn",
      OfficialLocationName: "Jörn",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Jämtlands Sikås",
      Geometry: {
        WGS84: "POINT (15.206458423813126 63.626198194100404)",
      },
      LocationSignature: "Js",
      OfficialLocationName: "Jämtlands Sikås",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Jularbo",
      Geometry: {
        WGS84: "POINT (16.25771286082864 60.158330506098444)",
      },
      LocationSignature: "Ju",
      OfficialLocationName: "Jularbo",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Jonsered",
      Geometry: {
        WGS84: "POINT (12.173623419786706 57.74984613398525)",
      },
      LocationSignature: "Jv",
      OfficialLocationName: "Jonsered västra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Jenny",
      Geometry: {
        WGS84: "POINT (16.579363795375265 57.76177578388929)",
      },
      LocationSignature: "Jy",
      OfficialLocationName: "Jenny",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Juån",
      Geometry: {
        WGS84: "POINT (15.562272457595162 62.3692451136364)",
      },
      LocationSignature: "Jå",
      OfficialLocationName: "Juån",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Jönåker",
      Geometry: {
        WGS84: "POINT (16.72465792809279 58.743342573688565)",
      },
      LocationSignature: "Jår",
      OfficialLocationName: "Jönåker",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Järnforsen",
      Geometry: {
        WGS84: "POINT (15.620878090761892 57.40898497608247)",
      },
      LocationSignature: "Jäf",
      OfficialLocationName: "Järnforsen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Järlövsby",
      Geometry: {
        WGS84: "POINT (12.361260522865766 57.27853077175509)",
      },
      LocationSignature: "Jäy",
      OfficialLocationName: "Järlövsby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Jönköping C",
      Geometry: {
        WGS84: "POINT (14.164573803237401 57.784457724561484)",
      },
      LocationSignature: "Jö",
      OfficialLocationName: "Jönköpings central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Jönköpings godsbangård",
      Geometry: {
        WGS84: "POINT (14.173179778921195 57.76406035330554)",
      },
      LocationSignature: "Jögb",
      OfficialLocationName: "Jönköpings godsbangård",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Katrineholm C",
      Geometry: {
        WGS84: "POINT (16.20918364756429 58.996511678685145)",
      },
      LocationSignature: "K",
      OfficialLocationName: "Katrineholms central",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kattarp",
      Geometry: {
        WGS84: "POINT (12.778340418711485 56.14492227526384)",
      },
      LocationSignature: "Ka",
      OfficialLocationName: "Kattarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Koijuvaara",
      Geometry: {
        WGS84: "POINT (20.731093461186685 67.05775928292343)",
      },
      LocationSignature: "Kaa",
      OfficialLocationName: "Koijuvaara",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kalmar C",
      Geometry: {
        WGS84: "POINT (16.36032521757645 56.66131381287639)",
      },
      LocationSignature: "Kac",
      OfficialLocationName: "Kalmar central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Karpalund",
      Geometry: {
        WGS84: "POINT (14.094672700914874 56.04073576797069)",
      },
      LocationSignature: "Kap",
      OfficialLocationName: "Karpalund",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kalmar södra",
      Geometry: {
        WGS84: "POINT (16.33108241264831 56.66670635218652)",
      },
      LocationSignature: "Kas",
      OfficialLocationName: "Kalmar södra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Karlskoga Dalbacksgatan",
      Geometry: {
        WGS84: "POINT (14.509817448299696 59.32636054046808)",
      },
      LocationSignature: "Kasd",
      OfficialLocationName: "Karlskoga Dalbacksgatan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kattisavan",
      Geometry: {
        WGS84: "POINT (18.1599783945203 64.7533732424568)",
      },
      LocationSignature: "Kat",
      OfficialLocationName: "Kattisavan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kattisträsk",
      Geometry: {
        WGS84: "POINT (20.03282411355709 64.68932524103143)",
      },
      LocationSignature: "Kaä",
      OfficialLocationName: "Kattisträsk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kungsbacka",
      Geometry: {
        WGS84: "POINT (12.079871831568406 57.489505442452156)",
      },
      LocationSignature: "Kb",
      OfficialLocationName: "Kungsbacka",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Korsberga",
      Geometry: {
        WGS84: "POINT (15.12310965169003 57.30181429985445)",
      },
      LocationSignature: "Kba",
      OfficialLocationName: "Korsberga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Karlsborg",
      Geometry: {
        WGS84: "POINT (14.509833864596466 58.52979067293135)",
      },
      LocationSignature: "Kbg",
      OfficialLocationName: "Karlsborg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Karbenning",
      Geometry: {
        WGS84: "POINT (16.073212686173147 60.0368385747705)",
      },
      LocationSignature: "Kbn",
      OfficialLocationName: "Karbenning",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kolbäck",
      Geometry: {
        WGS84: "POINT (16.233449467265874 59.56427506937709)",
      },
      LocationSignature: "Kbä",
      OfficialLocationName: "Kolbäck",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kågeröd",
      Geometry: {
        WGS84: "POINT (13.082759740945544 56.00153406201346)",
      },
      LocationSignature: "Kd",
      OfficialLocationName: "Kågeröd",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Krigslida",
      Geometry: {
        WGS84: "POINT (18.0655526617058 59.108906680414336)",
      },
      LocationSignature: "Kda",
      OfficialLocationName: "Krigslida",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kode",
      Geometry: {
        WGS84: "POINT (11.850915983954362 57.944463550966404)",
      },
      LocationSignature: "Kde",
      OfficialLocationName: "Kode",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Karlberg",
      Geometry: {
        WGS84: "POINT (18.028410943790742 59.34026923540147)",
      },
      LocationSignature: "Ke",
      OfficialLocationName: "Karlberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kejsarbäcken",
      Geometry: {
        WGS84: "POINT (14.71254611701661 59.833449287563)",
      },
      LocationSignature: "Keb",
      OfficialLocationName: "Kejsarbäcken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Keräsjoki",
      Geometry: {
        WGS84: "POINT (23.923956912052724 65.84662425525569)",
      },
      LocationSignature: "Kei",
      OfficialLocationName: "Keräsjoki",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kortfors",
      Geometry: {
        WGS84: "POINT (14.675973213604916 59.41783987395004)",
      },
      LocationSignature: "Kf",
      OfficialLocationName: "Kortfors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kolforsen",
      Geometry: {
        WGS84: "POINT (16.851236210048064 60.84308281677132)",
      },
      LocationSignature: "Kfn",
      OfficialLocationName: "Kolforsen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kävlinge",
      Geometry: {
        WGS84: "POINT (13.111371846927003 55.794495009726425)",
      },
      LocationSignature: "Kg",
      OfficialLocationName: "Kävlinge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kungsgården",
      Geometry: {
        WGS84: "POINT (16.621094279450368 60.60235210803366)",
      },
      LocationSignature: "Kgd",
      OfficialLocationName: "Kungsgården",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kragenäs",
      Geometry: {
        WGS84: "POINT (11.253071810027308 58.79874783179416)",
      },
      LocationSignature: "Kgs",
      OfficialLocationName: "Kragenäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Klostergården",
      Geometry: {
        WGS84: "POINT (13.171161860883926 55.6943762240635)",
      },
      LocationSignature: "Kgå",
      OfficialLocationName: "Klostergården",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Karlshamn",
      Geometry: {
        WGS84: "POINT (14.866386893161888 56.17713901903182)",
      },
      LocationSignature: "Kh",
      OfficialLocationName: "Karlshamn",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hede",
      Geometry: {
        WGS84: "POINT (12.08436754492456 57.51411892022103)",
      },
      LocationSignature: "Khe",
      OfficialLocationName: "Kungsbacka Hede",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kristinehamn",
      Geometry: {
        WGS84: "POINT (14.107122447815602 59.31739872869066)",
      },
      LocationSignature: "Khn",
      OfficialLocationName: "Kristinehamn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Klenshyttan",
      Geometry: {
        WGS84: "POINT (15.10432409108706 60.10786907773807)",
      },
      LocationSignature: "Khy",
      OfficialLocationName: "Klenshyttan",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kallhäll",
      Geometry: {
        WGS84: "POINT (17.8072890439651 59.45234417653789)",
      },
      LocationSignature: "Khä",
      OfficialLocationName: "Kallhäll",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kirunavaara",
      Geometry: {
        WGS84: "POINT (20.198888681780936 67.80203631674878)",
      },
      LocationSignature: "Kia",
      OfficialLocationName: "Kirunavaara",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kinnared",
      Geometry: {
        WGS84: "POINT (13.107056287205646 57.025220921838944)",
      },
      LocationSignature: "Kid",
      OfficialLocationName: "Kinnared",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kil",
      Geometry: {
        WGS84: "POINT (13.315881692526037 59.50474299102514)",
      },
      LocationSignature: "Kil",
      OfficialLocationName: "Kil",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kinna",
      Geometry: {
        WGS84: "POINT (12.697446501345638 57.50564669962769)",
      },
      LocationSignature: "Kin",
      OfficialLocationName: "Kinna",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kisa",
      Geometry: {
        WGS84: "POINT (15.63410216947283 57.984630824753395)",
      },
      LocationSignature: "Kisa",
      OfficialLocationName: "Kisa",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kjula",
      Geometry: {
        WGS84: "POINT (16.661367442087332 59.337252490019715)",
      },
      LocationSignature: "Kju",
      OfficialLocationName: "Kjula",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Katterjåkk",
      Geometry: {
        WGS84: "POINT (18.1608491256631 68.42015762596341)",
      },
      LocationSignature: "Kjå",
      OfficialLocationName: "Katterjåkk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Klippan",
      Geometry: {
        WGS84: "POINT (13.130034381042627 56.13068834739418)",
      },
      LocationSignature: "Kl",
      OfficialLocationName: "Klippan",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kumla",
      Geometry: {
        WGS84: "POINT (15.140951223316394 59.12754604268638)",
      },
      LocationSignature: "Kla",
      OfficialLocationName: "Kumla",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Klockarbäcken",
      Geometry: {
        WGS84: "POINT (20.14212202931874 63.8562358954284)",
      },
      LocationSignature: "Klbn",
      OfficialLocationName: "Klockarbäcken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kallholsfors",
      Geometry: {
        WGS84: "POINT (14.704242866061014 61.16737009251651)",
      },
      LocationSignature: "Klh",
      OfficialLocationName: "Kallholsfors",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Källby",
      Geometry: {
        WGS84: "POINT (13.301883886978413 58.51056882811847)",
      },
      LocationSignature: "Kll",
      OfficialLocationName: "Källby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kälarne",
      Geometry: {
        WGS84: "POINT (16.083214449312543 62.980635183759375)",
      },
      LocationSignature: "Kln",
      OfficialLocationName: "Kälarne",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Koler",
      Geometry: {
        WGS84: "POINT (20.493215539697424 65.4957903098796)",
      },
      LocationSignature: "Klr",
      OfficialLocationName: "Koler",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kilafors",
      Geometry: {
        WGS84: "POINT (16.57039880748239 61.230689785956244)",
      },
      LocationSignature: "Kls",
      OfficialLocationName: "Kilafors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kilvo",
      Geometry: {
        WGS84: "POINT (20.894671777799616 66.83086244888547)",
      },
      LocationSignature: "Klv",
      OfficialLocationName: "Kilvo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kalix Östra",
      Geometry: {
        WGS84: "POINT (23.180300864610107 65.85662821175931)",
      },
      LocationSignature: "Klx",
      OfficialLocationName: "Kalix Östra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kalix Västra",
      Geometry: {
        WGS84: "POINT (23.143068826107232 65.86487408658917)",
      },
      LocationSignature: "Klxv",
      OfficialLocationName: "Kalix Västra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kilsmo",
      Geometry: {
        WGS84: "POINT (15.535659066749187 59.065691496941184)",
      },
      LocationSignature: "Km",
      OfficialLocationName: "Kilsmo",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kiruna",
      Geometry: {
        WGS84: "POINT (20.1992306127494 67.8682632052675)",
      },
      LocationSignature: "Kmb",
      OfficialLocationName: "Kiruna malmbangård",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kimstad",
      Geometry: {
        WGS84: "POINT (15.96884942551882 58.53626912152928)",
      },
      LocationSignature: "Kms",
      OfficialLocationName: "Kimstad",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kummelby",
      Geometry: {
        WGS84: "POINT (17.956751377736484 59.41756747147486)",
      },
      LocationSignature: "Kmy",
      OfficialLocationName: "Kummelby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Knivsta",
      Geometry: {
        WGS84: "POINT (17.78709920918859 59.725408895627695)",
      },
      LocationSignature: "Kn",
      OfficialLocationName: "Knivsta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kvarnängen",
      Geometry: {
        WGS84: "POINT (17.927513716982958 58.929263989097976)",
      },
      LocationSignature: "Kng",
      OfficialLocationName: "Kvarnängen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Knalleland",
      Geometry: {
        WGS84: "POINT (12.942554118371053 57.7340598314687)",
      },
      LocationSignature: "Knl",
      OfficialLocationName: "Knalleland",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Knapptorp",
      Geometry: {
        WGS84: "POINT (14.930724887501288 59.484154832497644)",
      },
      LocationSignature: "Knp",
      OfficialLocationName: "Knapptorp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kolsnäs",
      Geometry: {
        WGS84: "POINT (13.140935206771791 59.82578001912943)",
      },
      LocationSignature: "Kns",
      OfficialLocationName: "Kolsnäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Knäred",
      Geometry: {
        WGS84: "POINT (13.311517384744116 56.515529657673824)",
      },
      LocationSignature: "Knä",
      OfficialLocationName: "Knäred",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kornsjö gränsen",
      Geometry: {
        WGS84: "POINT (11.659170027956689 58.941305938959005)",
      },
      LocationSignature: "Ko",
      OfficialLocationName: "Kornsjö gränsen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kolke",
      Geometry: {
        WGS84: "POINT (17.214767447065974 59.05841957265223)",
      },
      LocationSignature: "Koe",
      OfficialLocationName: "Kolke",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kosjärv",
      Geometry: {
        WGS84: "POINT (22.841449556609522 65.93127567028083)",
      },
      LocationSignature: "Koj",
      OfficialLocationName: "Kosjärv",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kolmården",
      Geometry: {
        WGS84: "POINT (16.362527356141097 58.674698187520086)",
      },
      LocationSignature: "Kon",
      OfficialLocationName: "Kolmården",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Koskullskulle",
      Geometry: {
        WGS84: "POINT (20.73265529948277 67.18697767170644)",
      },
      LocationSignature: "Kos",
      OfficialLocationName: "Koskullskulle",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Korsnäs",
      Geometry: {
        WGS84: "POINT (15.712718380886704 60.58609748121351)",
      },
      LocationSignature: "Koä",
      OfficialLocationName: "Korsnäs",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Köping",
      Geometry: {
        WGS84: "POINT (16.006600833998384 59.50621623962454)",
      },
      LocationSignature: "Kp",
      OfficialLocationName: "Köping",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kaisepakte",
      Geometry: {
        WGS84: "POINT (19.303326637189404 68.28568779451966)",
      },
      LocationSignature: "Kpe",
      OfficialLocationName: "Kaisepakte",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kopparberg",
      Geometry: {
        WGS84: "POINT (14.986890985813389 59.87263454722176)",
      },
      LocationSignature: "Kpg",
      OfficialLocationName: "Kopparberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Korpklev",
      Geometry: {
        WGS84: "POINT (15.620009929973161 57.93144396805659)",
      },
      LocationSignature: "Kpkv",
      OfficialLocationName: "Korpklev",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Köpmannebro",
      Geometry: {
        WGS84: "POINT (12.50712106135123 58.768453927915246)",
      },
      LocationSignature: "Kpm",
      OfficialLocationName: "Köpmannebro",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Killeberg",
      Geometry: {
        WGS84: "POINT (14.098457574008762 56.474837457314464)",
      },
      LocationSignature: "Kr",
      OfficialLocationName: "Killeberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Karsbäcken",
      Geometry: {
        WGS84: "POINT (19.995986234893046 64.90289079486165)",
      },
      LocationSignature: "Krb",
      OfficialLocationName: "Karsbäcken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kållered",
      Geometry: {
        WGS84: "POINT (12.046219106252126 57.61547580195989)",
      },
      LocationSignature: "Krd",
      OfficialLocationName: "Kållered",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kramfors",
      Geometry: {
        WGS84: "POINT (17.778236851831927 62.92901612571947)",
      },
      LocationSignature: "Krf",
      OfficialLocationName: "Kramfors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Karungi",
      Geometry: {
        WGS84: "POINT (23.95436385771183 66.0383797931731)",
      },
      LocationSignature: "Kri",
      OfficialLocationName: "Karungi",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kringlan",
      Geometry: {
        WGS84: "POINT (17.05491185350233 60.965938853724516)",
      },
      LocationSignature: "Krl",
      OfficialLocationName: "Kringlan",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Krokom",
      Geometry: {
        WGS84: "POINT (14.447974669029959 63.32597269349099)",
      },
      LocationSignature: "Krm",
      OfficialLocationName: "Krokom",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Krampen",
      Geometry: {
        WGS84: "POINT (15.579665070593638 59.74252751581795)",
      },
      LocationSignature: "Krn",
      OfficialLocationName: "Krampen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Krokom OJ",
      Geometry: {
        WGS84: "POINT (14.412679357006242 63.33849565336963)",
      },
      LocationSignature: "Kroj",
      OfficialLocationName: "Krokom OJ",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Krökesbo",
      Geometry: {
        WGS84: "POINT (14.503228032672014 57.70712751813588)",
      },
      LocationSignature: "Kröb",
      OfficialLocationName: "Krökesbo",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Karlstad C",
      Geometry: {
        WGS84: "POINT (13.499025373646646 59.37789093051029)",
      },
      LocationSignature: "Ks",
      OfficialLocationName: "Karlstad central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Karlskoga centrum",
      Geometry: {
        WGS84: "POINT (14.522877800385857 59.32482845940364)",
      },
      LocationSignature: "Ksac",
      OfficialLocationName: "Karlskoga centrum",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Karlstad C",
      Geometry: {
        WGS84: "POINT (13.499025373646646 59.37789093051029)",
      },
      LocationSignature: "Ksc",
      OfficialLocationName: "Karlstad central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Karlsberg",
      Geometry: {
        WGS84: "POINT (15.640723249514275 62.9413180839135)",
      },
      LocationSignature: "Ksg",
      OfficialLocationName: "Karlsberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kistinge",
      Geometry: {
        WGS84: "POINT (12.914015225466486 56.63853542766419)",
      },
      LocationSignature: "Kst",
      OfficialLocationName: "Kistinge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kvicksund",
      Geometry: {
        WGS84: "POINT (16.309467156943033 59.45813501090446)",
      },
      LocationSignature: "Ksu",
      OfficialLocationName: "Kvicksund",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kvicksund",
      Geometry: {
        WGS84: "POINT (16.320647961765943 59.450774679861105)",
      },
      LocationSignature: "Ksus",
      OfficialLocationName: "Kvicksunds Södra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Karsjö",
      Geometry: {
        WGS84: "POINT (16.26154159472413 61.64022873655604)",
      },
      LocationSignature: "Ksö",
      OfficialLocationName: "Karsjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kapellet",
      Geometry: {
        WGS84: "POINT (14.588470640783209 59.402057702242516)",
      },
      LocationSignature: "Kt",
      OfficialLocationName: "Kapellet",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kaitum",
      Geometry: {
        WGS84: "POINT (20.103269081398903 67.54257739184348)",
      },
      LocationSignature: "Ktm",
      OfficialLocationName: "Kaitum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Korsträsk",
      Geometry: {
        WGS84: "POINT (20.86457954292862 65.66468899340036)",
      },
      LocationSignature: "Ktä",
      OfficialLocationName: "Korsträsk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Klingerud",
      Geometry: {
        WGS84: "POINT (13.360805222677534 59.4833346179173)",
      },
      LocationSignature: "Kud",
      OfficialLocationName: "Klingerud",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Krokvik",
      Geometry: {
        WGS84: "POINT (20.066745806229893 67.93122721069808)",
      },
      LocationSignature: "Kv",
      OfficialLocationName: "Krokvik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Koskivaara",
      Geometry: {
        WGS84: "POINT (20.984461450548906 66.6494408216348)",
      },
      LocationSignature: "Kva",
      OfficialLocationName: "Koskivaara",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kvillsfors",
      Geometry: {
        WGS84: "POINT (15.495698364997274 57.40326945833851)",
      },
      LocationSignature: "Kvf",
      OfficialLocationName: "Kvillsfors",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Klevshult",
      Geometry: {
        WGS84: "POINT (14.09731711542775 57.35156606171029)",
      },
      LocationSignature: "Kvh",
      OfficialLocationName: "Klevshult",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kvidinge",
      Geometry: {
        WGS84: "POINT (13.046139823249574 56.137208176824466)",
      },
      LocationSignature: "Kvi",
      OfficialLocationName: "Kvidinge",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Välsviken",
      Geometry: {
        WGS84: "POINT (13.582080627872887 59.391133638116976)",
      },
      LocationSignature: "Kvä",
      OfficialLocationName: "Karlstad Välsviken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kvarnsjö",
      Geometry: {
        WGS84: "POINT (14.37248290236932 62.56592387535783)",
      },
      LocationSignature: "Kvö",
      OfficialLocationName: "Kvarnsjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kalixfors",
      Geometry: {
        WGS84: "POINT (20.20855464388276 67.74151691537413)",
      },
      LocationSignature: "Kx",
      OfficialLocationName: "Kalixfors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kopparåsen",
      Geometry: {
        WGS84: "POINT (18.503618022578504 68.42627551104769)",
      },
      LocationSignature: "Kå",
      OfficialLocationName: "Kopparåsen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kåbdalis",
      Geometry: {
        WGS84: "POINT (19.988721994748417 66.15278823403678)",
      },
      LocationSignature: "Kåb",
      OfficialLocationName: "Kåbdalis",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Källeryd",
      Geometry: {
        WGS84: "POINT (13.193576752434629 58.11463779435964)",
      },
      LocationSignature: "Kä",
      OfficialLocationName: "Källeryd",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kärreberga",
      Geometry: {
        WGS84: "POINT (12.983455452642477 56.138357587916126)",
      },
      LocationSignature: "Käb",
      OfficialLocationName: "Kärreberga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Källene",
      Geometry: {
        WGS84: "POINT (17.016655515359567 61.33352119082505)",
      },
      LocationSignature: "Käe",
      OfficialLocationName: "Källene",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kungsängen",
      Geometry: {
        WGS84: "POINT (17.752825186759477 59.47812275142195)",
      },
      LocationSignature: "Kän",
      OfficialLocationName: "Kungsängen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kärrgruvan",
      Geometry: {
        WGS84: "POINT (15.93558589928753 60.09814757550664)",
      },
      LocationSignature: "Kär",
      OfficialLocationName: "Kärrgruvan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kälvattnet",
      Geometry: {
        WGS84: "POINT (17.977831370233577 63.42145616202046)",
      },
      LocationSignature: "Käv",
      OfficialLocationName: "Kälvattnet",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Könsa",
      Geometry: {
        WGS84: "POINT (19.189901265604938 63.39112429230991)",
      },
      LocationSignature: "Köa",
      OfficialLocationName: "Könsa",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Köpingebro",
      Geometry: {
        WGS84: "POINT (13.885748407455768 55.46936305649391)",
      },
      LocationSignature: "Köp",
      OfficialLocationName: "Köpingebro",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kungsör",
      Geometry: {
        WGS84: "POINT (16.103102241021354 59.427798914452396)",
      },
      LocationSignature: "Kör",
      OfficialLocationName: "Kungsör",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ludvika",
      Geometry: {
        WGS84: "POINT (15.182493903383179 60.14987846259443)",
      },
      LocationSignature: "La",
      OfficialLocationName: "Ludvika",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lappberg",
      Geometry: {
        WGS84: "POINT (20.196095798107997 67.59295861887688)",
      },
      LocationSignature: "Lab",
      OfficialLocationName: "Lappberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Laholm",
      Geometry: {
        WGS84: "POINT (12.999365652360769 56.50268971625489)",
      },
      LocationSignature: "Laov",
      OfficialLocationName: "Laholm västra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lingbo",
      Geometry: {
        WGS84: "POINT (16.67279774440863 61.044939674824505)",
      },
      LocationSignature: "Lb",
      OfficialLocationName: "Lingbo",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lindesberg",
      Geometry: {
        WGS84: "POINT (15.228644833766962 59.59451622006108)",
      },
      LocationSignature: "Ld",
      OfficialLocationName: "Lindesberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lidlund",
      Geometry: {
        WGS84: "POINT (20.025426640807943 64.98215222947049)",
      },
      LocationSignature: "Ldl",
      OfficialLocationName: "Lidlund",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lindome",
      Geometry: {
        WGS84: "POINT (12.078728825970762 57.57603023678457)",
      },
      LocationSignature: "Ldo",
      OfficialLocationName: "Lindome",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Luleå C",
      Geometry: {
        WGS84: "POINT (22.165607454087375 65.584085923056)",
      },
      LocationSignature: "Le",
      OfficialLocationName: "Luleå",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Karlsvik",
      Geometry: {
        WGS84: "POINT (22.03196550004581 65.59741712420798)",
      },
      LocationSignature: "Le.k",
      OfficialLocationName: "Karlsvik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Leksboda",
      Geometry: {
        WGS84: "POINT (15.020214726665829 60.77658537548002)",
      },
      LocationSignature: "Lea",
      OfficialLocationName: "Leksboda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lekarekulle",
      Geometry: {
        WGS84: "POINT (12.151821057868393 57.40443993447667)",
      },
      LocationSignature: "Lek",
      OfficialLocationName: "Lekarekulle",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lene",
      Geometry: {
        WGS84: "POINT (13.120728427445593 59.54409389930647)",
      },
      LocationSignature: "Len",
      OfficialLocationName: "Lene",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lödöse Södra",
      Geometry: {
        WGS84: "POINT (12.164954942020112 58.01461620158086)",
      },
      LocationSignature: "Les",
      OfficialLocationName: "Lödöse Södra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lilla Edet",
      Geometry: {
        WGS84: "POINT (12.125774004499059 58.13182508085498)",
      },
      LocationSignature: "Let",
      OfficialLocationName: "Lilla Edet",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Läggesta",
      Geometry: {
        WGS84: "POINT (17.169099180406807 59.24754847952218)",
      },
      LocationSignature: "Lg",
      OfficialLocationName: "Läggesta",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Långbron",
      Geometry: {
        WGS84: "POINT (12.279924112504045 58.93645459757097)",
      },
      LocationSignature: "Lgb",
      OfficialLocationName: "Långbron",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ledsgård",
      Geometry: {
        WGS84: "POINT (12.09604503228337 57.52857082584413)",
      },
      LocationSignature: "Lgd",
      OfficialLocationName: "Ledsgård",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Linghem",
      Geometry: {
        WGS84: "POINT (15.785130833051785 58.43628121931304)",
      },
      LocationSignature: "Lgm",
      OfficialLocationName: "Linghem",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lammhult",
      Geometry: {
        WGS84: "POINT (14.585611240196648 57.17185120494095)",
      },
      LocationSignature: "Lh",
      OfficialLocationName: "Lammhult",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lillhamra",
      Geometry: {
        WGS84: "POINT (14.795872653513184 61.64161764211106)",
      },
      LocationSignature: "Lhr",
      OfficialLocationName: "Lillhamra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lillaryd",
      Geometry: {
        WGS84: "POINT (13.363006759579795 56.133968485499665)",
      },
      LocationSignature: "Lia",
      OfficialLocationName: "Lillaryd",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Linaälv",
      Geometry: {
        WGS84: "POINT (20.239292040970703 67.25678850323378)",
      },
      LocationSignature: "Lin",
      OfficialLocationName: "Linaälv",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Liseberg",
      Geometry: {
        WGS84: "POINT (11.996703861193009 57.69950383014116)",
      },
      LocationSignature: "Lis",
      OfficialLocationName: "Liseberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lit",
      Geometry: {
        WGS84: "POINT (14.85672673894211 63.31839791239978)",
      },
      LocationSignature: "Lit",
      OfficialLocationName: "Lit",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ljungskile",
      Geometry: {
        WGS84: "POINT (11.921011541713993 58.22476363859184)",
      },
      LocationSignature: "Lj",
      OfficialLocationName: "Ljungskile",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ljung",
      Geometry: {
        WGS84: "POINT (13.053311259954258 57.986451559040695)",
      },
      LocationSignature: "Lju",
      OfficialLocationName: "Ljung",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ljungaverks lastplats",
      Geometry: {
        WGS84: "POINT (16.078300553628065 62.49614586937123)",
      },
      LocationSignature: "Ljun",
      OfficialLocationName: "Ljungaverks lastplats",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ljungaverk",
      Geometry: {
        WGS84: "POINT (16.067429944561987 62.49558304542039)",
      },
      LocationSignature: "Ljv",
      OfficialLocationName: "Ljungaverk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lökom",
      Geometry: {
        WGS84: "POINT (17.60172377087706 63.16520546865781)",
      },
      LocationSignature: "Lkm",
      OfficialLocationName: "Lökom",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lindekullen",
      Geometry: {
        WGS84: "POINT (15.095863039689155 58.25529933299371)",
      },
      LocationSignature: "Lkn",
      OfficialLocationName: "Lindekullen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lidköping",
      Geometry: {
        WGS84: "POINT (13.165097294639642 58.50439218701964)",
      },
      LocationSignature: "Lkp",
      OfficialLocationName: "Lidköping",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lakaträsk",
      Geometry: {
        WGS84: "POINT (21.1253368971514 66.27531989954385)",
      },
      LocationSignature: "Lkä",
      OfficialLocationName: "Lakaträsk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Landskrona",
      Geometry: {
        WGS84: "POINT (12.857361724508854 55.87909039272977)",
      },
      LocationSignature: "Lkö",
      OfficialLocationName: "Landskrona Östra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Linddalen",
      Geometry: {
        WGS84: "POINT (14.769071065718263 59.01139705517916)",
      },
      LocationSignature: "Lln",
      OfficialLocationName: "Linddalen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Liljeholmen",
      Geometry: {
        WGS84: "POINT (18.026391864198825 59.30889139708874)",
      },
      LocationSignature: "Lm",
      OfficialLocationName: "Liljeholmen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lomma",
      Geometry: {
        WGS84: "POINT (13.074390729351423 55.67099445875928)",
      },
      LocationSignature: "Lma",
      OfficialLocationName: "Lomma",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Limmared",
      Geometry: {
        WGS84: "POINT (13.353545134666659 57.53398234056574)",
      },
      LocationSignature: "Lme",
      OfficialLocationName: "Limmared",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lemmeströ",
      Geometry: {
        WGS84: "POINT (13.363747729870992 55.49160938707948)",
      },
      LocationSignature: "Lmm",
      OfficialLocationName: "Lemmeströ",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Långviksmon",
      Geometry: {
        WGS84: "POINT (18.692608540783947 63.63345254824014)",
      },
      LocationSignature: "Lmn",
      OfficialLocationName: "Långviksmon",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Löcknamon",
      Geometry: {
        WGS84: "POINT (15.07123172663151 58.9133756901394)",
      },
      LocationSignature: "Lmo",
      OfficialLocationName: "Löcknamon",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lomselenäs",
      Geometry: {
        WGS84: "POINT (17.64635087566668 65.21555931413745)",
      },
      LocationSignature: "Lms",
      OfficialLocationName: "Lomselenäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lernacken",
      Geometry: {
        WGS84: "POINT (12.905518439114443 55.56561595256236)",
      },
      LocationSignature: "Lnk",
      OfficialLocationName: "Lernacken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lidnäs",
      Geometry: {
        WGS84: "POINT (14.600253224808421 57.070116727083935)",
      },
      LocationSignature: "Lns",
      OfficialLocationName: "Lidnäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Långvattnet",
      Geometry: {
        WGS84: "POINT (18.731308225580943 63.652859421080926)",
      },
      LocationSignature: "Lnv",
      OfficialLocationName: "Långvattnet",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lugnås",
      Geometry: {
        WGS84: "POINT (13.701910366621059 58.64430358614937)",
      },
      LocationSignature: "Lnå",
      OfficialLocationName: "Lugnås",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lessebo",
      Geometry: {
        WGS84: "POINT (15.25852918518695 56.750561692930205)",
      },
      LocationSignature: "Lo",
      OfficialLocationName: "Lessebo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Loka",
      Geometry: {
        WGS84: "POINT (14.469001657209652 59.63644734012384)",
      },
      LocationSignature: "Loka",
      OfficialLocationName: "Loka",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lomsmyren",
      Geometry: {
        WGS84: "POINT (14.511798735402813 60.98265667214552)",
      },
      LocationSignature: "Lom",
      OfficialLocationName: "Lomsmyren",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lottefors",
      Geometry: {
        WGS84: "POINT (16.41154554725869 61.420168480799155)",
      },
      LocationSignature: "Lot",
      OfficialLocationName: "Lottefors",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Linköping C",
      Geometry: {
        WGS84: "POINT (15.624342206193218 58.41703698401011)",
      },
      LocationSignature: "Lp",
      OfficialLocationName: "Linköpings central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lappträsk",
      Geometry: {
        WGS84: "POINT (23.513274097321137 66.03328471936935)",
      },
      LocationSignature: "Lpk",
      OfficialLocationName: "Lappträsk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lerum",
      Geometry: {
        WGS84: "POINT (12.272803985862398 57.769401630605834)",
      },
      LocationSignature: "Lr",
      OfficialLocationName: "Lerum",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Landeryd",
      Geometry: {
        WGS84: "POINT (13.265593771416139 57.076728966504604)",
      },
      LocationSignature: "Lrd",
      OfficialLocationName: "Landeryd",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lästringe",
      Geometry: {
        WGS84: "POINT (17.32717274554208 58.87514083459249)",
      },
      LocationSignature: "Lre",
      OfficialLocationName: "Lästringe",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Laduberg",
      Geometry: {
        WGS84: "POINT (21.109971503157226 65.72180998753866)",
      },
      LocationSignature: "Lrg",
      OfficialLocationName: "Laduberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lockarp",
      Geometry: {
        WGS84: "POINT (13.040833647616296 55.54720085629079)",
      },
      LocationSignature: "Lrp",
      OfficialLocationName: "Lockarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lerot",
      Geometry: {
        WGS84: "POINT (12.362895152693865 59.82656191193797)",
      },
      LocationSignature: "Lrt",
      OfficialLocationName: "Lerot",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ljusdal",
      Geometry: {
        WGS84: "POINT (16.096509755425156 61.82680948980172)",
      },
      LocationSignature: "Ls",
      OfficialLocationName: "Ljusdal",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ljusne bruk",
      Geometry: {
        WGS84: "POINT (17.116579938988806 61.21710028836408)",
      },
      LocationSignature: "Lsb",
      OfficialLocationName: "Ljusne bruk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Leksand",
      Geometry: {
        WGS84: "POINT (15.003007122656749 60.73403002584899)",
      },
      LocationSignature: "Lsd",
      OfficialLocationName: "Leksand",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ljusne",
      Geometry: {
        WGS84: "POINT (17.111846833114104 61.212611848468164)",
      },
      LocationSignature: "Lse",
      OfficialLocationName: "Ljusne",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Långsjön",
      Geometry: {
        WGS84: "POINT (16.091515860617193 60.587321829471385)",
      },
      LocationSignature: "Lsj",
      OfficialLocationName: "Långsjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Långsele",
      Geometry: {
        WGS84: "POINT (17.067174362704332 63.17639168969147)",
      },
      LocationSignature: "Lsl",
      OfficialLocationName: "Långsele",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Losesjön",
      Geometry: {
        WGS84: "POINT (16.99445227772619 61.41053218375311)",
      },
      LocationSignature: "Lsn",
      OfficialLocationName: "Losesjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lycksele industrispår",
      Geometry: {
        WGS84: "POINT (18.69954455398051 64.5895089947838)",
      },
      LocationSignature: "Lsp",
      OfficialLocationName: "Lycksele industrispår",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ljuså",
      Geometry: {
        WGS84: "POINT (21.60035195015171 65.97794918724358)",
      },
      LocationSignature: "Lså",
      OfficialLocationName: "Ljuså",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Långträsk",
      Geometry: {
        WGS84: "POINT (20.336314585176247 65.37735589246655)",
      },
      LocationSignature: "Ltk",
      OfficialLocationName: "Långträsk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Loster",
      Geometry: {
        WGS84: "POINT (15.949125921882592 61.939450688122385)",
      },
      LocationSignature: "Ltr",
      OfficialLocationName: "Loster",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lund C",
      Geometry: {
        WGS84: "POINT (13.186572717507945 55.70724958024215)",
      },
      LocationSignature: "Lu",
      OfficialLocationName: "Lund c",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lundby",
      Geometry: {
        WGS84: "POINT (16.927798317305335 59.654588773898396)",
      },
      LocationSignature: "Lub",
      OfficialLocationName: "Lundby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lunnarp",
      Geometry: {
        WGS84: "POINT (14.042069931219482 55.5410833838634)",
      },
      LocationSignature: "Lup",
      OfficialLocationName: "Lunnarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lubboträsk",
      Geometry: {
        WGS84: "POINT (19.929371589616856 64.5591042379907)",
      },
      LocationSignature: "Luä",
      OfficialLocationName: "Lubboträsk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lövliden",
      Geometry: {
        WGS84: "POINT (16.612365054050933 64.65448629938518)",
      },
      LocationSignature: "Lvd",
      OfficialLocationName: "Lövliden",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lovene",
      Geometry: {
        WGS84: "POINT (13.046556427646616 58.440223450417044)",
      },
      LocationSignature: "Lve",
      OfficialLocationName: "Lovene",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lycksele",
      Geometry: {
        WGS84: "POINT (18.668774252566916 64.59426717315466)",
      },
      LocationSignature: "Ly",
      OfficialLocationName: "Lycksele",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lyrestad",
      Geometry: {
        WGS84: "POINT (14.05657579837766 58.80493833797479)",
      },
      LocationSignature: "Lyd",
      OfficialLocationName: "Lyrestad",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lyse",
      Geometry: {
        WGS84: "POINT (11.49439044351098 58.32371159475235)",
      },
      LocationSignature: "Lye",
      OfficialLocationName: "Lyse",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lyckselesågen",
      Geometry: {
        WGS84: "POINT (18.72848558205344 64.5926885583373)",
      },
      LocationSignature: "Lyg",
      OfficialLocationName: "Lyckselesågen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lysekil",
      Geometry: {
        WGS84: "POINT (11.441354460692812 58.27578865693086)",
      },
      LocationSignature: "Lys",
      OfficialLocationName: "Lysekil",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lycksele timmer",
      Geometry: {
        WGS84: "POINT (18.75754570960207 64.59031547946381)",
      },
      LocationSignature: "Lyt",
      OfficialLocationName: "Lycksele timmer",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lysvik",
      Geometry: {
        WGS84: "POINT (13.13778300680481 60.01595053795713)",
      },
      LocationSignature: "Lyv",
      OfficialLocationName: "Lysvik",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Laxå",
      Geometry: {
        WGS84: "POINT (14.614602720877377 58.9889191110449)",
      },
      LocationSignature: "Lå",
      OfficialLocationName: "Laxå",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Låktatjåkka",
      Geometry: {
        WGS84: "POINT (18.324742210763095 68.42368660501461)",
      },
      LocationSignature: "Låk",
      OfficialLocationName: "Låktatjåkka",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lörstrand",
      Geometry: {
        WGS84: "POINT (16.165462823026107 61.65880342812682)",
      },
      LocationSignature: "Lö",
      OfficialLocationName: "Lörstrand",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lövberga",
      Geometry: {
        WGS84: "POINT (15.852410668022923 63.96127509535417)",
      },
      LocationSignature: "Löb",
      OfficialLocationName: "Lövberga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lönneberga",
      Geometry: {
        WGS84: "POINT (15.720032119609382 57.55224079879933)",
      },
      LocationSignature: "Lön",
      OfficialLocationName: "Lönneberga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lödöse",
      Geometry: {
        WGS84: "POINT (12.151245118900565 58.02752059762761)",
      },
      LocationSignature: "Löö",
      OfficialLocationName: "Lödöse",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Malmö C",
      Geometry: {
        WGS84: "POINT (13.000229772341523 55.609718195959864)",
      },
      LocationSignature: "M",
      OfficialLocationName: "Malmö central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mattmar",
      Geometry: {
        WGS84: "POINT (13.83018646209759 63.30331305828994)",
      },
      LocationSignature: "Ma",
      OfficialLocationName: "Mattmar",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Marma",
      Geometry: {
        WGS84: "POINT (17.43086205757493 60.49150378138229)",
      },
      LocationSignature: "Maa",
      OfficialLocationName: "Marma norra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Maj",
      Geometry: {
        WGS84: "POINT (17.40136160170673 62.23312984703997)",
      },
      LocationSignature: "Maj",
      OfficialLocationName: "Maj",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Marieholm",
      Geometry: {
        WGS84: "POINT (13.155793036541002 55.86480001866541)",
      },
      LocationSignature: "Mar",
      OfficialLocationName: "Marieholm",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mobodarne",
      Geometry: {
        WGS84: "POINT (16.7148222766878 61.28092341280449)",
      },
      LocationSignature: "Mb",
      OfficialLocationName: "Mobodarne",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Murbergsviken",
      Geometry: {
        WGS84: "POINT (17.9272604938995 62.653926312647535)",
      },
      LocationSignature: "Mbv",
      OfficialLocationName: "Murbergsviken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Malmby",
      Geometry: {
        WGS84: "POINT (17.05345151484165 59.343899341696144)",
      },
      LocationSignature: "Mby",
      OfficialLocationName: "Malmby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Malmbäck",
      Geometry: {
        WGS84: "POINT (14.460424902962865 57.577996215266744)",
      },
      LocationSignature: "Mbä",
      OfficialLocationName: "Malmbäck",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Moradal",
      Geometry: {
        WGS84: "POINT (15.54769491290494 62.56381762029011)",
      },
      LocationSignature: "Mdl",
      OfficialLocationName: "Moradal",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mariedamm",
      Geometry: {
        WGS84: "POINT (15.162035691610491 58.85287440436758)",
      },
      LocationSignature: "Mdm",
      OfficialLocationName: "Mariedamm",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Mölndal",
      Geometry: {
        WGS84: "POINT (12.01666374798342 57.663387110687026)",
      },
      LocationSignature: "Mdn",
      OfficialLocationName: "Mölndals nedre",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mölndals övre",
      Geometry: {
        WGS84: "POINT (12.030969629224018 57.6584571095213)",
      },
      LocationSignature: "Mdö",
      OfficialLocationName: "Mölndals övre",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Marmaverken",
      Geometry: {
        WGS84: "POINT (16.863436611581 61.27045993906213)",
      },
      LocationSignature: "Me",
      OfficialLocationName: "Marmaverken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Mehedeby",
      Geometry: {
        WGS84: "POINT (17.40069752324232 60.45069394886442)",
      },
      LocationSignature: "Meh",
      OfficialLocationName: "Mehedeby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mockfjärd",
      Geometry: {
        WGS84: "POINT (14.958373542671264 60.495790026339314)",
      },
      LocationSignature: "Mfj",
      OfficialLocationName: "Mockfjärd",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Munkflohögen",
      Geometry: {
        WGS84: "POINT (14.948973113312919 63.56008736688588)",
      },
      LocationSignature: "Mfn",
      OfficialLocationName: "Munkflohögen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Malungsfors",
      Geometry: {
        WGS84: "POINT (13.55570958933087 60.73876168629439)",
      },
      LocationSignature: "Mfs",
      OfficialLocationName: "Malungsfors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Malmö godsbangård",
      Geometry: {
        WGS84: "POINT (13.051048595077065 55.621995414730016)",
      },
      LocationSignature: "Mgb",
      OfficialLocationName: "Malmö godsbangård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Moholm",
      Geometry: {
        WGS84: "POINT (14.040189112219359 58.607131690503714)",
      },
      LocationSignature: "Mh",
      OfficialLocationName: "Moholm",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Myrheden",
      Geometry: {
        WGS84: "POINT (20.215933067309656 65.29948504721146)",
      },
      LocationSignature: "Mhn",
      OfficialLocationName: "Myrheden",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Mörsil",
      Geometry: {
        WGS84: "POINT (13.657733740685659 63.308534568220175)",
      },
      LocationSignature: "Mi",
      OfficialLocationName: "Mörsil",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Maria",
      Geometry: {
        WGS84: "POINT (12.71059765487515 56.07556905669264)",
      },
      LocationSignature: "Mia",
      OfficialLocationName: "Maria",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mimer",
      Geometry: {
        WGS84: "POINT (15.94582380760837 60.07826546414226)",
      },
      LocationSignature: "Mim",
      OfficialLocationName: "Mimer",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Milletorp",
      Geometry: {
        WGS84: "POINT (15.184680048059874 57.23865053151265)",
      },
      LocationSignature: "Mip",
      OfficialLocationName: "Milletorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Morjärv",
      Geometry: {
        WGS84: "POINT (22.695179774553598 66.06972976667032)",
      },
      LocationSignature: "Mjv",
      OfficialLocationName: "Morjärv",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Murjek",
      Geometry: {
        WGS84: "POINT (20.880749813051352 66.48412917099675)",
      },
      LocationSignature: "Mk",
      OfficialLocationName: "Murjek",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Munkedal",
      Geometry: {
        WGS84: "POINT (11.674984460366113 58.46688501241287)",
      },
      LocationSignature: "Mkl",
      OfficialLocationName: "Munkedal",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Munkedals hamn",
      Geometry: {
        WGS84: "POINT (11.679186378967866 58.43835740284852)",
      },
      LocationSignature: "Mklh",
      OfficialLocationName: "Munkedals hamn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Munkedals nedre",
      Geometry: {
        WGS84: "POINT (11.67869862022587 58.465156712510705)",
      },
      LocationSignature: "Mkln",
      OfficialLocationName: "Munkedals nedre",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Mellerud",
      Geometry: {
        WGS84: "POINT (12.464787660936478 58.701830658869376)",
      },
      LocationSignature: "Ml",
      OfficialLocationName: "Mellerud",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mellby",
      Geometry: {
        WGS84: "POINT (13.737316751830134 56.07491373039666)",
      },
      LocationSignature: "Mlb",
      OfficialLocationName: "Mellby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Meselefors",
      Geometry: {
        WGS84: "POINT (16.816720395920665 64.44300746313709)",
      },
      LocationSignature: "Mlf",
      OfficialLocationName: "Meselefors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Malung",
      Geometry: {
        WGS84: "POINT (13.710375683947163 60.68360564509348)",
      },
      LocationSignature: "Mlg",
      OfficialLocationName: "Malung",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Malung Folkhögskola",
      Geometry: {
        WGS84: "POINT (13.73534726229212 60.66828226480445)",
      },
      LocationSignature: "Mlgf",
      OfficialLocationName: "Malungs folkhögskola",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Malung Gymnasium",
      Geometry: {
        WGS84: "POINT (13.72374227758868 60.679432473036556)",
      },
      LocationSignature: "Mlgg",
      OfficialLocationName: "Malungs gymnasium",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mellösa",
      Geometry: {
        WGS84: "POINT (16.55964778903859 59.10084758936241)",
      },
      LocationSignature: "Mlö",
      OfficialLocationName: "Mellösa",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mariannelund",
      Geometry: {
        WGS84: "POINT (15.585301141237197 57.61600562447327)",
      },
      LocationSignature: "Mnd",
      OfficialLocationName: "Mariannelund",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Moheda",
      Geometry: {
        WGS84: "POINT (14.576590184629076 57.00312065683874)",
      },
      LocationSignature: "Mo",
      OfficialLocationName: "Moheda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mo grindar",
      Geometry: {
        WGS84: "POINT (16.71169973853212 60.94206165233246)",
      },
      LocationSignature: "Mog",
      OfficialLocationName: "Mo grindar",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mon",
      Geometry: {
        WGS84: "POINT (11.765799622120207 58.90902955119506)",
      },
      LocationSignature: "Mon",
      OfficialLocationName: "Mon",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Munktorp",
      Geometry: {
        WGS84: "POINT (16.134057758303435 59.5332291421602)",
      },
      LocationSignature: "Morp",
      OfficialLocationName: "Munktorp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Moskosel",
      Geometry: {
        WGS84: "POINT (19.42657862259086 65.88241661989609)",
      },
      LocationSignature: "Mos",
      OfficialLocationName: "Moskosel",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Motala C",
      Geometry: {
        WGS84: "POINT (15.048178636126666 58.537506804953296)",
      },
      LocationSignature: "Mot",
      OfficialLocationName: "Motala central",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Mollaryd",
      Geometry: {
        WGS84: "POINT (13.022665314312638 57.90621466640094)",
      },
      LocationSignature: "Moy",
      OfficialLocationName: "Mollaryd",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mölltorp",
      Geometry: {
        WGS84: "POINT (14.416862231176792 58.4935389098249)",
      },
      LocationSignature: "Mp",
      OfficialLocationName: "Mölltorp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Persborg",
      Geometry: {
        WGS84: "POINT (13.029363554491523 55.58063014112111)",
      },
      LocationSignature: "Mpb",
      OfficialLocationName: "Malmö Persborg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Märsta",
      Geometry: {
        WGS84: "POINT (17.861556068154965 59.627719092214846)",
      },
      LocationSignature: "Mr",
      OfficialLocationName: "Märsta",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Mora",
      Geometry: {
        WGS84: "POINT (14.559096105462443 61.00871230738821)",
      },
      LocationSignature: "Mra",
      OfficialLocationName: "Mora",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mora kommuns industrispår",
      Geometry: {
        WGS84: "POINT (14.541055760022665 60.9951850681577)",
      },
      LocationSignature: "Mrai",
      OfficialLocationName: "Mora kommuns industrispår",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Morastrand",
      Geometry: {
        WGS84: "POINT (14.54248942185974 61.00476054846952)",
      },
      LocationSignature: "Mras",
      OfficialLocationName: "Morastrand",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Mora",
      Geometry: {
        WGS84: "POINT (14.559096105462443 61.00871230738821)",
      },
      LocationSignature: "Mrc",
      OfficialLocationName: "Mora central",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Markaryd",
      Geometry: {
        WGS84: "POINT (13.593922735903572 56.45961821495866)",
      },
      LocationSignature: "Mrd",
      OfficialLocationName: "Markaryd",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Axhult",
      Geometry: {
        WGS84: "POINT (13.692607159687721 56.516835950095555)",
      },
      LocationSignature: "Mrd.ax",
      OfficialLocationName: "Axhult",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Marsvinsholm",
      Geometry: {
        WGS84: "POINT (13.694029878198478 55.464295506045836)",
      },
      LocationSignature: "Mrh",
      OfficialLocationName: "Marsvinsholm",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Mora lasarett",
      Geometry: {
        WGS84: "POINT (14.58108177023721 61.01605454979477)",
      },
      LocationSignature: "Mrl",
      OfficialLocationName: "Mora lasarett",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Marma",
      Geometry: {
        WGS84: "POINT (17.421016802707587 60.48009515174406)",
      },
      LocationSignature: "Mrm",
      OfficialLocationName: "Marma",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Morshyttan",
      Geometry: {
        WGS84: "POINT (16.37538504301754 60.26186242189954)",
      },
      LocationSignature: "Mrs",
      OfficialLocationName: "Morshyttan",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Mörrum",
      Geometry: {
        WGS84: "POINT (14.742953091127111 56.18685415895982)",
      },
      LocationSignature: "Mru",
      OfficialLocationName: "Mörrum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mosås",
      Geometry: {
        WGS84: "POINT (15.162633055324918 59.19777465022302)",
      },
      LocationSignature: "Ms",
      OfficialLocationName: "Mosås",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mönsterås bruk",
      Geometry: {
        WGS84: "POINT (16.5369812274077 57.08492152398162)",
      },
      LocationSignature: "Msb",
      OfficialLocationName: "Mönsterås bruk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Malmsjö",
      Geometry: {
        WGS84: "POINT (17.762635393655522 59.15409762975881)",
      },
      LocationSignature: "Msj",
      OfficialLocationName: "Malmsjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mellansel",
      Geometry: {
        WGS84: "POINT (18.30672782455455 63.428235435638086)",
      },
      LocationSignature: "Msl",
      OfficialLocationName: "Mellansel",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Medskogsheden",
      Geometry: {
        WGS84: "POINT (16.699216962621506 60.79123623314869)",
      },
      LocationSignature: "Msn",
      OfficialLocationName: "Medskogsheden",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mönsterås",
      Geometry: {
        WGS84: "POINT (16.446906080559234 57.04014558104898)",
      },
      LocationSignature: "Mss",
      OfficialLocationName: "Mönsterås",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Mariestad",
      Geometry: {
        WGS84: "POINT (13.825665890319714 58.7112583423946)",
      },
      LocationSignature: "Mst",
      OfficialLocationName: "Mariestad",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mellansjö",
      Geometry: {
        WGS84: "POINT (15.68223292664033 62.3078124317795)",
      },
      LocationSignature: "Msö",
      OfficialLocationName: "Mellansjö",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Mantorp",
      Geometry: {
        WGS84: "POINT (15.288154576706267 58.347988802692036)",
      },
      LocationSignature: "Mt",
      OfficialLocationName: "Mantorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Maitum",
      Geometry: {
        WGS84: "POINT (19.70254128352351 66.39017729339639)",
      },
      LocationSignature: "Mtm",
      OfficialLocationName: "Maitum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mertainen",
      Geometry: {
        WGS84: "POINT (20.843751864641916 67.67781854974298)",
      },
      LocationSignature: "Mtn",
      OfficialLocationName: "Mertainen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Mullsjö",
      Geometry: {
        WGS84: "POINT (13.881370834582835 57.91503506588997)",
      },
      LocationSignature: "Mu",
      OfficialLocationName: "Mullsjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mosselund",
      Geometry: {
        WGS84: "POINT (13.850435129977809 56.21580506683171)",
      },
      LocationSignature: "Mud",
      OfficialLocationName: "Mosselund",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Mjölby",
      Geometry: {
        WGS84: "POINT (15.132101007286456 58.32274619735859)",
      },
      LocationSignature: "My",
      OfficialLocationName: "Mjölby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Mölnlycke",
      Geometry: {
        WGS84: "POINT (12.124506245707574 57.65839047066676)",
      },
      LocationSignature: "Myk",
      OfficialLocationName: "Mölnlycke",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Myrbacken",
      Geometry: {
        WGS84: "POINT (17.80770264584468 59.69401662762307)",
      },
      LocationSignature: "Myn",
      OfficialLocationName: "Myrbacken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Myra",
      Geometry: {
        WGS84: "POINT (16.98725458581181 61.50653734619269)",
      },
      LocationSignature: "Myra",
      OfficialLocationName: "Myra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Morgongåva",
      Geometry: {
        WGS84: "POINT (16.96136180078778 59.9329057877021)",
      },
      LocationSignature: "Må",
      OfficialLocationName: "Morgongåva",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Målilla",
      Geometry: {
        WGS84: "POINT (15.824829427517638 57.38970455172794)",
      },
      LocationSignature: "Måa",
      OfficialLocationName: "Målilla",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Månsarp",
      Geometry: {
        WGS84: "POINT (14.07105156879612 57.65400314888071)",
      },
      LocationSignature: "Måp",
      OfficialLocationName: "Månsarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Märbäck",
      Geometry: {
        WGS84: "POINT (14.074393562897118 61.20125050851892)",
      },
      LocationSignature: "Mäk",
      OfficialLocationName: "Märbäck",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Mölnbo",
      Geometry: {
        WGS84: "POINT (17.418288054007117 59.04736118461918)",
      },
      LocationSignature: "Mö",
      OfficialLocationName: "Mölnbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mörlunda",
      Geometry: {
        WGS84: "POINT (15.875579276126036 57.32234572354373)",
      },
      LocationSignature: "Möa",
      OfficialLocationName: "Mörlunda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mörtsal",
      Geometry: {
        WGS84: "POINT (17.926030672764377 62.782454793181216)",
      },
      LocationSignature: "Möl",
      OfficialLocationName: "Mörtsal",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Mörarp",
      Geometry: {
        WGS84: "POINT (12.873598118115169 56.05535238111414)",
      },
      LocationSignature: "Mör",
      OfficialLocationName: "Mörarp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Nässjö C",
      Geometry: {
        WGS84: "POINT (14.694734601983976 57.65135943530492)",
      },
      LocationSignature: "N",
      OfficialLocationName: "Nässjö central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Näsberg",
      Geometry: {
        WGS84: "POINT (21.00941971352769 66.3647515697605)",
      },
      LocationSignature: "Nb",
      OfficialLocationName: "Näsberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nyboda",
      Geometry: {
        WGS84: "POINT (18.021133062535558 59.299937470441385)",
      },
      LocationSignature: "Nba",
      OfficialLocationName: "Nyboda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Norberg",
      Geometry: {
        WGS84: "POINT (15.934321769626965 60.06556001384838)",
      },
      LocationSignature: "Nbg",
      OfficialLocationName: "Norberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nybybruk",
      Geometry: {
        WGS84: "POINT (16.459282015508744 59.423495961324484)",
      },
      LocationSignature: "Nbr",
      OfficialLocationName: "Nybybruk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Norra bantorget",
      Geometry: {
        WGS84: "POINT (18.044917606512314 59.33660445660729)",
      },
      LocationSignature: "Nbt",
      OfficialLocationName: "Norra bantorget",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Nässundet",
      Geometry: {
        WGS84: "POINT (14.234599410872281 59.438503614815104)",
      },
      LocationSignature: "Nd",
      OfficialLocationName: "Nässundet",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Norrsundet",
      Geometry: {
        WGS84: "POINT (17.14208730712597 60.93197014682041)",
      },
      LocationSignature: "Ndt",
      OfficialLocationName: "Norrsundet",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Norsesund västra",
      Geometry: {
        WGS84: "POINT (12.433845183031636 57.85878250725469)",
      },
      LocationSignature: "Ndv",
      OfficialLocationName: "Norsesund västra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Norrhög",
      Geometry: {
        WGS84: "POINT (15.658244242663194 62.23921251921093)",
      },
      LocationSignature: "Ng",
      OfficialLocationName: "Norrhög",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Nynäsgård",
      Geometry: {
        WGS84: "POINT (17.942529489313543 58.913736221868994)",
      },
      LocationSignature: "Ngd",
      OfficialLocationName: "Nynäsgård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Norsholm",
      Geometry: {
        WGS84: "POINT (15.971970998843487 58.506928603690554)",
      },
      LocationSignature: "Nh",
      OfficialLocationName: "Norsholm",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Norrahammar",
      Geometry: {
        WGS84: "POINT (14.115808987171052 57.704350055838454)",
      },
      LocationSignature: "Nhr",
      OfficialLocationName: "Norrahammar",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Njurundabommen",
      Geometry: {
        WGS84: "POINT (17.377428460750508 62.267506874647914)",
      },
      LocationSignature: "Njb",
      OfficialLocationName: "Njurundabommen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Nyköping C",
      Geometry: {
        WGS84: "POINT (16.997084943083745 58.75661275367473)",
      },
      LocationSignature: "Nk",
      OfficialLocationName: "Nyköpings central",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Nykroppa",
      Geometry: {
        WGS84: "POINT (14.308436065558167 59.622480175289546)",
      },
      LocationSignature: "Nka",
      OfficialLocationName: "Nykroppa",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Närkes Kvarntorp",
      Geometry: {
        WGS84: "POINT (15.26379758753444 59.126788827407)",
      },
      LocationSignature: "Nkp",
      OfficialLocationName: "Närkes Kvarntorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Norra Kikkejaur",
      Geometry: {
        WGS84: "POINT (19.13595970534014 65.6379921811629)",
      },
      LocationSignature: "Nkr",
      OfficialLocationName: "Norra Kikkejaur",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nyköping södra",
      Geometry: {
        WGS84: "POINT (17.000752309621905 58.747626269926464)",
      },
      LocationSignature: "Nks",
      OfficialLocationName: "Nyköping södra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Näverkärret",
      Geometry: {
        WGS84: "POINT (15.527176207970545 59.66526139032396)",
      },
      LocationSignature: "Nkt",
      OfficialLocationName: "Näverkärret",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Nykvarn",
      Geometry: {
        WGS84: "POINT (17.432080395575703 59.1787322753928)",
      },
      LocationSignature: "Nkv",
      OfficialLocationName: "Nykvarn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nelhammar",
      Geometry: {
        WGS84: "POINT (16.324957310979343 58.0897754772045)",
      },
      LocationSignature: "Nl",
      OfficialLocationName: "Nelhammar",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Amsterdam",
      Geometry: {
        WGS84: "POINT (4.879368963100642 52.36727035907419)",
      },
      LocationSignature: "Nl.ams",
      OfficialLocationName: "Amsterdam",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nyland",
      Geometry: {
        WGS84: "POINT (17.7616597547903 63.00570188764028)",
      },
      LocationSignature: "Nld",
      OfficialLocationName: "Nyland",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nolby",
      Geometry: {
        WGS84: "POINT (17.37607048592485 62.28841899385442)",
      },
      LocationSignature: "Nly",
      OfficialLocationName: "Nolby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Niemisel",
      Geometry: {
        WGS84: "POINT (22.001481447954266 66.02814097861841)",
      },
      LocationSignature: "Nml",
      OfficialLocationName: "Niemisel",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Halden",
      Geometry: {
        WGS84: "POINT (11.391237978408311 59.12617923870987)",
      },
      LocationSignature: "No.hld",
      OfficialLocationName: "Halden",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Katterat",
      Geometry: {
        WGS84: "POINT (17.96585545435336 68.39792796062905)",
      },
      LocationSignature: "No.kat",
      OfficialLocationName: "Katterat",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Kongsvinger",
      Geometry: {
        WGS84: "POINT (12.02248806430466 60.26679920006539)",
      },
      LocationSignature: "No.kvg",
      OfficialLocationName: "Kongsvinger",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lilleström",
      Geometry: {
        WGS84: "POINT (11.050513104916602 59.95638189788172)",
      },
      LocationSignature: "No.lil",
      OfficialLocationName: "Lilleström",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lilleström",
      Geometry: {
        WGS84: "POINT (11.050513104916602 59.95638189788172)",
      },
      LocationSignature: "No.lls",
      OfficialLocationName: "Lilleström",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Narvik",
      Geometry: {
        WGS84: "POINT (17.386687780396514 68.43669216907497)",
      },
      LocationSignature: "No.nk",
      OfficialLocationName: "Narvik",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Oslo",
      Geometry: {
        WGS84: "POINT (10.736695251219352 59.91799725372251)",
      },
      LocationSignature: "No.osl",
      OfficialLocationName: "Oslo",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Rombak",
      Geometry: {
        WGS84: "POINT (17.790981037982274 68.40461174157639)",
      },
      LocationSignature: "No.rom",
      OfficialLocationName: "Rombak",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sarpsborg",
      Geometry: {
        WGS84: "POINT (11.112626070011519 59.26482811739439)",
      },
      LocationSignature: "No.sbo",
      OfficialLocationName: "Sarpsborg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Straumsnes",
      Geometry: {
        WGS84: "POINT (15.202738110952263 67.30222325344515)",
      },
      LocationSignature: "No.sms",
      OfficialLocationName: "Straumsnes",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sösterbekk",
      Geometry: {
        WGS84: "POINT (18.002447553369333 68.44614089246615)",
      },
      LocationSignature: "No.sös",
      OfficialLocationName: "Sösterbekk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Trondheim",
      Geometry: {
        WGS84: "POINT (10.922988176949668 63.45729648117318)",
      },
      LocationSignature: "No.tnd",
      OfficialLocationName: "Trondheim",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Norrmjöle",
      Geometry: {
        WGS84: "POINT (20.087808485785 63.70196459455939)",
      },
      LocationSignature: "Noe",
      OfficialLocationName: "Norrmjöle",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Nordmaling",
      Geometry: {
        WGS84: "POINT (19.48231846543507 63.5784029799022)",
      },
      LocationSignature: "Nog",
      OfficialLocationName: "Nordmaling",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Nol",
      Geometry: {
        WGS84: "POINT (12.06061767215043 57.917647392219116)",
      },
      LocationSignature: "Nol",
      OfficialLocationName: "Nol",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Norrsjön",
      Geometry: {
        WGS84: "POINT (19.60002082014037 63.58321498962454)",
      },
      LocationSignature: "Non",
      OfficialLocationName: "Norrsjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nordlunda",
      Geometry: {
        WGS84: "POINT (19.22277783764049 65.55472988503185)",
      },
      LocationSignature: "Norl",
      OfficialLocationName: "Nordlunda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nordskogen",
      Geometry: {
        WGS84: "POINT (12.95011961210867 57.75027447608747)",
      },
      LocationSignature: "Nosk",
      OfficialLocationName: "Nordskogen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Norrköping C",
      Geometry: {
        WGS84: "POINT (16.181679029700245 58.596266416740335)",
      },
      LocationSignature: "Nr",
      OfficialLocationName: "Norrköpings central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Norrfors",
      Geometry: {
        WGS84: "POINT (18.998618252161002 63.76893322166648)",
      },
      LocationSignature: "Nrs",
      OfficialLocationName: "Norrfors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nuortikon",
      Geometry: {
        WGS84: "POINT (20.820686209965483 66.8835310906646)",
      },
      LocationSignature: "Nrt",
      OfficialLocationName: "Nuortikon",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Norsesund",
      Geometry: {
        WGS84: "POINT (12.438839362739925 57.861336648362105)",
      },
      LocationSignature: "Ns",
      OfficialLocationName: "Norsesund",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nyckelsjön",
      Geometry: {
        WGS84: "POINT (16.866786287292225 59.08782741209608)",
      },
      LocationSignature: "Nsj",
      OfficialLocationName: "Nyckelsjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stockholms norra",
      Geometry: {
        WGS84: "POINT (18.033491804221473 59.34752869986927)",
      },
      LocationSignature: "Nst",
      OfficialLocationName: "Stockholms norra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nedansjö",
      Geometry: {
        WGS84: "POINT (16.852469308297152 62.37472791196406)",
      },
      LocationSignature: "Nsö",
      OfficialLocationName: "Nedansjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nacksta",
      Geometry: {
        WGS84: "POINT (17.247244542240395 62.398584150744476)",
      },
      LocationSignature: "Nta",
      OfficialLocationName: "Nacksta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nuorttagievlle",
      Geometry: {
        WGS84: "POINT (21.015701649552764 66.56131997580738)",
      },
      LocationSignature: "Nte",
      OfficialLocationName: "Nuorttagievlle",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Nattavaara",
      Geometry: {
        WGS84: "POINT (20.950092892795404 66.75427981037849)",
      },
      LocationSignature: "Ntv",
      OfficialLocationName: "Nattavaara",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Norrviken",
      Geometry: {
        WGS84: "POINT (17.924356611552174 59.459362194827804)",
      },
      LocationSignature: "Nvk",
      OfficialLocationName: "Norrviken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Norra Valbo",
      Geometry: {
        WGS84: "POINT (17.013517121749192 60.6614769856305)",
      },
      LocationSignature: "Nvl",
      OfficialLocationName: "Norra Valbo",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Notviken",
      Geometry: {
        WGS84: "POINT (22.118734555128196 65.61065686153327)",
      },
      LocationSignature: "Nvn",
      OfficialLocationName: "Notviken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nyhem",
      Geometry: {
        WGS84: "POINT (15.613306217434134 62.89189398246965)",
      },
      LocationSignature: "Ny",
      OfficialLocationName: "Nyhem",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Nybro",
      Geometry: {
        WGS84: "POINT (15.908953378445108 56.742404761426506)",
      },
      LocationSignature: "Nyb",
      OfficialLocationName: "Nybro",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Nynäshamn",
      Geometry: {
        WGS84: "POINT (17.949664205892567 58.90022886342229)",
      },
      LocationSignature: "Nyc",
      OfficialLocationName: "Nynäshamns Centrum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nyfors",
      Geometry: {
        WGS84: "POINT (20.964426286010095 65.67177684996618)",
      },
      LocationSignature: "Nyf",
      OfficialLocationName: "Nyfors",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Nynäshamn",
      Geometry: {
        WGS84: "POINT (17.949931842139648 58.90031261621835)",
      },
      LocationSignature: "Nyh",
      OfficialLocationName: "Nynäshamn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nyåker",
      Geometry: {
        WGS84: "POINT (19.313008107620465 63.77005772057072)",
      },
      LocationSignature: "Nyå",
      OfficialLocationName: "Nyåker",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nås",
      Geometry: {
        WGS84: "POINT (14.49997148456051 60.46255408414109)",
      },
      LocationSignature: "Nås",
      OfficialLocationName: "Nås",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nälden",
      Geometry: {
        WGS84: "POINT (14.263773013864942 63.35078325967095)",
      },
      LocationSignature: "Nä",
      OfficialLocationName: "Nälden",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nättraby",
      Geometry: {
        WGS84: "POINT (15.527123636495114 56.211038461732464)",
      },
      LocationSignature: "Nät",
      OfficialLocationName: "Nättraby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Nödinge",
      Geometry: {
        WGS84: "POINT (12.042947479417407 57.892440825736806)",
      },
      LocationSignature: "Nöe",
      OfficialLocationName: "Nödinge",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Osby",
      Geometry: {
        WGS84: "POINT (13.995681778357715 56.381181281226006)",
      },
      LocationSignature: "O",
      OfficialLocationName: "Osby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ockelbo",
      Geometry: {
        WGS84: "POINT (16.720896069102906 60.89116170166613)",
      },
      LocationSignature: "Ob",
      OfficialLocationName: "Ockelbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Olofström",
      Geometry: {
        WGS84: "POINT (14.519747273209372 56.279054856639775)",
      },
      LocationSignature: "Of",
      OfficialLocationName: "Olofström",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Oskarshamn",
      Geometry: {
        WGS84: "POINT (16.457553172333 57.262908730722145)",
      },
      LocationSignature: "Oh",
      OfficialLocationName: "Oskarshamn",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Oleby",
      Geometry: {
        WGS84: "POINT (13.03855609180535 60.12986766666605)",
      },
      LocationSignature: "Ol",
      OfficialLocationName: "Oleby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Oskarström",
      Geometry: {
        WGS84: "POINT (12.9719841578046 56.80650084935297)",
      },
      LocationSignature: "Om",
      OfficialLocationName: "Oskarström",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ombenning",
      Geometry: {
        WGS84: "POINT (15.926542015088128 59.98091709980214)",
      },
      LocationSignature: "Omb",
      OfficialLocationName: "Ombenning",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ope",
      Geometry: {
        WGS84: "POINT (14.746154113285547 63.133878501973356)",
      },
      LocationSignature: "Ope",
      OfficialLocationName: "Ope",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Opphem",
      Geometry: {
        WGS84: "POINT (15.726662796158282 58.1468228303289)",
      },
      LocationSignature: "Oph",
      OfficialLocationName: "Opphem",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Olskroken",
      Geometry: {
        WGS84: "POINT (11.998780183652906 57.71842741329906)",
      },
      LocationSignature: "Or",
      OfficialLocationName: "Olskroken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ornäs",
      Geometry: {
        WGS84: "POINT (15.534855737667959 60.507160326533814)",
      },
      LocationSignature: "Orn",
      OfficialLocationName: "Ornäs",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Orsa",
      Geometry: {
        WGS84: "POINT (14.621142303134159 61.11908685869698)",
      },
      LocationSignature: "Ors",
      OfficialLocationName: "Orsa",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ormaryd",
      Geometry: {
        WGS84: "POINT (14.83142566464959 57.6656372007004)",
      },
      LocationSignature: "Ory",
      OfficialLocationName: "Ormaryd",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Orrskog",
      Geometry: {
        WGS84: "POINT (17.400468969720666 60.40448196066179)",
      },
      LocationSignature: "Os",
      OfficialLocationName: "Orrskog",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Oslättfors",
      Geometry: {
        WGS84: "POINT (16.97884322214595 60.773027096679485)",
      },
      LocationSignature: "Osl",
      OfficialLocationName: "Oslättfors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ovansjö",
      Geometry: {
        WGS84: "POINT (15.593763880731869 62.51715116295648)",
      },
      LocationSignature: "Osö",
      OfficialLocationName: "Ovansjö",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ottebol",
      Geometry: {
        WGS84: "POINT (12.461594101865348 59.69797590203506)",
      },
      LocationSignature: "Ot",
      OfficialLocationName: "Ottebol",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Oxie",
      Geometry: {
        WGS84: "POINT (13.096284054069896 55.5416490616957)",
      },
      LocationSignature: "Ox",
      OfficialLocationName: "Oxie",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Oxelösund",
      Geometry: {
        WGS84: "POINT (17.099949191978347 58.67051223945652)",
      },
      LocationSignature: "Oxd",
      OfficialLocationName: "Oxelösund",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Oxmyran",
      Geometry: {
        WGS84: "POINT (19.236913571628566 63.77342144627517)",
      },
      LocationSignature: "Oxm",
      OfficialLocationName: "Oxmyran",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Partille",
      Geometry: {
        WGS84: "POINT (12.102643921452882 57.74071029713566)",
      },
      LocationSignature: "P",
      OfficialLocationName: "Partille",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Prässebo",
      Geometry: {
        WGS84: "POINT (12.291472671036578 58.110759870948904)",
      },
      LocationSignature: "Pb",
      OfficialLocationName: "Prässebo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Persberg",
      Geometry: {
        WGS84: "POINT (14.23820721305298 59.749679913743385)",
      },
      LocationSignature: "Pbg",
      OfficialLocationName: "Persberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Polcirkeln",
      Geometry: {
        WGS84: "POINT (21.02118150459631 66.56730434607853)",
      },
      LocationSignature: "Pc",
      OfficialLocationName: "Polcirkeln",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Peuravaara",
      Geometry: {
        WGS84: "POINT (20.157396090025767 67.87292488439674)",
      },
      LocationSignature: "Pea",
      OfficialLocationName: "Peuravaara",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Peberholm",
      Geometry: {
        WGS84: "POINT (12.748686749416848 55.600566583524916)",
      },
      LocationSignature: "Phm",
      OfficialLocationName: "Peberholm",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Pitkäjärvi",
      Geometry: {
        WGS84: "POINT (20.93988833313914 67.66419954112915)",
      },
      LocationSignature: "Pii",
      OfficialLocationName: "Pitkäjärvi",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Porjus",
      Geometry: {
        WGS84: "POINT (19.829651910179116 66.96614637805786)",
      },
      LocationSignature: "Pj",
      OfficialLocationName: "Porjus",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Pilgrimstad",
      Geometry: {
        WGS84: "POINT (15.040389875520408 62.95497212761036)",
      },
      LocationSignature: "Pl",
      OfficialLocationName: "Pilgrimstad",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Pauliström",
      Geometry: {
        WGS84: "POINT (15.507521221099788 57.46994816943831)",
      },
      LocationSignature: "Plm",
      OfficialLocationName: "Pauliström",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Prästmon",
      Geometry: {
        WGS84: "POINT (17.755313133065048 63.077685513224)",
      },
      LocationSignature: "Pm",
      OfficialLocationName: "Prästmon",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Perstorp",
      Geometry: {
        WGS84: "POINT (13.398581628274538 56.13640272456923)",
      },
      LocationSignature: "Pt",
      OfficialLocationName: "Perstorp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Lissabon",
      Geometry: {
        WGS84: "POINT (-9.157293845631589 38.69603691647582)",
      },
      LocationSignature: "Pt.lis",
      OfficialLocationName: "Lissabon",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Piteå",
      Geometry: {
        WGS84: "POINT (21.468066583803726 65.31793028610595)",
      },
      LocationSignature: "Ptå",
      OfficialLocationName: "Piteå",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Pålsboda",
      Geometry: {
        WGS84: "POINT (15.340060316891568 59.063873128113606)",
      },
      LocationSignature: "På",
      OfficialLocationName: "Pålsboda",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Påarp",
      Geometry: {
        WGS84: "POINT (12.81718764853875 56.02926556671496)",
      },
      LocationSignature: "Påa",
      OfficialLocationName: "Påarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Piteälvsbron",
      Geometry: {
        WGS84: "POINT (19.3595803083385 65.98742483537643)",
      },
      LocationSignature: "Päb",
      OfficialLocationName: "Piteälvsbron",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Pölsebo",
      Geometry: {
        WGS84: "POINT (11.899147150690903 57.699540667493736)",
      },
      LocationSignature: "Pöb",
      OfficialLocationName: "Pölsebo",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Rotebro",
      Geometry: {
        WGS84: "POINT (17.915298260190166 59.475514234039295)",
      },
      LocationSignature: "R",
      OfficialLocationName: "Rotebro",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ramlösa",
      Geometry: {
        WGS84: "POINT (12.72732636269773 56.0239753050688)",
      },
      LocationSignature: "Ram",
      OfficialLocationName: "Ramlösa",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rian",
      Geometry: {
        WGS84: "POINT (23.008754683045197 65.89127340701083)",
      },
      LocationSignature: "Ran",
      OfficialLocationName: "Rian",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ralingsås",
      Geometry: {
        WGS84: "POINT (14.80369789817114 57.877043000570715)",
      },
      LocationSignature: "Ras",
      OfficialLocationName: "Ralingsås",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ronneby",
      Geometry: {
        WGS84: "POINT (15.283598683023076 56.206445652391736)",
      },
      LocationSignature: "Rb",
      OfficialLocationName: "Ronneby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Rabbalshede",
      Geometry: {
        WGS84: "POINT (11.470711607633103 58.61010066120599)",
      },
      LocationSignature: "Rbh",
      OfficialLocationName: "Rabbalshede",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Råbäck",
      Geometry: {
        WGS84: "POINT (13.376620125855242 58.610608209345884)",
      },
      LocationSignature: "Rbk",
      OfficialLocationName: "Råbäck",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Röstbo",
      Geometry: {
        WGS84: "POINT (16.67323596216115 61.17507848939752)",
      },
      LocationSignature: "Rbo",
      OfficialLocationName: "Röstbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rockneby",
      Geometry: {
        WGS84: "POINT (16.341089279793206 56.80727475673242)",
      },
      LocationSignature: "Rby",
      OfficialLocationName: "Rockneby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Repbäcken",
      Geometry: {
        WGS84: "POINT (15.343418221806562 60.514173927266)",
      },
      LocationSignature: "Rbä",
      OfficialLocationName: "Repbäcken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Rävlanda",
      Geometry: {
        WGS84: "POINT (12.500280228730583 57.65442643450085)",
      },
      LocationSignature: "Rd",
      OfficialLocationName: "Rävlanda",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Rydsgård",
      Geometry: {
        WGS84: "POINT (13.588473166758456 55.47423125962734)",
      },
      LocationSignature: "Rdg",
      OfficialLocationName: "Rydsgård",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Rimforsa",
      Geometry: {
        WGS84: "POINT (15.681679282269746 58.13530453556956)",
      },
      LocationSignature: "Rf",
      OfficialLocationName: "Rimforsa",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rimforsa brygga",
      Geometry: {
        WGS84: "POINT (15.693200073949845 58.140892213022596)",
      },
      LocationSignature: "Rfb",
      OfficialLocationName: "Rimforsa brygga",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Reftele",
      Geometry: {
        WGS84: "POINT (13.593496728976955 57.175920515622714)",
      },
      LocationSignature: "Rft",
      OfficialLocationName: "Reftele",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Riksgränsen",
      Geometry: {
        WGS84: "POINT (18.11869690003302 68.42719824233153)",
      },
      LocationSignature: "Rgn",
      OfficialLocationName: "Riksgränsen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Riksgräns Swe-Dk.",
      Geometry: {
        WGS84: "POINT (12.808827738668187 55.57937527748038)",
      },
      LocationSignature: "Riks",
      OfficialLocationName: "Riksgräns Swe-Dk.",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Röjan",
      Geometry: {
        WGS84: "POINT (14.359231439773422 62.47920736813055)",
      },
      LocationSignature: "Rjn",
      OfficialLocationName: "Röjan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vemdalen",
      Geometry: {
        WGS84: "POINT (13.863238489291808 62.44808632637305)",
      },
      LocationSignature: "Rjn.vd",
      OfficialLocationName: "Vemdalen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rörvik",
      Geometry: {
        WGS84: "POINT (14.58287021210532 57.23018863815012)",
      },
      LocationSignature: "Rk",
      OfficialLocationName: "Rörvik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rekarne",
      Geometry: {
        WGS84: "POINT (16.34082857433631 59.42764005935918)",
      },
      LocationSignature: "Rke",
      OfficialLocationName: "Rekarne",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Rocksjön",
      Geometry: {
        WGS84: "POINT (14.198182496319422 57.7750362469798)",
      },
      LocationSignature: "Rkö",
      OfficialLocationName: "Rocksjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rällså",
      Geometry: {
        WGS84: "POINT (15.097524794485626 59.796973795235765)",
      },
      LocationSignature: "Rlå",
      OfficialLocationName: "Rällså",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rämshyttan",
      Geometry: {
        WGS84: "POINT (15.2223983004871 60.29830305770648)",
      },
      LocationSignature: "Rmh",
      OfficialLocationName: "Rämshyttan",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ramnäs",
      Geometry: {
        WGS84: "POINT (16.207883205469344 59.76977622087099)",
      },
      LocationSignature: "Rmn",
      OfficialLocationName: "Ramnäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Regumatorp",
      Geometry: {
        WGS84: "POINT (13.790299419949568 58.31703312552783)",
      },
      LocationSignature: "Rmtp",
      OfficialLocationName: "Regumatorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Roback",
      Geometry: {
        WGS84: "POINT (16.161748314141715 63.020441134030555)",
      },
      LocationSignature: "Rob",
      OfficialLocationName: "Roback",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Rosengård",
      Geometry: {
        WGS84: "POINT (13.030834150865605 55.58896718684582)",
      },
      LocationSignature: "Rog",
      OfficialLocationName: "Rosengård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rossön",
      Geometry: {
        WGS84: "POINT (16.348826342027646 63.92120198566088)",
      },
      LocationSignature: "Ros",
      OfficialLocationName: "Rossön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ripats",
      Geometry: {
        WGS84: "POINT (20.731579093517908 66.97193679743431)",
      },
      LocationSignature: "Rps",
      OfficialLocationName: "Ripats",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Rosersberg",
      Geometry: {
        WGS84: "POINT (17.879821813887027 59.58435991542243)",
      },
      LocationSignature: "Rs",
      OfficialLocationName: "Rosersberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Råtsi",
      Geometry: {
        WGS84: "POINT (20.240482825138074 67.79096264999866)",
      },
      LocationSignature: "Rsi",
      OfficialLocationName: "Råtsi",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rensjön",
      Geometry: {
        WGS84: "POINT (19.826895032446586 68.07068436662173)",
      },
      LocationSignature: "Rsn",
      OfficialLocationName: "Rensjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ramsjö",
      Geometry: {
        WGS84: "POINT (15.67632995695312 62.159960296252116)",
      },
      LocationSignature: "Rsö",
      OfficialLocationName: "Ramsjö",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ransta",
      Geometry: {
        WGS84: "POINT (16.63995226743637 59.81019146076976)",
      },
      LocationSignature: "Rt",
      OfficialLocationName: "Ransta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Röste",
      Geometry: {
        WGS84: "POINT (16.391811365414654 61.38016454747677)",
      },
      LocationSignature: "Rte",
      OfficialLocationName: "Röste",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Rottneros",
      Geometry: {
        WGS84: "POINT (13.128730817596281 59.79496980518692)",
      },
      LocationSignature: "Rts",
      OfficialLocationName: "Rottneros",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ragunda",
      Geometry: {
        WGS84: "POINT (16.412273453108398 63.05842349183876)",
      },
      LocationSignature: "Ru",
      OfficialLocationName: "Ragunda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ruda",
      Geometry: {
        WGS84: "POINT (16.11636423070368 57.11043237983634)",
      },
      LocationSignature: "Rud",
      OfficialLocationName: "Ruda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rundvik",
      Geometry: {
        WGS84: "POINT (19.414275614981324 63.54614365956771)",
      },
      LocationSignature: "Ruk",
      OfficialLocationName: "Rundvik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Runemo",
      Geometry: {
        WGS84: "POINT (16.133190521462033 61.357191848903035)",
      },
      LocationSignature: "Rum",
      OfficialLocationName: "Runemo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Runsala",
      Geometry: {
        WGS84: "POINT (15.15314102084493 58.86898253815527)",
      },
      LocationSignature: "Rus",
      OfficialLocationName: "Runsala",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rautas",
      Geometry: {
        WGS84: "POINT (19.89862371829088 67.99427716556012)",
      },
      LocationSignature: "Rut",
      OfficialLocationName: "Rautas",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Rättvik",
      Geometry: {
        WGS84: "POINT (15.11569266851374 60.88469674996119)",
      },
      LocationSignature: "Rv",
      OfficialLocationName: "Rättvik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rosshyttan",
      Geometry: {
        WGS84: "POINT (16.35405229166647 60.068583111688774)",
      },
      LocationSignature: "Ry",
      OfficialLocationName: "Rosshyttan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ryssjöbrink",
      Geometry: {
        WGS84: "POINT (17.335702926296598 59.20947392236341)",
      },
      LocationSignature: "Ryb",
      OfficialLocationName: "Ryssjöbrink",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rynge",
      Geometry: {
        WGS84: "POINT (13.670590452612146 55.4689408605178)",
      },
      LocationSignature: "Rye",
      OfficialLocationName: "Rynge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ryggen",
      Geometry: {
        WGS84: "POINT (15.946192339279943 60.591033177918625)",
      },
      LocationSignature: "Ryg",
      OfficialLocationName: "Ryggen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rydöbruk",
      Geometry: {
        WGS84: "POINT (13.146860869619399 56.963664407781444)",
      },
      LocationSignature: "Ryk",
      OfficialLocationName: "Rydöbruk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Rydaholm",
      Geometry: {
        WGS84: "POINT (14.31146313164322 56.986401280809616)",
      },
      LocationSignature: "Rym",
      OfficialLocationName: "Rydaholm",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ryr",
      Geometry: {
        WGS84: "POINT (12.13874686106434 58.3317749707901)",
      },
      LocationSignature: "Ryr",
      OfficialLocationName: "Ryr",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Rydebäck",
      Geometry: {
        WGS84: "POINT (12.783556727555347 55.96577142443263)",
      },
      LocationSignature: "Ryä",
      OfficialLocationName: "Rydebäck",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rågsveden",
      Geometry: {
        WGS84: "POINT (14.065207108436699 60.479529466895464)",
      },
      LocationSignature: "Råg",
      OfficialLocationName: "Rågsveden",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Råsjön",
      Geometry: {
        WGS84: "POINT (12.41186900575653 58.830883644439105)",
      },
      LocationSignature: "Rån",
      OfficialLocationName: "Råsjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Råskogen",
      Geometry: {
        WGS84: "POINT (12.380774693383422 58.64207622233329)",
      },
      LocationSignature: "Rås",
      OfficialLocationName: "Råskogen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rågön",
      Geometry: {
        WGS84: "POINT (19.171657671328106 63.32325208270805)",
      },
      LocationSignature: "Råö",
      OfficialLocationName: "Rågön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Räppe",
      Geometry: {
        WGS84: "POINT (14.73687636776238 56.883718818515376)",
      },
      LocationSignature: "Räp",
      OfficialLocationName: "Räppe",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rödberg",
      Geometry: {
        WGS84: "POINT (12.616641374379133 57.677817072540776)",
      },
      LocationSignature: "Rö",
      OfficialLocationName: "Rödberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Rönninge",
      Geometry: {
        WGS84: "POINT (17.75222820335924 59.19351266247038)",
      },
      LocationSignature: "Rön",
      OfficialLocationName: "Rönninge",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Rörstorp",
      Geometry: {
        WGS84: "POINT (14.064114622877709 57.17230722990742)",
      },
      LocationSignature: "Röp",
      OfficialLocationName: "Rörstorp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sveg",
      Geometry: {
        WGS84: "POINT (14.352013954814712 62.034465796261614)",
      },
      LocationSignature: "S",
      OfficialLocationName: "Sveg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sandared",
      Geometry: {
        WGS84: "POINT (12.79249209172293 57.709536517615255)",
      },
      LocationSignature: "Sae",
      OfficialLocationName: "Sandared",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Staffanstorp",
      Geometry: {
        WGS84: "POINT (13.215492382876871 55.64300278617098)",
      },
      LocationSignature: "Saf",
      OfficialLocationName: "Staffanstorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sandbäck",
      Geometry: {
        WGS84: "POINT (14.634516797526306 56.1635083574034)",
      },
      LocationSignature: "Sak",
      OfficialLocationName: "Sandbäck",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Svalöv",
      Geometry: {
        WGS84: "POINT (13.110517062821998 55.913514219560604)",
      },
      LocationSignature: "Sal",
      OfficialLocationName: "Svalöv",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Samnan",
      Geometry: {
        WGS84: "POINT (17.655060740975056 59.911514935381284)",
      },
      LocationSignature: "Sam",
      OfficialLocationName: "Samnan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sannarp",
      Geometry: {
        WGS84: "POINT (12.893372380138683 56.686435547151994)",
      },
      LocationSignature: "Sarp",
      OfficialLocationName: "Sannarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Salsta",
      Geometry: {
        WGS84: "POINT (17.728363874288274 60.04658899728175)",
      },
      LocationSignature: "Sas",
      OfficialLocationName: "Salsta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stora Tuna",
      Geometry: {
        WGS84: "POINT (15.496910794427853 60.44177079213061)",
      },
      LocationSignature: "Sau",
      OfficialLocationName: "Stora Tuna",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skabersjö",
      Geometry: {
        WGS84: "POINT (13.154086208249646 55.53392382989854)",
      },
      LocationSignature: "Saö",
      OfficialLocationName: "Skabersjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Spannarboda",
      Geometry: {
        WGS84: "POINT (15.507496877379213 59.57628476058226)",
      },
      LocationSignature: "Sba",
      OfficialLocationName: "Spannarboda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Saluböle",
      Geometry: {
        WGS84: "POINT (19.28722224313167 63.47740222626343)",
      },
      LocationSignature: "Sbe",
      OfficialLocationName: "Saluböle",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Storblåliden",
      Geometry: {
        WGS84: "POINT (20.42892931159203 65.4468376000125)",
      },
      LocationSignature: "Sbi",
      OfficialLocationName: "Storblåliden",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stenbacken",
      Geometry: {
        WGS84: "POINT (19.502393049398613 68.24975188559038)",
      },
      LocationSignature: "Sbk",
      OfficialLocationName: "Stenbacken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skärblacka",
      Geometry: {
        WGS84: "POINT (15.910323851139843 58.57514825981841)",
      },
      LocationSignature: "Sbl",
      OfficialLocationName: "Skärblacka",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Strömsnäsbruk",
      Geometry: {
        WGS84: "POINT (13.732615703503818 56.55013570323237)",
      },
      LocationSignature: "Sbu",
      OfficialLocationName: "Strömsnäsbruk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Norra Sunderbyn",
      Geometry: {
        WGS84: "POINT (21.86501365779015 65.6978339826181)",
      },
      LocationSignature: "Sby",
      OfficialLocationName: "Norra Sunderbyn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sågbäcken",
      Geometry: {
        WGS84: "POINT (22.595502910354863 66.06213297359645)",
      },
      LocationSignature: "Sbä",
      OfficialLocationName: "Sågbäcken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Stockholm City",
      Geometry: {
        WGS84: "POINT (18.06485202220192 59.332371652020754)",
      },
      LocationSignature: "Sci",
      OfficialLocationName: "Stockholm City",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sköldinge",
      Geometry: {
        WGS84: "POINT (16.45415369808121 59.03024423038165)",
      },
      LocationSignature: "Sde",
      OfficialLocationName: "Sköldinge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sandmon",
      Geometry: {
        WGS84: "POINT (13.634339689225913 59.60282084329451)",
      },
      LocationSignature: "Sdm",
      OfficialLocationName: "Sandmon",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sandsjönäs",
      Geometry: {
        WGS84: "POINT (17.663578350756822 65.24325085379625)",
      },
      LocationSignature: "Sdnä",
      OfficialLocationName: "Sandsjönäs",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Smålandsstenar",
      Geometry: {
        WGS84: "POINT (13.414984120812575 57.16143907046352)",
      },
      LocationSignature: "Sdr",
      OfficialLocationName: "Smålandsstenar",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Smedby",
      Geometry: {
        WGS84: "POINT (16.233273816065285 56.67759311683166)",
      },
      LocationSignature: "Sdy",
      OfficialLocationName: "Smedby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Svedala",
      Geometry: {
        WGS84: "POINT (13.23204454303852 55.506880996857916)",
      },
      LocationSignature: "Sea",
      OfficialLocationName: "Svedala",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Skene",
      Geometry: {
        WGS84: "POINT (12.649438952203928 57.488433688435435)",
      },
      LocationSignature: "See",
      OfficialLocationName: "Skene",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sege",
      Geometry: {
        WGS84: "POINT (13.084511307194273 55.617148224182365)",
      },
      LocationSignature: "Sege",
      OfficialLocationName: "Sege",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sävedalen",
      Geometry: {
        WGS84: "POINT (12.066539308173493 57.73289548417455)",
      },
      LocationSignature: "Sel",
      OfficialLocationName: "Sävedalen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stavreviken",
      Geometry: {
        WGS84: "POINT (17.40657081270282 62.55351181166798)",
      },
      LocationSignature: "Sen",
      OfficialLocationName: "Stavreviken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stensundberget",
      Geometry: {
        WGS84: "POINT (23.382241834279473 65.84906813211998)",
      },
      LocationSignature: "Set",
      OfficialLocationName: "Stensundberget",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Storfors",
      Geometry: {
        WGS84: "POINT (14.271725919543863 59.533027211130175)",
      },
      LocationSignature: "Sf",
      OfficialLocationName: "Storfors",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Säffle",
      Geometry: {
        WGS84: "POINT (12.916233752704398 59.13235023011816)",
      },
      LocationSignature: "Sfl",
      OfficialLocationName: "Säffle",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Storflon",
      Geometry: {
        WGS84: "POINT (14.38646015440119 63.351273637214234)",
      },
      LocationSignature: "Sfn",
      OfficialLocationName: "Storflon",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Stehag",
      Geometry: {
        WGS84: "POINT (13.393432999610381 55.900294268863576)",
      },
      LocationSignature: "Sg",
      OfficialLocationName: "Stehag",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Segmon",
      Geometry: {
        WGS84: "POINT (13.038469712460678 59.27472871736501)",
      },
      LocationSignature: "Sgm",
      OfficialLocationName: "Segmon",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Strängnäs",
      Geometry: {
        WGS84: "POINT (17.028206598634636 59.3702999656302)",
      },
      LocationSignature: "Sgs",
      OfficialLocationName: "Strängnäs",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Slagnäs",
      Geometry: {
        WGS84: "POINT (18.15790291390821 65.58590556818578)",
      },
      LocationSignature: "Sgä",
      OfficialLocationName: "Slagnäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stjärnhov",
      Geometry: {
        WGS84: "POINT (17.00528336867815 59.08291261404693)",
      },
      LocationSignature: "Sh",
      OfficialLocationName: "Stjärnhov",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Söderhamn",
      Geometry: {
        WGS84: "POINT (17.104148640998115 61.301704582426694)",
      },
      LocationSignature: "Shm",
      OfficialLocationName: "Söderhamn",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Surahammar",
      Geometry: {
        WGS84: "POINT (16.21962848329636 59.70657891121106)",
      },
      LocationSignature: "Shr",
      OfficialLocationName: "Surahammar",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Söderhamn",
      Geometry: {
        WGS84: "POINT (17.03489122366434 61.29941060831739)",
      },
      LocationSignature: "Shv",
      OfficialLocationName: "Söderhamns västra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Simrishamn",
      Geometry: {
        WGS84: "POINT (14.351074101494959 55.55363173275493)",
      },
      LocationSignature: "Si",
      OfficialLocationName: "Simrishamn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Silverdalen",
      Geometry: {
        WGS84: "POINT (15.742764090382453 57.542676980528654)",
      },
      LocationSignature: "Sic",
      OfficialLocationName: "Silverdalens centrum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Silverdalens bruk",
      Geometry: {
        WGS84: "POINT (15.75915150207357 57.53619257745389)",
      },
      LocationSignature: "Sid",
      OfficialLocationName: "Silverdalens bruk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stävie",
      Geometry: {
        WGS84: "POINT (13.074162600923152 55.74745417965337)",
      },
      LocationSignature: "Sie",
      OfficialLocationName: "Stävie",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sikfors",
      Geometry: {
        WGS84: "POINT (14.583740202151063 59.80701024371624)",
      },
      LocationSignature: "Sif",
      OfficialLocationName: "Sikfors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Silinge",
      Geometry: {
        WGS84: "POINT (16.566558595964707 59.01231713473943)",
      },
      LocationSignature: "Sii",
      OfficialLocationName: "Silinge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Svartvik",
      Geometry: {
        WGS84: "POINT (17.36733128374122 62.3227211980573)",
      },
      LocationSignature: "Sik",
      OfficialLocationName: "Svartvik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sorsele industrispår",
      Geometry: {
        WGS84: "POINT (17.524532150432055 65.56284205320398)",
      },
      LocationSignature: "Sir",
      OfficialLocationName: "Sorsele industrispår",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sjisjka",
      Geometry: {
        WGS84: "POINT (20.234529083666388 67.6258895982425)",
      },
      LocationSignature: "Sjka",
      OfficialLocationName: "Sjisjka",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Skövde C",
      Geometry: {
        WGS84: "POINT (13.85185993588994 58.38903712692206)",
      },
      LocationSignature: "Sk",
      OfficialLocationName: "Skövde central",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Skinnskatteberg",
      Geometry: {
        WGS84: "POINT (15.6915987074977 59.83261999016361)",
      },
      LocationSignature: "Skb",
      OfficialLocationName: "Skinnskatteberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skälebol",
      Geometry: {
        WGS84: "POINT (12.393122405138747 58.60707725316669)",
      },
      LocationSignature: "Skbl",
      OfficialLocationName: "Skälebol",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skavstaby",
      Geometry: {
        WGS84: "POINT (17.895877592113234 59.550615770046676)",
      },
      LocationSignature: "Skby",
      OfficialLocationName: "Skavstaby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Skee",
      Geometry: {
        WGS84: "POINT (11.262054500281476 58.92340879359357)",
      },
      LocationSignature: "Ske",
      OfficialLocationName: "Skee",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Skogås",
      Geometry: {
        WGS84: "POINT (18.15357186741919 59.219356858542994)",
      },
      LocationSignature: "Skg",
      OfficialLocationName: "Skogås",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skönvik",
      Geometry: {
        WGS84: "POINT (17.32809106554911 62.468900592281145)",
      },
      LocationSignature: "Skk",
      OfficialLocationName: "Skönvik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skellefteå",
      Geometry: {
        WGS84: "POINT (20.955692728423887 64.7534893095815)",
      },
      LocationSignature: "Skl",
      OfficialLocationName: "Skellefteå",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stenkumla",
      Geometry: {
        WGS84: "POINT (15.043540496106248 58.96140257422909)",
      },
      LocationSignature: "Skm",
      OfficialLocationName: "Stenkumla",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skymossen",
      Geometry: {
        WGS84: "POINT (15.088890430516072 59.0199782095557)",
      },
      LocationSignature: "Skms",
      OfficialLocationName: "Skymossen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Skänninge",
      Geometry: {
        WGS84: "POINT (15.092523585317286 58.39263945340202)",
      },
      LocationSignature: "Skn",
      OfficialLocationName: "Skänninge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skoghall",
      Geometry: {
        WGS84: "POINT (13.452965645817976 59.32985747319946)",
      },
      LocationSignature: "Skog",
      OfficialLocationName: "Skoghall",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skåre",
      Geometry: {
        WGS84: "POINT (13.448354239269 59.4294007372681)",
      },
      LocationSignature: "Skr",
      OfficialLocationName: "Skåre",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skogstorp",
      Geometry: {
        WGS84: "POINT (16.4733846209059 59.331842555940774)",
      },
      LocationSignature: "Skrp",
      OfficialLocationName: "Skogstorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skäret",
      Geometry: {
        WGS84: "POINT (14.929960916776981 59.894921475782404)",
      },
      LocationSignature: "Skrt",
      OfficialLocationName: "Skäret",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Skållerud",
      Geometry: {
        WGS84: "POINT (12.418609413146058 58.78080067349001)",
      },
      LocationSignature: "Skru",
      OfficialLocationName: "Skållerud",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skebokvarn",
      Geometry: {
        WGS84: "POINT (16.717755282048962 59.06534638848358)",
      },
      LocationSignature: "Skv",
      OfficialLocationName: "Skebokvarn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skästra",
      Geometry: {
        WGS84: "POINT (16.164100840576378 61.74906176904908)",
      },
      LocationSignature: "Skä",
      OfficialLocationName: "Skästra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sala",
      Geometry: {
        WGS84: "POINT (16.60451664044467 59.925695407493045)",
      },
      LocationSignature: "Sl",
      OfficialLocationName: "Sala",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Solbacka",
      Geometry: {
        WGS84: "POINT (17.360123886905132 62.504561984557014)",
      },
      LocationSignature: "Slbk",
      OfficialLocationName: "Solbacka",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Slätte",
      Geometry: {
        WGS84: "POINT (14.200876979556378 58.76440956698508)",
      },
      LocationSignature: "Sle",
      OfficialLocationName: "Slätte",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sällinge",
      Geometry: {
        WGS84: "POINT (15.480402696805758 59.51974983820795)",
      },
      LocationSignature: "Slg",
      OfficialLocationName: "Sällinge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Selsjön",
      Geometry: {
        WGS84: "POINT (17.21230047065301 63.30695899653184)",
      },
      LocationSignature: "Slj",
      OfficialLocationName: "Selsjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Solum",
      Geometry: {
        WGS84: "POINT (17.78278119477315 63.11532843508525)",
      },
      LocationSignature: "Slm",
      OfficialLocationName: "Solum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sellnäs",
      Geometry: {
        WGS84: "POINT (15.380681426914334 60.41336741671901)",
      },
      LocationSignature: "Slnä",
      OfficialLocationName: "Sellnäs",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sandhem",
      Geometry: {
        WGS84: "POINT (13.772749414804583 57.988321871987225)",
      },
      LocationSignature: "Sm",
      OfficialLocationName: "Sandhem",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Strömstad",
      Geometry: {
        WGS84: "POINT (11.1733627688164 58.93653560517534)",
      },
      LocationSignature: "Smd",
      OfficialLocationName: "Strömstad",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Smedjebacken",
      Geometry: {
        WGS84: "POINT (15.40561681482803 60.13650713866027)",
      },
      LocationSignature: "Smj",
      OfficialLocationName: "Smedjebacken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sommen",
      Geometry: {
        WGS84: "POINT (14.978273741361638 58.145764815245464)",
      },
      LocationSignature: "Smn",
      OfficialLocationName: "Sommen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Strömsbro",
      Geometry: {
        WGS84: "POINT (17.154727803682743 60.69925577176364)",
      },
      LocationSignature: "Smo",
      OfficialLocationName: "Strömsbro",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Smedstorp",
      Geometry: {
        WGS84: "POINT (14.11755176953013 55.549539915968204)",
      },
      LocationSignature: "Smp",
      OfficialLocationName: "Smedstorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Simonstorp",
      Geometry: {
        WGS84: "POINT (16.15699657244143 58.78392561801097)",
      },
      LocationSignature: "Smt",
      OfficialLocationName: "Simonstorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Smålands Burseryd",
      Geometry: {
        WGS84: "POINT (13.287002621905463 57.203118073895794)",
      },
      LocationSignature: "Smu",
      OfficialLocationName: "Smålands Burseryd",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Simeå",
      Geometry: {
        WGS84: "POINT (16.32665245950934 61.571915713983934)",
      },
      LocationSignature: "Små",
      OfficialLocationName: "Simeå",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Stenkullen",
      Geometry: {
        WGS84: "POINT (12.314619869123824 57.79173715329077)",
      },
      LocationSignature: "Sn",
      OfficialLocationName: "Stenkullen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Snickarbo",
      Geometry: {
        WGS84: "POINT (16.056387976160508 60.191122630579585)",
      },
      LocationSignature: "Snb",
      OfficialLocationName: "Snickarbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sandarne",
      Geometry: {
        WGS84: "POINT (17.14838141831547 61.26506112325472)",
      },
      LocationSignature: "Sne",
      OfficialLocationName: "Sandarne",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Singsån",
      Geometry: {
        WGS84: "POINT (16.376906901147716 63.028879140607046)",
      },
      LocationSignature: "Sngå",
      OfficialLocationName: "Singsån",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skanlog",
      Geometry: {
        WGS84: "POINT (16.445314694769007 59.405526758583285)",
      },
      LocationSignature: "Snl",
      OfficialLocationName: "Skanlog",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Snyten",
      Geometry: {
        WGS84: "POINT (16.023763005988446 59.991648546326765)",
      },
      LocationSignature: "Snt",
      OfficialLocationName: "Snyten",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Stenungsund",
      Geometry: {
        WGS84: "POINT (11.824676822456842 58.07270857331496)",
      },
      LocationSignature: "Snu",
      OfficialLocationName: "Stenungsund",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sandviken",
      Geometry: {
        WGS84: "POINT (16.77758839061764 60.614229442263586)",
      },
      LocationSignature: "Snv",
      OfficialLocationName: "Sandviken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Stensjön",
      Geometry: {
        WGS84: "POINT (14.823732604032834 57.58252860021629)",
      },
      LocationSignature: "Snö",
      OfficialLocationName: "Stensjön",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Solna",
      Geometry: {
        WGS84: "POINT (18.010330020813157 59.365525934131234)",
      },
      LocationSignature: "So",
      OfficialLocationName: "Solna",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stordalen",
      Geometry: {
        WGS84: "POINT (19.070536571088198 68.34357611493724)",
      },
      LocationSignature: "Soa",
      OfficialLocationName: "Stordalen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Stockholm Odenplan",
      Geometry: {
        WGS84: "POINT (18.04514491266581 59.34444415196133)",
      },
      LocationSignature: "Sod",
      OfficialLocationName: "Stockholm Odenplan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stolpen",
      Geometry: {
        WGS84: "POINT (14.55278120205907 57.591498645494426)",
      },
      LocationSignature: "Soe",
      OfficialLocationName: "Stolpen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sollentuna",
      Geometry: {
        WGS84: "POINT (17.949147258050402 59.42783962168611)",
      },
      LocationSignature: "Sol",
      OfficialLocationName: "Sollentuna",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stormyran",
      Geometry: {
        WGS84: "POINT (17.447253325130042 63.36856162640737)",
      },
      LocationSignature: "Som",
      OfficialLocationName: "Stormyran",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skorped",
      Geometry: {
        WGS84: "POINT (17.859506824237766 63.385405632136184)",
      },
      LocationSignature: "Sop",
      OfficialLocationName: "Skorped",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Storsjö",
      Geometry: {
        WGS84: "POINT (16.365446134528018 58.05823966722256)",
      },
      LocationSignature: "Soö",
      OfficialLocationName: "Storsjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sparreholm",
      Geometry: {
        WGS84: "POINT (16.828620214429925 59.07582222311673)",
      },
      LocationSignature: "Sp",
      OfficialLocationName: "Sparreholm",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skeppshult",
      Geometry: {
        WGS84: "POINT (13.356921656768593 57.125013517332015)",
      },
      LocationSignature: "Sph",
      OfficialLocationName: "Skeppshult",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Spjutsbygd",
      Geometry: {
        WGS84: "POINT (15.59426050523745 56.310267193630715)",
      },
      LocationSignature: "Spj",
      OfficialLocationName: "Spjutsbygd",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Spjutbäcken",
      Geometry: {
        WGS84: "POINT (14.138812446400333 59.32866721401694)",
      },
      LocationSignature: "Spjb",
      OfficialLocationName: "Spjutbäcken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sprängsviken",
      Geometry: {
        WGS84: "POINT (17.870747931644434 62.86039162184185)",
      },
      LocationSignature: "Spk",
      OfficialLocationName: "Sprängsviken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stolpstugan",
      Geometry: {
        WGS84: "POINT (16.34350394721599 59.012100756745205)",
      },
      LocationSignature: "Spn",
      OfficialLocationName: "Stolpstugan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Spännarhyttan",
      Geometry: {
        WGS84: "POINT (15.941699476552369 60.08930406801351)",
      },
      LocationSignature: "Spy",
      OfficialLocationName: "Spännarhyttan",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Spånga",
      Geometry: {
        WGS84: "POINT (17.897261239158393 59.384100766747025)",
      },
      LocationSignature: "Spå",
      OfficialLocationName: "Spånga",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Storå",
      Geometry: {
        WGS84: "POINT (15.140746230592448 59.718883901339424)",
      },
      LocationSignature: "Sr",
      OfficialLocationName: "Storå",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Söderbärke",
      Geometry: {
        WGS84: "POINT (15.537110664376323 60.072492580553146)",
      },
      LocationSignature: "Sre",
      OfficialLocationName: "Söderbärke",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sörtjärn",
      Geometry: {
        WGS84: "POINT (14.629719298049542 62.342743935781115)",
      },
      LocationSignature: "Srn",
      OfficialLocationName: "Sörtjärn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Storebro",
      Geometry: {
        WGS84: "POINT (15.852201866816793 57.58401773914322)",
      },
      LocationSignature: "Sro",
      OfficialLocationName: "Storebro",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Skurup",
      Geometry: {
        WGS84: "POINT (13.498125673867094 55.48021075529136)",
      },
      LocationSignature: "Srp",
      OfficialLocationName: "Skurup",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skattkärr",
      Geometry: {
        WGS84: "POINT (13.67181726411824 59.40855844874174)",
      },
      LocationSignature: "Srr",
      OfficialLocationName: "Skattkärr",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sorsele",
      Geometry: {
        WGS84: "POINT (17.542801304424216 65.5350377770453)",
      },
      LocationSignature: "Srs",
      OfficialLocationName: "Sorsele",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Strömtorp",
      Geometry: {
        WGS84: "POINT (14.411523962028872 59.25063463845019)",
      },
      LocationSignature: "Srt",
      OfficialLocationName: "Strömtorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skruv",
      Geometry: {
        WGS84: "POINT (15.367249504289669 56.67781516987047)",
      },
      LocationSignature: "Sru",
      OfficialLocationName: "Skruv",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Storvreta",
      Geometry: {
        WGS84: "POINT (17.70624373515717 59.95630278535516)",
      },
      LocationSignature: "Srv",
      OfficialLocationName: "Storvreta",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Stenstorp",
      Geometry: {
        WGS84: "POINT (13.712659768978105 58.27291618463623)",
      },
      LocationSignature: "Ss",
      OfficialLocationName: "Stenstorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sjösa",
      Geometry: {
        WGS84: "POINT (17.096430406735053 58.78220230088201)",
      },
      LocationSignature: "Ssa",
      OfficialLocationName: "Sjösa",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Strömsholm",
      Geometry: {
        WGS84: "POINT (16.267670411516967 59.50098262292828)",
      },
      LocationSignature: "Ssh",
      OfficialLocationName: "Strömsholm",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Stockholms Södra",
      Geometry: {
        WGS84: "POINT (18.060508539616663 59.313422739013326)",
      },
      LocationSignature: "Sst",
      OfficialLocationName: "Stockholms Södra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Segersäng",
      Geometry: {
        WGS84: "POINT (17.927110595566532 59.029504897567406)",
      },
      LocationSignature: "Ssä",
      OfficialLocationName: "Segersäng",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Säter",
      Geometry: {
        WGS84: "POINT (15.75707875341139 60.34658945633742)",
      },
      LocationSignature: "St",
      OfficialLocationName: "Säter",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Stuvsta",
      Geometry: {
        WGS84: "POINT (17.997188639671837 59.25466170156521)",
      },
      LocationSignature: "Sta",
      OfficialLocationName: "Stuvsta",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Stångby",
      Geometry: {
        WGS84: "POINT (13.199201061644683 55.74965620244009)",
      },
      LocationSignature: "Stb",
      OfficialLocationName: "Stångby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Stöde",
      Geometry: {
        WGS84: "POINT (16.59912484713559 62.41978682271702)",
      },
      LocationSignature: "Std",
      OfficialLocationName: "Stöde",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Svarte",
      Geometry: {
        WGS84: "POINT (13.717903263237991 55.42854773824731)",
      },
      LocationSignature: "Ste",
      OfficialLocationName: "Svarte",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stegsskogen",
      Geometry: {
        WGS84: "POINT (17.201841413927134 61.847946073730995)",
      },
      LocationSignature: "Sten",
      OfficialLocationName: "Stegsskogen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Stora Höga",
      Geometry: {
        WGS84: "POINT (11.835770406511925 58.01867922191185)",
      },
      LocationSignature: "Sth",
      OfficialLocationName: "Stora Höga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Storbergets industrispår",
      Geometry: {
        WGS84: "POINT (16.451184975680246 64.25631667250723)",
      },
      LocationSignature: "Sti",
      OfficialLocationName: "Storbergets industrispår",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stjerneskolan",
      Geometry: {
        WGS84: "POINT (13.011138231141132 60.139069155249075)",
      },
      LocationSignature: "Stje",
      OfficialLocationName: "Stjerneskolan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sikträsk",
      Geometry: {
        WGS84: "POINT (20.44229385915465 67.17444381563038)",
      },
      LocationSignature: "Stk",
      OfficialLocationName: "Sikträsk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ställdalen",
      Geometry: {
        WGS84: "POINT (14.941722782907375 59.93441852108295)",
      },
      LocationSignature: "Stl",
      OfficialLocationName: "Ställdalen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Stora Levene",
      Geometry: {
        WGS84: "POINT (12.925755809778781 58.32592468992109)",
      },
      LocationSignature: "Stlv",
      OfficialLocationName: "Stora Levene",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Storträsk",
      Geometry: {
        WGS84: "POINT (20.060009177003472 65.15398906962758)",
      },
      LocationSignature: "Sto",
      OfficialLocationName: "Storträsk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stockviksverkens nedre",
      Geometry: {
        WGS84: "POINT (17.36077294398588 62.34714289033383)",
      },
      LocationSignature: "Ston",
      OfficialLocationName: "Stockviksverkens nedre",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Svågertorp / Malmö Syd",
      Geometry: {
        WGS84: "POINT (12.99327317320076 55.54725788846203)",
      },
      LocationSignature: "Stp",
      OfficialLocationName: "Svågertorp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Storlien",
      Geometry: {
        WGS84: "POINT (12.100571617339952 63.315944076714366)",
      },
      LocationSignature: "Str",
      OfficialLocationName: "Storlien",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Storsund",
      Geometry: {
        WGS84: "POINT (20.62209574662744 65.57838508428458)",
      },
      LocationSignature: "Sts",
      OfficialLocationName: "Storsund",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stugsund",
      Geometry: {
        WGS84: "POINT (17.11115037363679 61.30502653722101)",
      },
      LocationSignature: "Stu",
      OfficialLocationName: "Stugsund",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Stavre",
      Geometry: {
        WGS84: "POINT (15.311813908268146 62.8261046063748)",
      },
      LocationSignature: "Stv",
      OfficialLocationName: "Stavre",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sollefteå",
      Geometry: {
        WGS84: "POINT (17.26505113582394 63.16703417366437)",
      },
      LocationSignature: "Stå",
      OfficialLocationName: "Sollefteå",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sandträsk",
      Geometry: {
        WGS84: "POINT (21.318035263104164 66.12663650311367)",
      },
      LocationSignature: "Stä",
      OfficialLocationName: "Sandträsk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Strångsjö",
      Geometry: {
        WGS84: "POINT (16.198003964139417 58.90612562623948)",
      },
      LocationSignature: "Stö",
      OfficialLocationName: "Strångsjö",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sundbyberg",
      Geometry: {
        WGS84: "POINT (17.971824561472314 59.360838317032425)",
      },
      LocationSignature: "Sub",
      OfficialLocationName: "Sundbyberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sundsvall C",
      Geometry: {
        WGS84: "POINT (17.3148892715914 62.38657417781709)",
      },
      LocationSignature: "Suc",
      OfficialLocationName: "Sundsvall c",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Surte",
      Geometry: {
        WGS84: "POINT (12.013395423210866 57.824899347663006)",
      },
      LocationSignature: "Sue",
      OfficialLocationName: "Surte",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sturefors",
      Geometry: {
        WGS84: "POINT (15.731734349300964 58.33254245283661)",
      },
      LocationSignature: "Suf",
      OfficialLocationName: "Sturefors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skuckuviken",
      Geometry: {
        WGS84: "POINT (14.495525426698515 62.79930118979378)",
      },
      LocationSignature: "Sui",
      OfficialLocationName: "Skuckuviken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Storuman",
      Geometry: {
        WGS84: "POINT (17.109871461011323 65.09595270191313)",
      },
      LocationSignature: "Sum",
      OfficialLocationName: "Storuman",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sunne",
      Geometry: {
        WGS84: "POINT (13.14917141651931 59.835524624821204)",
      },
      LocationSignature: "Sun",
      OfficialLocationName: "Sunne",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Skutskär",
      Geometry: {
        WGS84: "POINT (17.404550712054245 60.62603692023139)",
      },
      LocationSignature: "Sur",
      OfficialLocationName: "Skutskär",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sunderby sjukhus",
      Geometry: {
        WGS84: "POINT (21.93540803550396 65.6732855914292)",
      },
      LocationSignature: "Sus",
      OfficialLocationName: "Sunderbyns sjukhus",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sundsvall Västra",
      Geometry: {
        WGS84: "POINT (17.293346549612117 62.389481062371765)",
      },
      LocationSignature: "Suv",
      OfficialLocationName: "Sundsvalls västra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Storvik",
      Geometry: {
        WGS84: "POINT (16.53429590414158 60.5818777468156)",
      },
      LocationSignature: "Sv",
      OfficialLocationName: "Storvik",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Svenstavik centrum",
      Geometry: {
        WGS84: "POINT (14.435763743165415 62.76729090201955)",
      },
      LocationSignature: "Svc",
      OfficialLocationName: "Svenstaviks centrum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Säve",
      Geometry: {
        WGS84: "POINT (11.912290149559729 57.8041772605239)",
      },
      LocationSignature: "Sve",
      OfficialLocationName: "Säve",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Svenshögen",
      Geometry: {
        WGS84: "POINT (11.936140778928644 58.1447165547658)",
      },
      LocationSignature: "Svg",
      OfficialLocationName: "Svenshögen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Södra Vi",
      Geometry: {
        WGS84: "POINT (15.792900930341437 57.740248168463765)",
      },
      LocationSignature: "Svi",
      OfficialLocationName: "Södra Vi",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Svedje",
      Geometry: {
        WGS84: "POINT (17.886110047056334 62.68993677772813)",
      },
      LocationSignature: "Svj",
      OfficialLocationName: "Svedje",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Svenstavik",
      Geometry: {
        WGS84: "POINT (14.440749903845948 62.77187951846194)",
      },
      LocationSignature: "Svk",
      OfficialLocationName: "Svenstavik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sävast",
      Geometry: {
        WGS84: "POINT (21.766516268166626 65.76241150093156)",
      },
      LocationSignature: "Svt",
      OfficialLocationName: "Sävast",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Svappavaara",
      Geometry: {
        WGS84: "POINT (21.000230748164466 67.65875532173251)",
      },
      LocationSignature: "Svv",
      OfficialLocationName: "Svappavaara",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Svartå",
      Geometry: {
        WGS84: "POINT (14.515860321730184 59.13231497938185)",
      },
      LocationSignature: "Svå",
      OfficialLocationName: "Svartå",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skytts Vemmerlöv",
      Geometry: {
        WGS84: "POINT (13.154144494652416 55.41014142727715)",
      },
      LocationSignature: "Svö",
      OfficialLocationName: "Skytts Vemmerlöv",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Stockaryd",
      Geometry: {
        WGS84: "POINT (14.591878829462207 57.30984525247945)",
      },
      LocationSignature: "Sy",
      OfficialLocationName: "Stockaryd",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Skillingaryd",
      Geometry: {
        WGS84: "POINT (14.090712832002865 57.42842541794301)",
      },
      LocationSignature: "Syd",
      OfficialLocationName: "Skillingaryd",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Skyttorp",
      Geometry: {
        WGS84: "POINT (17.7369006258753 60.079384892487)",
      },
      LocationSignature: "Syt",
      OfficialLocationName: "Skyttorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stenåsen",
      Geometry: {
        WGS84: "POINT (13.337844988313991 59.50328154246856)",
      },
      LocationSignature: "Sån",
      OfficialLocationName: "Stenåsen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Söråkers södra",
      Geometry: {
        WGS84: "POINT (17.440481170671575 62.54993338511977)",
      },
      LocationSignature: "Sårs",
      OfficialLocationName: "Söråkers södra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sävsjö",
      Geometry: {
        WGS84: "POINT (14.665566693279215 57.40292039779345)",
      },
      LocationSignature: "Sä",
      OfficialLocationName: "Sävsjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Söräng",
      Geometry: {
        WGS84: "POINT (16.21854491153223 61.34036413658828)",
      },
      LocationSignature: "Säg",
      OfficialLocationName: "Söräng",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sävastklinten",
      Geometry: {
        WGS84: "POINT (21.74937132455502 65.77629646122766)",
      },
      LocationSignature: "Sän",
      OfficialLocationName: "Sävastklinten",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skärpan",
      Geometry: {
        WGS84: "POINT (17.576853802163885 60.308089113351215)",
      },
      LocationSignature: "Säp",
      OfficialLocationName: "Skärpan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sävenäs rangerbangård",
      Geometry: {
        WGS84: "POINT (12.046198561249218 57.73013058057448)",
      },
      LocationSignature: "Sär",
      OfficialLocationName: "Sävenäs rangerbangård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sunnäsbruk",
      Geometry: {
        WGS84: "POINT (17.12529795757194 61.108563249534164)",
      },
      LocationSignature: "Säu",
      OfficialLocationName: "Sunnäsbruk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sävenäs",
      Geometry: {
        WGS84: "POINT (12.025185985883473 57.724740151675235)",
      },
      LocationSignature: "Säv",
      OfficialLocationName: "Sävenäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Säby",
      Geometry: {
        WGS84: "POINT (17.702637227405763 59.829068148399806)",
      },
      LocationSignature: "Säy",
      OfficialLocationName: "Säby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Södertälje Centrum",
      Geometry: {
        WGS84: "POINT (17.628350245585395 59.19114492012457)",
      },
      LocationSignature: "Söc",
      OfficialLocationName: "Södertälje centrum",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Södertälje hamn",
      Geometry: {
        WGS84: "POINT (17.64721545709819 59.17763490847209)",
      },
      LocationSignature: "Söd",
      OfficialLocationName: "Södertälje hamn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Söderby",
      Geometry: {
        WGS84: "POINT (17.793602828281763 59.196385520619955)",
      },
      LocationSignature: "Södy",
      OfficialLocationName: "Söderby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sölvesborg",
      Geometry: {
        WGS84: "POINT (14.583113661977116 56.04944439042681)",
      },
      LocationSignature: "Sög",
      OfficialLocationName: "Sölvesborg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stöcke",
      Geometry: {
        WGS84: "POINT (20.25866234389911 63.74282378186285)",
      },
      LocationSignature: "Sök",
      OfficialLocationName: "Stöcke",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Södertälje kanal",
      Geometry: {
        WGS84: "POINT (17.64387541309818 59.184702201969614)",
      },
      LocationSignature: "Söka",
      OfficialLocationName: "Södertälje kanal",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Sösdala",
      Geometry: {
        WGS84: "POINT (13.680791457830793 56.03643863264241)",
      },
      LocationSignature: "Söla",
      OfficialLocationName: "Sösdala",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Södra utmarken",
      Geometry: {
        WGS84: "POINT (12.8455284539855 56.237061649391684)",
      },
      LocationSignature: "Söt",
      OfficialLocationName: "Södra utmarken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Södertälje Syd (SL)",
      Geometry: {
        WGS84: "POINT (17.645486487135074 59.163419995708246)",
      },
      LocationSignature: "Söu",
      OfficialLocationName: "Södertälje syd undre",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skelleftehamns övre",
      Geometry: {
        WGS84: "POINT (21.227539123222698 64.6871948391003)",
      },
      LocationSignature: "Söv",
      OfficialLocationName: "Skelleftehamns övre",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Södertälje Syd",
      Geometry: {
        WGS84: "POINT (17.647961677699406 59.163297667082155)",
      },
      LocationSignature: "Söö",
      OfficialLocationName: "Södertälje syd övre",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Töreboda",
      Geometry: {
        WGS84: "POINT (14.127171288759493 58.704573123619056)",
      },
      LocationSignature: "T",
      OfficialLocationName: "Töreboda",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Taberg",
      Geometry: {
        WGS84: "POINT (14.086971324390367 57.678678622316184)",
      },
      LocationSignature: "Tag",
      OfficialLocationName: "Taberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tillberga",
      Geometry: {
        WGS84: "POINT (16.62408747664672 59.68742415596676)",
      },
      LocationSignature: "Tb",
      OfficialLocationName: "Tillberga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tystberga",
      Geometry: {
        WGS84: "POINT (17.236070652701297 58.848522060712575)",
      },
      LocationSignature: "Tba",
      OfficialLocationName: "Tystberga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tureberg",
      Geometry: {
        WGS84: "POINT (17.946446148602234 59.43044361581522)",
      },
      LocationSignature: "Tbg",
      OfficialLocationName: "Tureberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Torbacken",
      Geometry: {
        WGS84: "POINT (12.243018803241961 58.068724021676495)",
      },
      LocationSignature: "Tbn",
      OfficialLocationName: "Torbacken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tobo",
      Geometry: {
        WGS84: "POINT (17.6337596120473 60.259036019685695)",
      },
      LocationSignature: "Tbo",
      OfficialLocationName: "Tobo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tibro",
      Geometry: {
        WGS84: "POINT (14.167421816650002 58.424862342364044)",
      },
      LocationSignature: "Tbr",
      OfficialLocationName: "Tibro",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tandsbyn",
      Geometry: {
        WGS84: "POINT (14.74403140627081 62.99873430320563)",
      },
      LocationSignature: "Tby",
      OfficialLocationName: "Tandsbyn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Trödje",
      Geometry: {
        WGS84: "POINT (17.199079624026957 60.81439890481609)",
      },
      LocationSignature: "Tdj",
      OfficialLocationName: "Trödje",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Torebo",
      Geometry: {
        WGS84: "POINT (12.455654810940652 56.94908348296948)",
      },
      LocationSignature: "Teo",
      OfficialLocationName: "Torebo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tolikberget",
      Geometry: {
        WGS84: "POINT (20.907244817818512 66.4132585858858)",
      },
      LocationSignature: "Tet",
      OfficialLocationName: "Tolikberget",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tågarp",
      Geometry: {
        WGS84: "POINT (12.947381489347032 55.927403258121785)",
      },
      LocationSignature: "Tgp",
      OfficialLocationName: "Tågarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Trångstad",
      Geometry: {
        WGS84: "POINT (13.258352501514773 59.554773213650314)",
      },
      LocationSignature: "Tgs",
      OfficialLocationName: "Trångstad",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tenhult",
      Geometry: {
        WGS84: "POINT (14.326605469367479 57.70521548903799)",
      },
      LocationSignature: "Th",
      OfficialLocationName: "Tenhult",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tallhed",
      Geometry: {
        WGS84: "POINT (14.709128203122093 61.210087670161826)",
      },
      LocationSignature: "Thd",
      OfficialLocationName: "Tallhed",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tornhill",
      Geometry: {
        WGS84: "POINT (13.189795351981873 55.73805960046775)",
      },
      LocationSignature: "Thl",
      OfficialLocationName: "Tornhill",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Träskholm",
      Geometry: {
        WGS84: "POINT (20.09513515802838 65.23220213017427)",
      },
      LocationSignature: "Thm",
      OfficialLocationName: "Träskholm",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Trollhättan C",
      Geometry: {
        WGS84: "POINT (12.298873391406515 58.28761287752628)",
      },
      LocationSignature: "Thn",
      OfficialLocationName: "Trollhättan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Trehörningsjö",
      Geometry: {
        WGS84: "POINT (18.865575698427193 63.69141263584453)",
      },
      LocationSignature: "Thö",
      OfficialLocationName: "Trehörningsjö",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Timsfors",
      Geometry: {
        WGS84: "POINT (13.610699283769161 56.48417519937704)",
      },
      LocationSignature: "Tim",
      OfficialLocationName: "Timsfors",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tierp",
      Geometry: {
        WGS84: "POINT (17.513066776528493 60.34578295707297)",
      },
      LocationSignature: "Tip",
      OfficialLocationName: "Tierp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tjunnaryd",
      Geometry: {
        WGS84: "POINT (14.975285149871361 57.504517540061464)",
      },
      LocationSignature: "Tj",
      OfficialLocationName: "Tjunnaryd",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tjugonde slussen",
      Geometry: {
        WGS84: "POINT (12.245792469506286 59.00261153183182)",
      },
      LocationSignature: "Tjs",
      OfficialLocationName: "Tjugonde slussen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tjustskolan",
      Geometry: {
        WGS84: "POINT (16.630536157017342 57.75293422614994)",
      },
      LocationSignature: "Tjsk",
      OfficialLocationName: "Tjustskolan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tjärnvik",
      Geometry: {
        WGS84: "POINT (17.319671824986838 62.10002785009845)",
      },
      LocationSignature: "Tjä",
      OfficialLocationName: "Tjärnvik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tallåsen",
      Geometry: {
        WGS84: "POINT (16.01268952858495 61.86726811626779)",
      },
      LocationSignature: "Tl",
      OfficialLocationName: "Tallåsen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tällberg",
      Geometry: {
        WGS84: "POINT (15.02040678081707 60.81875585328287)",
      },
      LocationSignature: "Tlg",
      OfficialLocationName: "Tällberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tomelilla",
      Geometry: {
        WGS84: "POINT (13.949663134726764 55.54583465207257)",
      },
      LocationSignature: "Tli",
      OfficialLocationName: "Tomelilla",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tolita",
      Geometry: {
        WGS84: "POINT (13.240058028329964 59.5777826994211)",
      },
      LocationSignature: "Tlt",
      OfficialLocationName: "Tolita",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tomteboda bangård",
      Geometry: {
        WGS84: "POINT (18.020304631522645 59.3456514379661)",
      },
      LocationSignature: "Tm",
      OfficialLocationName: "Tomteboda bangård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tomteboda övre",
      Geometry: {
        WGS84: "POINT (18.016423862998142 59.351088102413044)",
      },
      LocationSignature: "Tmö",
      OfficialLocationName: "Tomteboda övre",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tannefors",
      Geometry: {
        WGS84: "POINT (15.660269524864816 58.400411375265186)",
      },
      LocationSignature: "Tn",
      OfficialLocationName: "Tannefors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Torneträsk",
      Geometry: {
        WGS84: "POINT (19.707929473987917 68.217515131929)",
      },
      LocationSignature: "Tnk",
      OfficialLocationName: "Torneträsk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tranås",
      Geometry: {
        WGS84: "POINT (14.97512128865337 58.038088925601144)",
      },
      LocationSignature: "Tns",
      OfficialLocationName: "Tranås",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tanum",
      Geometry: {
        WGS84: "POINT (11.295587334864106 58.713577029133404)",
      },
      LocationSignature: "Tnu",
      OfficialLocationName: "Tanum",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Torpshammar",
      Geometry: {
        WGS84: "POINT (16.33540081744031 62.4727105744491)",
      },
      LocationSignature: "To",
      OfficialLocationName: "Torpshammar",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tofta",
      Geometry: {
        WGS84: "POINT (12.300448183722388 57.17332785659042)",
      },
      LocationSignature: "Tof",
      OfficialLocationName: "Tofta",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Torpåkra",
      Geometry: {
        WGS84: "POINT (13.029488485657703 57.94065041052178)",
      },
      LocationSignature: "Top",
      OfficialLocationName: "Torpåkra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Toresta",
      Geometry: {
        WGS84: "POINT (17.574479262092098 59.53361652325665)",
      },
      LocationSignature: "Tot",
      OfficialLocationName: "Toresta",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Torup",
      Geometry: {
        WGS84: "POINT (13.079549912332952 56.95761701729362)",
      },
      LocationSignature: "Tou",
      OfficialLocationName: "Torup",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Torsby",
      Geometry: {
        WGS84: "POINT (13.002740109219932 60.13649071411862)",
      },
      LocationSignature: "Toy",
      OfficialLocationName: "Torsby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Teckomatorp",
      Geometry: {
        WGS84: "POINT (13.082523880380096 55.87052818699065)",
      },
      LocationSignature: "Tp",
      OfficialLocationName: "Teckomatorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tappudden",
      Geometry: {
        WGS84: "POINT (15.12817741639522 61.17228286382721)",
      },
      LocationSignature: "Tpu",
      OfficialLocationName: "Tappudden",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Traryd",
      Geometry: {
        WGS84: "POINT (13.752688475732716 56.579342171869556)",
      },
      LocationSignature: "Trd",
      OfficialLocationName: "Traryd",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Trekanten",
      Geometry: {
        WGS84: "POINT (16.113449667032402 56.70294806718645)",
      },
      LocationSignature: "Tre",
      OfficialLocationName: "Trekanten",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Trelleborg",
      Geometry: {
        WGS84: "POINT (13.161120593885759 55.3715014913109)",
      },
      LocationSignature: "Trg",
      OfficialLocationName: "Trelleborg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Triangeln",
      Geometry: {
        WGS84: "POINT (13.000853697723674 55.59246336791903)",
      },
      LocationSignature: "Tri",
      OfficialLocationName: "Triangeln",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Trolmen",
      Geometry: {
        WGS84: "POINT (13.347926934850472 58.594828108595486)",
      },
      LocationSignature: "Trm",
      OfficialLocationName: "Trolmen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Timrå",
      Geometry: {
        WGS84: "POINT (17.33027035317587 62.48640256321447)",
      },
      LocationSignature: "Trå",
      OfficialLocationName: "Timrå",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tungelsta",
      Geometry: {
        WGS84: "POINT (18.046493774584693 59.10284954389717)",
      },
      LocationSignature: "Ts",
      OfficialLocationName: "Tungelsta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tågsjöberg",
      Geometry: {
        WGS84: "POINT (16.742004628585956 63.673493964630396)",
      },
      LocationSignature: "Tsg",
      OfficialLocationName: "Tågsjöberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tisselskog",
      Geometry: {
        WGS84: "POINT (12.385625007670573 58.89719548559316)",
      },
      LocationSignature: "Tsl",
      OfficialLocationName: "Tisselskog",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Torsåker",
      Geometry: {
        WGS84: "POINT (16.490255793861195 60.51458534848631)",
      },
      LocationSignature: "Tså",
      OfficialLocationName: "Torsåker",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tortuna",
      Geometry: {
        WGS84: "POINT (16.720767162038683 59.66983642782106)",
      },
      LocationSignature: "Ttu",
      OfficialLocationName: "Tortuna",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tumba",
      Geometry: {
        WGS84: "POINT (17.83838552404354 59.199494369094495)",
      },
      LocationSignature: "Tu",
      OfficialLocationName: "Tumba",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tunadal",
      Geometry: {
        WGS84: "POINT (17.388325109569184 62.40570158538972)",
      },
      LocationSignature: "Tud",
      OfficialLocationName: "Tunadal",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tullinge",
      Geometry: {
        WGS84: "POINT (17.90526941954288 59.20569892752887)",
      },
      LocationSignature: "Tul",
      OfficialLocationName: "Tullinge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tunneby",
      Geometry: {
        WGS84: "POINT (14.082767770671795 56.45764261886403)",
      },
      LocationSignature: "Tun",
      OfficialLocationName: "Tunneby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tväråbäck",
      Geometry: {
        WGS84: "POINT (19.72412938457328 63.989480436762896)",
      },
      LocationSignature: "Tvb",
      OfficialLocationName: "Tväråbäck",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Torved",
      Geometry: {
        WGS84: "POINT (14.100594332661101 58.82626908585966)",
      },
      LocationSignature: "Tvd",
      OfficialLocationName: "Torved",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tvärålund",
      Geometry: {
        WGS84: "POINT (19.677608488593513 64.09966413146115)",
      },
      LocationSignature: "Tvä",
      OfficialLocationName: "Tvärålund",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tyringe",
      Geometry: {
        WGS84: "POINT (13.598412201416835 56.159642334609906)",
      },
      LocationSignature: "Ty",
      OfficialLocationName: "Tyringe",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tyllered",
      Geometry: {
        WGS84: "POINT (12.391631696621602 57.01989594027928)",
      },
      LocationSignature: "Tye",
      OfficialLocationName: "Tyllered",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Trångsund",
      Geometry: {
        WGS84: "POINT (18.13066308690351 59.2278010926431)",
      },
      LocationSignature: "Tåd",
      OfficialLocationName: "Trångsund",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Trångsviken",
      Geometry: {
        WGS84: "POINT (14.013444875942062 63.331841669071174)",
      },
      LocationSignature: "Tåv",
      OfficialLocationName: "Trångsviken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tälle",
      Geometry: {
        WGS84: "POINT (15.019017352589032 59.05017312357227)",
      },
      LocationSignature: "Täl",
      OfficialLocationName: "Tälle",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Tjörnarp",
      Geometry: {
        WGS84: "POINT (13.631212179236938 56.001482609448786)",
      },
      LocationSignature: "Tö",
      OfficialLocationName: "Tjörnarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tösse",
      Geometry: {
        WGS84: "POINT (12.643358969894535 58.97135854973525)",
      },
      LocationSignature: "Töe",
      OfficialLocationName: "Tösse",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Töva",
      Geometry: {
        WGS84: "POINT (17.10980977939033 62.37374129482955)",
      },
      LocationSignature: "Töv",
      OfficialLocationName: "Töva",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Uppsala C",
      Geometry: {
        WGS84: "POINT (17.64869381436992 59.85788169781748)",
      },
      LocationSignature: "U",
      OfficialLocationName: "Uppsala central",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ulriksdal",
      Geometry: {
        WGS84: "POINT (18.00129058481793 59.3797706297159)",
      },
      LocationSignature: "Udl",
      OfficialLocationName: "Ulriksdal",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ulriksfors",
      Geometry: {
        WGS84: "POINT (15.62081731272755 63.83231220134711)",
      },
      LocationSignature: "Uf",
      OfficialLocationName: "Ulriksfors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ulvshyttan",
      Geometry: {
        WGS84: "POINT (15.35680333769202 60.33010085615723)",
      },
      LocationSignature: "Uly",
      OfficialLocationName: "Ulvshyttan",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Undersåker",
      Geometry: {
        WGS84: "POINT (13.238468868154436 63.314993806109705)",
      },
      LocationSignature: "Un",
      OfficialLocationName: "Undersåker",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Uppsala norra",
      Geometry: {
        WGS84: "POINT (17.612216446551198 59.86715452731721)",
      },
      LocationSignature: "Una",
      OfficialLocationName: "Uppsala norra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Upplands Väsby",
      Geometry: {
        WGS84: "POINT (17.899233873234227 59.52048495324485)",
      },
      LocationSignature: "Upv",
      OfficialLocationName: "Upplands Väsby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ulvstorp",
      Geometry: {
        WGS84: "POINT (14.68926900521358 57.4770900942035)",
      },
      LocationSignature: "Utp",
      OfficialLocationName: "Ulvstorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Utsikten",
      Geometry: {
        WGS84: "POINT (17.89637730060255 59.2031118281726)",
      },
      LocationSignature: "Uts",
      OfficialLocationName: "Utsikten",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Uddevalla C",
      Geometry: {
        WGS84: "POINT (11.92343684034566 58.35422333538101)",
      },
      LocationSignature: "Uv",
      OfficialLocationName: "Uddevalla central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ursviken",
      Geometry: {
        WGS84: "POINT (21.169963182259128 64.70842910386303)",
      },
      LocationSignature: "Uvn",
      OfficialLocationName: "Ursviken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Umeå C",
      Geometry: {
        WGS84: "POINT (20.26700335933932 63.830145338113354)",
      },
      LocationSignature: "Uå",
      OfficialLocationName: "Umeå central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Umeå godsbangård",
      Geometry: {
        WGS84: "POINT (20.23747489467179 63.844654058682444)",
      },
      LocationSignature: "Uågb",
      OfficialLocationName: "Umeå godsbangård",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Umeå östra",
      Geometry: {
        WGS84: "POINT (20.29358809905884 63.818221267698995)",
      },
      LocationSignature: "Uåö",
      OfficialLocationName: "Umeå östra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Uddevalla Östra",
      Geometry: {
        WGS84: "POINT (11.945058028507319 58.350355715864424)",
      },
      LocationSignature: "Uö",
      OfficialLocationName: "Uddevalla östra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Värnamo",
      Geometry: {
        WGS84: "POINT (14.035995992549305 57.18637858551666)",
      },
      LocationSignature: "V",
      OfficialLocationName: "Värnamo",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vara",
      Geometry: {
        WGS84: "POINT (12.95151028626041 58.26096162875698)",
      },
      LocationSignature: "Vaa",
      OfficialLocationName: "Vara",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vad",
      Geometry: {
        WGS84: "POINT (15.641570835108386 60.03236487171857)",
      },
      LocationSignature: "Vad",
      OfficialLocationName: "Vad",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vargön",
      Geometry: {
        WGS84: "POINT (12.391194643610309 58.35977256149721)",
      },
      LocationSignature: "Vag",
      OfficialLocationName: "Vargön",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Varjisträsk",
      Geometry: {
        WGS84: "POINT (19.529869928454637 66.04456840193006)",
      },
      LocationSignature: "Vaj",
      OfficialLocationName: "Varjisträsk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Varberg",
      Geometry: {
        WGS84: "POINT (12.248148605641644 57.10940435733867)",
      },
      LocationSignature: "Vb",
      OfficialLocationName: "Varberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Västra Bodarne",
      Geometry: {
        WGS84: "POINT (12.474109701084204 57.887564065583405)",
      },
      LocationSignature: "Vbd",
      OfficialLocationName: "Västra Bodarne",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vännäsby",
      Geometry: {
        WGS84: "POINT (19.817416135342015 63.915223210714174)",
      },
      LocationSignature: "Vby",
      OfficialLocationName: "Vännäsby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Värmlands Bro",
      Geometry: {
        WGS84: "POINT (13.013889775896303 59.18534449254292)",
      },
      LocationSignature: "Vdb",
      OfficialLocationName: "Värmlands Bro",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Veddige",
      Geometry: {
        WGS84: "POINT (12.336845403258469 57.26668289387274)",
      },
      LocationSignature: "Vdi",
      OfficialLocationName: "Veddige",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vindeln",
      Geometry: {
        WGS84: "POINT (19.713976874034127 64.20409934111386)",
      },
      LocationSignature: "Vdn",
      OfficialLocationName: "Vindeln",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vedevåg",
      Geometry: {
        WGS84: "POINT (15.282285132489159 59.52359597557751)",
      },
      LocationSignature: "Vdv",
      OfficialLocationName: "Vedevåg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Väse",
      Geometry: {
        WGS84: "POINT (13.853246879190458 59.38163802328396)",
      },
      LocationSignature: "Ve",
      OfficialLocationName: "Väse",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Velanda södra",
      Geometry: {
        WGS84: "POINT (12.325544559205545 58.21814541569815)",
      },
      LocationSignature: "Veas",
      OfficialLocationName: "Velanda södra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vedum",
      Geometry: {
        WGS84: "POINT (12.998680295557389 58.16898333314739)",
      },
      LocationSignature: "Ved",
      OfficialLocationName: "Vedum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vartofta",
      Geometry: {
        WGS84: "POINT (13.628000302571145 58.0921342693588)",
      },
      LocationSignature: "Vf",
      OfficialLocationName: "Vartofta",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vissefjärda",
      Geometry: {
        WGS84: "POINT (15.582586142693545 56.53801694212454)",
      },
      LocationSignature: "Vfa",
      OfficialLocationName: "Vissefjärda",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Viskafors",
      Geometry: {
        WGS84: "POINT (12.84512087808266 57.62927078065517)",
      },
      LocationSignature: "Vfo",
      OfficialLocationName: "Viskafors",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vänersborg C",
      Geometry: {
        WGS84: "POINT (12.318685025914473 58.37754860433986)",
      },
      LocationSignature: "Vg",
      OfficialLocationName: "Vänersborg central",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vega",
      Geometry: {
        WGS84: "POINT (18.135840879679357 59.186802676715665)",
      },
      LocationSignature: "Vga",
      OfficialLocationName: "Vega",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vaggeryd",
      Geometry: {
        WGS84: "POINT (14.145410375900997 57.498203709590534)",
      },
      LocationSignature: "Vgd",
      OfficialLocationName: "Vaggeryd",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vårgårda",
      Geometry: {
        WGS84: "POINT (12.809253563632469 58.03114357080547)",
      },
      LocationSignature: "Vgå",
      OfficialLocationName: "Vårgårda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vegeholm",
      Geometry: {
        WGS84: "POINT (12.793779564559399 56.20865773217615)",
      },
      LocationSignature: "Vh",
      OfficialLocationName: "Vegeholm",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vattholma",
      Geometry: {
        WGS84: "POINT (17.730865290008413 60.02511868161011)",
      },
      LocationSignature: "Vha",
      OfficialLocationName: "Vattholma",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vagnhärad",
      Geometry: {
        WGS84: "POINT (17.49878397536677 58.94583470022704)",
      },
      LocationSignature: "Vhd",
      OfficialLocationName: "Vagnhärad",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Västerhaninge",
      Geometry: {
        WGS84: "POINT (18.103366450191004 59.12303313879594)",
      },
      LocationSignature: "Vhe",
      OfficialLocationName: "Västerhaninge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vikmanshyttan",
      Geometry: {
        WGS84: "POINT (15.862408417564374 60.29659786091728)",
      },
      LocationSignature: "Vhy",
      OfficialLocationName: "Vikmanshyttan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Via",
      Geometry: {
        WGS84: "POINT (17.191079582866042 61.80793952683063)",
      },
      LocationSignature: "Via",
      OfficialLocationName: "Via",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vimmerby",
      Geometry: {
        WGS84: "POINT (15.858462336452869 57.66303493311814)",
      },
      LocationSignature: "Vib",
      OfficialLocationName: "Vimmerby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vimmerby Hamra",
      Geometry: {
        WGS84: "POINT (15.869022590346383 57.63711876388124)",
      },
      LocationSignature: "Vibh",
      OfficialLocationName: "Vimmerby Hamra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Västra Ingelstad",
      Geometry: {
        WGS84: "POINT (13.10647547845362 55.491522424202245)",
      },
      LocationSignature: "Vid",
      OfficialLocationName: "Västra Ingelstad",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vikersvik",
      Geometry: {
        WGS84: "POINT (14.859800877442353 59.46453958116082)",
      },
      LocationSignature: "Vik",
      OfficialLocationName: "Vikersvik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vimnarp",
      Geometry: {
        WGS84: "POINT (14.692784357841488 57.70764846213031)",
      },
      LocationSignature: "Vim",
      OfficialLocationName: "Vimnarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Viarp",
      Geometry: {
        WGS84: "POINT (12.807348951422938 56.33298357801248)",
      },
      LocationSignature: "Vip",
      OfficialLocationName: "Viarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Viskan",
      Geometry: {
        WGS84: "POINT (16.433481172691373 62.44947324741554)",
      },
      LocationSignature: "Visk",
      OfficialLocationName: "Viskan",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vassijaure",
      Geometry: {
        WGS84: "POINT (18.257670045642197 68.42957731290946)",
      },
      LocationSignature: "Vj",
      OfficialLocationName: "Vassijaure",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vojmån",
      Geometry: {
        WGS84: "POINT (16.816545306197494 64.79184829267957)",
      },
      LocationSignature: "Vjm",
      OfficialLocationName: "Vojmån",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Västervik",
      Geometry: {
        WGS84: "POINT (16.642875950478558 57.75563171574041)",
      },
      LocationSignature: "Vk",
      OfficialLocationName: "Västervik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vika",
      Geometry: {
        WGS84: "POINT (14.459743968959296 60.94552200218654)",
      },
      LocationSignature: "Vka",
      OfficialLocationName: "Vika",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vallsta",
      Geometry: {
        WGS84: "POINT (16.36748127138293 61.518063691991316)",
      },
      LocationSignature: "Vl",
      OfficialLocationName: "Vallsta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vilhelmsborg",
      Geometry: {
        WGS84: "POINT (13.73198571879785 55.42700503197543)",
      },
      LocationSignature: "Vlb",
      OfficialLocationName: "Vilhelmsborg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vinlidsberg",
      Geometry: {
        WGS84: "POINT (17.063427485714946 65.01699640680968)",
      },
      LocationSignature: "Vld",
      OfficialLocationName: "Vinlidsberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vallnäs",
      Geometry: {
        WGS84: "POINT (15.474793537802535 57.61770853396815)",
      },
      LocationSignature: "Vln",
      OfficialLocationName: "Vallnäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vattjom",
      Geometry: {
        WGS84: "POINT (17.005752809488232 62.367270060899145)",
      },
      LocationSignature: "Vm",
      OfficialLocationName: "Vattjom",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vilhelmina",
      Geometry: {
        WGS84: "POINT (16.65138978584911 64.6229731673905)",
      },
      LocationSignature: "Vma",
      OfficialLocationName: "Vilhelmina",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vilhelmina norra",
      Geometry: {
        WGS84: "POINT (16.613601624515624 64.64639488575558)",
      },
      LocationSignature: "Vman",
      OfficialLocationName: "Vilhelmina norra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vilhelmina timmer",
      Geometry: {
        WGS84: "POINT (16.61375807543596 64.6493820117804)",
      },
      LocationSignature: "Vmat",
      OfficialLocationName: "Vilhelmina timmer",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "VME",
      Geometry: {
        WGS84: "POINT (16.446014019975404 59.405815401145425)",
      },
      LocationSignature: "Vme",
      OfficialLocationName: "VME",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Villersmuren",
      Geometry: {
        WGS84: "POINT (16.696344553652775 60.60717515030991)",
      },
      LocationSignature: "Vmu",
      OfficialLocationName: "Villersmuren",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Värtan",
      Geometry: {
        WGS84: "POINT (18.094923050232673 59.3510713152609)",
      },
      LocationSignature: "Vn",
      OfficialLocationName: "Värtan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Voxna",
      Geometry: {
        WGS84: "POINT (15.572487263238113 61.340688259018215)",
      },
      LocationSignature: "Vna",
      OfficialLocationName: "Voxna",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vännäs",
      Geometry: {
        WGS84: "POINT (19.749005567890734 63.91027427561762)",
      },
      LocationSignature: "Vns",
      OfficialLocationName: "Vännäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vackernäset",
      Geometry: {
        WGS84: "POINT (16.117951207797987 63.00411601874607)",
      },
      LocationSignature: "Vnt",
      OfficialLocationName: "Vackernäset",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vinnö",
      Geometry: {
        WGS84: "POINT (14.089146607505324 56.053184669355254)",
      },
      LocationSignature: "Vnö",
      OfficialLocationName: "Vinnö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vansbro",
      Geometry: {
        WGS84: "POINT (14.226069019340349 60.51249802090835)",
      },
      LocationSignature: "Vo",
      OfficialLocationName: "Vansbro",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Varpemossen",
      Geometry: {
        WGS84: "POINT (12.303182801258048 58.12767784662423)",
      },
      LocationSignature: "Vpm",
      OfficialLocationName: "Varpemossen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vingåker",
      Geometry: {
        WGS84: "POINT (15.876508044889901 59.04210176467909)",
      },
      LocationSignature: "Vr",
      OfficialLocationName: "Vingåker",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vrena",
      Geometry: {
        WGS84: "POINT (16.704290020129495 58.86241050436821)",
      },
      LocationSignature: "Vre",
      OfficialLocationName: "Vrena",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Verveln",
      Geometry: {
        WGS84: "POINT (15.609432792597241 57.89418223464518)",
      },
      LocationSignature: "Vrn",
      OfficialLocationName: "Verveln",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vekerum",
      Geometry: {
        WGS84: "POINT (14.780727231863164 56.17695388785271)",
      },
      LocationSignature: "Vru",
      OfficialLocationName: "Vekerum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Värö",
      Geometry: {
        WGS84: "POINT (12.19607210258432 57.238625054834266)",
      },
      LocationSignature: "Vrö",
      OfficialLocationName: "Värö",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vislanda",
      Geometry: {
        WGS84: "POINT (14.456503391464464 56.78865375112148)",
      },
      LocationSignature: "Vs",
      OfficialLocationName: "Vislanda",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vikingstad",
      Geometry: {
        WGS84: "POINT (15.432983306582676 58.38348056921931)",
      },
      LocationSignature: "Vsd",
      OfficialLocationName: "Vikingstad",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Valskog",
      Geometry: {
        WGS84: "POINT (15.951253655535792 59.443550861026786)",
      },
      LocationSignature: "Vsg",
      OfficialLocationName: "Valskog",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vittsjö",
      Geometry: {
        WGS84: "POINT (13.66298862977174 56.342332381096334)",
      },
      LocationSignature: "Vsj",
      OfficialLocationName: "Vittsjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vuonoskogen",
      Geometry: {
        WGS84: "POINT (23.724902929037633 65.86046945110843)",
      },
      LocationSignature: "Vsk",
      OfficialLocationName: "Vuonoskogen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Virsbo",
      Geometry: {
        WGS84: "POINT (16.084287806932494 59.871855109392925)",
      },
      LocationSignature: "Vso",
      OfficialLocationName: "Virsbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Viresjö",
      Geometry: {
        WGS84: "POINT (15.912973109730595 58.20390273154081)",
      },
      LocationSignature: "Vsö",
      OfficialLocationName: "Viresjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vretstorp",
      Geometry: {
        WGS84: "POINT (14.864881658041284 59.02158171281365)",
      },
      LocationSignature: "Vt",
      OfficialLocationName: "Vretstorp",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vetlanda",
      Geometry: {
        WGS84: "POINT (15.081629057016732 57.42593877763404)",
      },
      LocationSignature: "Vta",
      OfficialLocationName: "Vetlanda",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vattnäs",
      Geometry: {
        WGS84: "POINT (14.597827372400163 61.0493419740301)",
      },
      LocationSignature: "Vtn",
      OfficialLocationName: "Vattnäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Västra Torup",
      Geometry: {
        WGS84: "POINT (13.507725853681846 56.14423014316046)",
      },
      LocationSignature: "Vto",
      OfficialLocationName: "Västra Torup",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vitvattnet",
      Geometry: {
        WGS84: "POINT (23.199019541268463 66.05973764251532)",
      },
      LocationSignature: "Vtv",
      OfficialLocationName: "Vitvattnet",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vitåfors",
      Geometry: {
        WGS84: "POINT (20.722180971398505 67.19344764183892)",
      },
      LocationSignature: "Vtå",
      OfficialLocationName: "Vitåfors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vallvik",
      Geometry: {
        WGS84: "POINT (17.12931768152377 61.19338857217373)",
      },
      LocationSignature: "Vv",
      OfficialLocationName: "Vallvik",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Västerås C",
      Geometry: {
        WGS84: "POINT (16.552911395701365 59.60769591346944)",
      },
      LocationSignature: "Vå",
      OfficialLocationName: "Västerås central",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vålberg",
      Geometry: {
        WGS84: "POINT (13.197398736891367 59.398934277506505)",
      },
      LocationSignature: "Våb",
      OfficialLocationName: "Vålberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vallåkra",
      Geometry: {
        WGS84: "POINT (12.862229987247117 55.96382723958079)",
      },
      LocationSignature: "Våk",
      OfficialLocationName: "Vallåkra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Västerås norra",
      Geometry: {
        WGS84: "POINT (16.583068638375146 59.645991203887846)",
      },
      LocationSignature: "Vån",
      OfficialLocationName: "Västerås norra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Västerås västra",
      Geometry: {
        WGS84: "POINT (16.50769251598782 59.59560523584816)",
      },
      LocationSignature: "Våv",
      OfficialLocationName: "Västerås västra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Väring",
      Geometry: {
        WGS84: "POINT (13.959383096702147 58.511465342624554)",
      },
      LocationSignature: "Vä",
      OfficialLocationName: "Väring",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vätteryd",
      Geometry: {
        WGS84: "POINT (13.654906572522266 56.01924510051186)",
      },
      LocationSignature: "Väd",
      OfficialLocationName: "Vätteryd",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vännäs norra",
      Geometry: {
        WGS84: "POINT (19.741427866518897 63.92946238255092)",
      },
      LocationSignature: "Vän",
      OfficialLocationName: "Vännäs norra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Västra Ämtervik",
      Geometry: {
        WGS84: "POINT (13.147127417799577 59.72774026993494)",
      },
      LocationSignature: "Vät",
      OfficialLocationName: "Västra Ämtervik",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Västeraspby",
      Geometry: {
        WGS84: "POINT (17.747631407792724 63.05568689711318)",
      },
      LocationSignature: "Väy",
      OfficialLocationName: "Västeraspby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Växjö",
      Geometry: {
        WGS84: "POINT (14.805327926627928 56.876666244681225)",
      },
      LocationSignature: "Vö",
      OfficialLocationName: "Växjö",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Vinslöv",
      Geometry: {
        WGS84: "POINT (13.916529317596337 56.10492079291515)",
      },
      LocationSignature: "Vöv",
      OfficialLocationName: "Vinslöv",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Aga",
      Geometry: {
        WGS84: "POINT (18.15647602526266 59.346296449912714)",
      },
      LocationSignature: "wAga",
      OfficialLocationName: "Aga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Alléparken",
      Geometry: {
        WGS84: "POINT (17.973987618728962 59.32864612844465)",
      },
      LocationSignature: "wAll",
      OfficialLocationName: "Alléparken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Alvik",
      Geometry: {
        WGS84: "POINT (17.982003937832268 59.33343210533686)",
      },
      LocationSignature: "wAlv",
      OfficialLocationName: "Alvik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Baggeby",
      Geometry: {
        WGS84: "POINT (18.133905338031912 59.35683449080269)",
      },
      LocationSignature: "wBag",
      OfficialLocationName: "Baggeby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bodal",
      Geometry: {
        WGS84: "POINT (18.13845561184383 59.35369647573072)",
      },
      LocationSignature: "wBod",
      OfficialLocationName: "Bodal",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brevik",
      Geometry: {
        WGS84: "POINT (18.20365448512078 59.34839800536344)",
      },
      LocationSignature: "wBvi",
      OfficialLocationName: "Brevik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gåshaga brygga",
      Geometry: {
        WGS84: "POINT (18.233900831405908 59.35687136347078)",
      },
      LocationSignature: "wGåb",
      OfficialLocationName: "Gåshaga brygga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gåshaga",
      Geometry: {
        WGS84: "POINT (18.22790226296775 59.356723541320626)",
      },
      LocationSignature: "wGåh",
      OfficialLocationName: "Gåshaga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Höglandstorget",
      Geometry: {
        WGS84: "POINT (17.939777900735383 59.323474134169345)",
      },
      LocationSignature: "wHlt",
      OfficialLocationName: "Höglandstorget",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Högberga",
      Geometry: {
        WGS84: "POINT (18.193876545373673 59.3443423712286)",
      },
      LocationSignature: "wHöb",
      OfficialLocationName: "Högberga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Wibe",
      Geometry: {
        WGS84: "POINT (14.5241409312912 60.984029545968724)",
      },
      LocationSignature: "Wibe",
      OfficialLocationName: "Wibe",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Klövervägen",
      Geometry: {
        WGS84: "POINT (17.973870734398464 59.32469495893034)",
      },
      LocationSignature: "wKlv",
      OfficialLocationName: "Klövervägen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kottla",
      Geometry: {
        WGS84: "POINT (18.1800359947369 59.34441137966856)",
      },
      LocationSignature: "wKoa",
      OfficialLocationName: "Kottla",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Käppala",
      Geometry: {
        WGS84: "POINT (18.217695117662636 59.35265328738359)",
      },
      LocationSignature: "wKäp",
      OfficialLocationName: "Käppala",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Larsberg",
      Geometry: {
        WGS84: "POINT (18.146203151896497 59.350508075964285)",
      },
      LocationSignature: "wLab",
      OfficialLocationName: "Larsberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lundsbrunn kurort",
      Geometry: {
        WGS84: "POINT (13.444785006843391 58.47731961930436)",
      },
      LocationSignature: "wLnb",
      OfficialLocationName: "Lundsbrunn kurort",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nockeby",
      Geometry: {
        WGS84: "POINT (17.918552137083967 59.32874994453668)",
      },
      LocationSignature: "wNob",
      OfficialLocationName: "Nockeby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nockeby torg",
      Geometry: {
        WGS84: "POINT (17.927763741891816 59.32912728347937)",
      },
      LocationSignature: "wNot",
      OfficialLocationName: "Nockeby torg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Olovslund",
      Geometry: {
        WGS84: "POINT (17.934927046911895 59.32797762248713)",
      },
      LocationSignature: "wOll",
      OfficialLocationName: "Olovslund",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ropsten",
      Geometry: {
        WGS84: "POINT (18.10380268642855 59.35812116418682)",
      },
      LocationSignature: "wRos",
      OfficialLocationName: "Ropsten",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skara",
      Geometry: {
        WGS84: "POINT (13.439133971127497 58.383109667082614)",
      },
      LocationSignature: "wSa",
      OfficialLocationName: "Skara",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skärsätra",
      Geometry: {
        WGS84: "POINT (18.170278855757868 59.343364096459055)",
      },
      LocationSignature: "wSkr",
      OfficialLocationName: "Skärsätra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Smedslätten",
      Geometry: {
        WGS84: "POINT (17.963717645341116 59.32097258620574)",
      },
      LocationSignature: "wSms",
      OfficialLocationName: "Smedslätten",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sissehult",
      Geometry: {
        WGS84: "POINT (15.388913604373016 57.20112846046162)",
      },
      LocationSignature: "wSsu",
      OfficialLocationName: "Sissehult",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Torsvik",
      Geometry: {
        WGS84: "POINT (18.117810788530836 59.36192953892423)",
      },
      LocationSignature: "wTov",
      OfficialLocationName: "Torsvik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tallås Grusgropsväxel",
      Geometry: {
        WGS84: "POINT (16.401978040744993 60.872036548818734)",
      },
      LocationSignature: "wTås",
      OfficialLocationName: "Tallås Grusgropsväxel",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ålstens Gård",
      Geometry: {
        WGS84: "POINT (17.951494334883026 59.320513351606344)",
      },
      LocationSignature: "wÅlG",
      OfficialLocationName: "Ålstens Gård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ålstensgatan",
      Geometry: {
        WGS84: "POINT (17.955800675611837 59.323336105347906)",
      },
      LocationSignature: "wÅls",
      OfficialLocationName: "Ålstensgatan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Anten",
      Geometry: {
        WGS84: "POINT (12.440677771210064 57.98754716232859)",
      },
      LocationSignature: "xA",
      OfficialLocationName: "Anten",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ankarsrum",
      Geometry: {
        WGS84: "POINT (16.334464810860112 57.69985651307497)",
      },
      LocationSignature: "xAkm",
      OfficialLocationName: "Ankarsrum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Almunge",
      Geometry: {
        WGS84: "POINT (18.044469400578777 59.87594550761922)",
      },
      LocationSignature: "xAlg",
      OfficialLocationName: "Almunge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Altorp",
      Geometry: {
        WGS84: "POINT (18.07284721681037 59.410885681003634)",
      },
      LocationSignature: "xAlp",
      OfficialLocationName: "Altorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Arninge",
      Geometry: {
        WGS84: "POINT (18.141155544890534 59.45859480621608)",
      },
      LocationSignature: "xAre",
      OfficialLocationName: "Arninge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Arelid",
      Geometry: {
        WGS84: "POINT (12.465749217001125 58.02206100162949)",
      },
      LocationSignature: "xArl",
      OfficialLocationName: "Arelid",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Aska",
      Geometry: {
        WGS84: "POINT (14.978186718728612 58.454184991564546)",
      },
      LocationSignature: "xAsk",
      OfficialLocationName: "Aska",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bärby",
      Geometry: {
        WGS84: "POINT (17.815478304564717 59.85713295711729)",
      },
      LocationSignature: "xB",
      OfficialLocationName: "Bärby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brobacka",
      Geometry: {
        WGS84: "POINT (12.434538316482998 57.97781737037212)",
      },
      LocationSignature: "xBb",
      OfficialLocationName: "Brobacka",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Blägda",
      Geometry: {
        WGS84: "POINT (16.123409956482053 57.61024896168865)",
      },
      LocationSignature: "xBlä",
      OfficialLocationName: "Blägda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bor Norra",
      Geometry: {
        WGS84: "POINT (14.170256813603578 57.11635785107298)",
      },
      LocationSignature: "xBn",
      OfficialLocationName: "Bor Norra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bråvallavägen",
      Geometry: {
        WGS84: "POINT (18.06044409048147 59.40549795979893)",
      },
      LocationSignature: "xBvv",
      OfficialLocationName: "Bråvallavägen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bällsta",
      Geometry: {
        WGS84: "POINT (18.072075530118497 59.52289946609442)",
      },
      LocationSignature: "xBäl",
      OfficialLocationName: "Bällsta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bläse kalkbrott",
      Geometry: {
        WGS84: "POINT (18.858116427933773 57.903523976068634)",
      },
      LocationSignature: "xBäo",
      OfficialLocationName: "Bläse kalkbrott",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Bläse kalkbruk",
      Geometry: {
        WGS84: "POINT (18.839955072775176 57.89461650542081)",
      },
      LocationSignature: "xBäu",
      OfficialLocationName: "Bläse kalkbruk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brösarp",
      Geometry: {
        WGS84: "POINT (14.125331508124054 55.73504566032526)",
      },
      LocationSignature: "xBöp",
      OfficialLocationName: "Brösarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Brösarp södra",
      Geometry: {
        WGS84: "POINT (14.126037896459557 55.731941628588196)",
      },
      LocationSignature: "xBös",
      OfficialLocationName: "Brösarp södra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Dirhuvden",
      Geometry: {
        WGS84: "POINT (12.450878204416583 58.00161741781681)",
      },
      LocationSignature: "xDir",
      OfficialLocationName: "Dirhuvden",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Djursholms Ekeby",
      Geometry: {
        WGS84: "POINT (18.057576876345586 59.41298784568663)",
      },
      LocationSignature: "xDje",
      OfficialLocationName: "Djursholms Ekeby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Djursholms Ösby",
      Geometry: {
        WGS84: "POINT (18.057816910970494 59.39688784270378)",
      },
      LocationSignature: "xDjö",
      OfficialLocationName: "Djursholms Ösby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Dala kvarn",
      Geometry: {
        WGS84: "POINT (13.44187814098671 58.46395373493309)",
      },
      LocationSignature: "xDkn",
      OfficialLocationName: "Dala kvarn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Delsbo",
      Geometry: {
        WGS84: "POINT (16.554852663970188 61.7976518296692)",
      },
      LocationSignature: "xDo",
      OfficialLocationName: "Delsbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ekskogen",
      Geometry: {
        WGS84: "POINT (18.226243736528897 59.639050979118394)",
      },
      LocationSignature: "xEkg",
      OfficialLocationName: "Ekskogen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ekebohult",
      Geometry: {
        WGS84: "POINT (14.306377784408138 57.19060704465562)",
      },
      LocationSignature: "xEkh",
      OfficialLocationName: "Ekebohult",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Eken",
      Geometry: {
        WGS84: "POINT (18.52388033765994 57.539956467976495)",
      },
      LocationSignature: "xEkn",
      OfficialLocationName: "Eken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Enebyberg",
      Geometry: {
        WGS84: "POINT (18.051087404118764 59.42589122358702)",
      },
      LocationSignature: "xEnb",
      OfficialLocationName: "Enebyberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ervalla NBVJ",
      Geometry: {
        WGS84: "POINT (15.247654109647817 59.426322622345715)",
      },
      LocationSignature: "xErn",
      OfficialLocationName: "Ervalla NBVJ",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ensta",
      Geometry: {
        WGS84: "POINT (18.06364723830495 59.45205241880546)",
      },
      LocationSignature: "xEsa",
      OfficialLocationName: "Ensta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Erstaviksbadet",
      Geometry: {
        WGS84: "POINT (18.28506986875821 59.27294412122426)",
      },
      LocationSignature: "xEvb",
      OfficialLocationName: "Erstaviksbadet",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Elgaryd",
      Geometry: {
        WGS84: "POINT (14.278113529822166 57.17885898535548)",
      },
      LocationSignature: "xEyd",
      OfficialLocationName: "Elgaryd",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Faringe",
      Geometry: {
        WGS84: "POINT (18.114658699900897 59.920006289693745)",
      },
      LocationSignature: "xF",
      OfficialLocationName: "Faringe",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Funbo",
      Geometry: {
        WGS84: "POINT (17.858906554137764 59.84949391758602)",
      },
      LocationSignature: "xFb",
      OfficialLocationName: "Funbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fagerrör",
      Geometry: {
        WGS84: "POINT (17.11117418092352 57.31263565346643)",
      },
      LocationSignature: "xFgr",
      OfficialLocationName: "Fagerrör",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Finkelboda",
      Geometry: {
        WGS84: "POINT (16.432968327153315 60.85995571438783)",
      },
      LocationSignature: "xFia",
      OfficialLocationName: "Finkelboda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fiskåsen",
      Geometry: {
        WGS84: "POINT (17.126287225305646 57.318419350276066)",
      },
      LocationSignature: "xFie",
      OfficialLocationName: "Fiskåsen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fyrislund",
      Geometry: {
        WGS84: "POINT (17.68210280746533 59.85842702610516)",
      },
      LocationSignature: "xFl",
      OfficialLocationName: "Fyrislund",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Flaten",
      Geometry: {
        WGS84: "POINT (15.671944539667296 57.36532520295694)",
      },
      LocationSignature: "xFla",
      OfficialLocationName: "Flaten",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Forsmöllan",
      Geometry: {
        WGS84: "POINT (13.181581395009614 56.11725933271403)",
      },
      LocationSignature: "xFmö",
      OfficialLocationName: "Forsmöllan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fredriksfors",
      Geometry: {
        WGS84: "POINT (16.620318578057756 61.7857343060372)",
      },
      LocationSignature: "xFos",
      OfficialLocationName: "Fredriksfors",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Forsa",
      Geometry: {
        WGS84: "POINT (16.945595482051544 61.734437626498334)",
      },
      LocationSignature: "xFsa",
      OfficialLocationName: "Forsa",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fagersand",
      Geometry: {
        WGS84: "POINT (16.28939490890582 57.69560729014491)",
      },
      LocationSignature: "xFsd",
      OfficialLocationName: "Fagersand",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Frösunda",
      Geometry: {
        WGS84: "POINT (18.170307313549305 59.624097014229065)",
      },
      LocationSignature: "xFsu",
      OfficialLocationName: "Frösunda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fisksätra",
      Geometry: {
        WGS84: "POINT (18.25601484493628 59.294120504032634)",
      },
      LocationSignature: "xFsä",
      OfficialLocationName: "Fisksätra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Fårhult",
      Geometry: {
        WGS84: "POINT (16.420080270411653 57.737707369910865)",
      },
      LocationSignature: "xFåt",
      OfficialLocationName: "Fårhult",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gunsta",
      Geometry: {
        WGS84: "POINT (17.834203816484763 59.852795645294066)",
      },
      LocationSignature: "xGa",
      OfficialLocationName: "Gunsta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gimarpsby",
      Geometry: {
        WGS84: "POINT (14.26110650292449 57.165715922027246)",
      },
      LocationSignature: "xGby",
      OfficialLocationName: "Gimarpsby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gripsholmsviken",
      Geometry: {
        WGS84: "POINT (17.20785999454085 59.257613717601636)",
      },
      LocationSignature: "xGhn",
      OfficialLocationName: "Gripsholmsviken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gimarp",
      Geometry: {
        WGS84: "POINT (14.27112512240911 57.17421903449605)",
      },
      LocationSignature: "xGim",
      OfficialLocationName: "Gimarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gnötteln",
      Geometry: {
        WGS84: "POINT (15.89667336755707 57.52005530261805)",
      },
      LocationSignature: "xGnl",
      OfficialLocationName: "Gnötteln",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Galoppfältet",
      Geometry: {
        WGS84: "POINT (18.084438120209207 59.446818060645725)",
      },
      LocationSignature: "xGpf",
      OfficialLocationName: "Galoppfältet",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gräfsnäs",
      Geometry: {
        WGS84: "POINT (12.490459181415128 58.08051805232108)",
      },
      LocationSignature: "xGr",
      OfficialLocationName: "Gräfsnäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gudå",
      Geometry: {
        WGS84: "POINT (12.097409536169001 63.31580784000923)",
      },
      LocationSignature: "Xgu",
      OfficialLocationName: "Gudå",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gyllebosjö",
      Geometry: {
        WGS84: "POINT (14.211361581188745 55.59753506920245)",
      },
      LocationSignature: "xGyb",
      OfficialLocationName: "Gyllebosjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gyttorp",
      Geometry: {
        WGS84: "POINT (14.971226414337908 59.50166298232147)",
      },
      LocationSignature: "xGyt",
      OfficialLocationName: "Gyttorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Gårdveda",
      Geometry: {
        WGS84: "POINT (15.754835549847648 57.38220225500932)",
      },
      LocationSignature: "xGåv",
      OfficialLocationName: "Gårdveda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hultarp",
      Geometry: {
        WGS84: "POINT (15.610979728013083 57.343294596861725)",
      },
      LocationSignature: "xHap",
      OfficialLocationName: "Hultarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hybo",
      Geometry: {
        WGS84: "POINT (16.192716031497113 61.796497886107204)",
      },
      LocationSignature: "xHbo",
      OfficialLocationName: "Hybo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hedlandet",
      Geometry: {
        WGS84: "POINT (17.232236307193496 59.24568090116602)",
      },
      LocationSignature: "xHdt",
      OfficialLocationName: "Hedlandet",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Henriksdal",
      Geometry: {
        WGS84: "POINT (18.108213766779954 59.3122566315866)",
      },
      LocationSignature: "xHed",
      OfficialLocationName: "Henriksdal",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Hell",
      Geometry: {
        WGS84: "POINT (10.976086984843768 63.40380375151755)",
      },
      LocationSignature: "Xhel",
      OfficialLocationName: "Hell",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hultsfreds Hembygdspark",
      Geometry: {
        WGS84: "POINT (15.86188284669933 57.496141865700196)",
      },
      LocationSignature: "xHfh",
      OfficialLocationName: "Hultsfreds Hembygdspark",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hultsfred smalspår (xHfp)",
      Geometry: {
        WGS84: "POINT (15.850756384163192 57.48117949710855)",
      },
      LocationSignature: "xHfp",
      OfficialLocationName: "Hultsfred smalspår (xHfp)",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hägernäs",
      Geometry: {
        WGS84: "POINT (18.124824565553563 59.450934820674334)",
      },
      LocationSignature: "xHgä",
      OfficialLocationName: "Hägernäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hjorthagen",
      Geometry: {
        WGS84: "POINT (17.201992968372984 59.256680040461546)",
      },
      LocationSignature: "xHhn",
      OfficialLocationName: "Hjorthagen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hjortöström",
      Geometry: {
        WGS84: "POINT (15.55073145708429 57.31208655592484)",
      },
      LocationSignature: "xHjm",
      OfficialLocationName: "Hjortöström",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hjorted",
      Geometry: {
        WGS84: "POINT (16.243892252964947 57.662743120826086)",
      },
      LocationSignature: "xHjt",
      OfficialLocationName: "Hjorted",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hudiksvall (xHkl)",
      Geometry: {
        WGS84: "POINT (17.083419579006552 61.70391826766832)",
      },
      LocationSignature: "xHkl",
      OfficialLocationName: "Hudiksvall (xHkl)",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hegra",
      Geometry: {
        WGS84: "POINT (12.097409536169001 63.31580784000923)",
      },
      LocationSignature: "Xhra",
      OfficialLocationName: "Hegra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hesselby",
      Geometry: {
        WGS84: "POINT (18.531221822673114 57.54447084498816)",
      },
      LocationSignature: "xHy",
      OfficialLocationName: "Hesselby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hultanäs",
      Geometry: {
        WGS84: "POINT (15.43869906894383 57.21507717139209)",
      },
      LocationSignature: "xHäs",
      OfficialLocationName: "Hultanäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Härnön",
      Geometry: {
        WGS84: "POINT (17.288701123568067 59.24455597334636)",
      },
      LocationSignature: "xHön",
      OfficialLocationName: "Härnön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hösjömo",
      Geometry: {
        WGS84: "POINT (14.228342654015272 57.15095634639774)",
      },
      LocationSignature: "xHöo",
      OfficialLocationName: "Hösjömo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Igelboda",
      Geometry: {
        WGS84: "POINT (18.275430036381575 59.28993051375122)",
      },
      LocationSignature: "xIgb",
      OfficialLocationName: "Igelboda",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Impregneringen",
      Geometry: {
        WGS84: "POINT (14.321887446535548 57.19121280897813)",
      },
      LocationSignature: "xIpr",
      OfficialLocationName: "Impregneringen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Järle",
      Geometry: {
        WGS84: "POINT (15.151242964604952 59.46423990767993)",
      },
      LocationSignature: "xJ",
      OfficialLocationName: "Järle",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Jagbacken",
      Geometry: {
        WGS84: "POINT (17.18400239656597 59.24803578067138)",
      },
      LocationSignature: "xJbn",
      OfficialLocationName: "Jagbacken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Jädraås",
      Geometry: {
        WGS84: "POINT (16.468261096740488 60.84227119121279)",
      },
      LocationSignature: "xJås",
      OfficialLocationName: "Jädraås",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Katterat",
      Geometry: {
        WGS84: "POINT (17.96585545435336 68.39792796062905)",
      },
      LocationSignature: "Xkat",
      OfficialLocationName: "Katterat",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kragstalund",
      Geometry: {
        WGS84: "POINT (18.07573843340895 59.51030452529331)",
      },
      LocationSignature: "xKgl",
      OfficialLocationName: "Kragstalund",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Källarhalsen",
      Geometry: {
        WGS84: "POINT (15.065455007779068 59.48975132293166)",
      },
      LocationSignature: "xKh",
      OfficialLocationName: "Källarhalsen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kambshagtorp",
      Geometry: {
        WGS84: "POINT (18.50210211731635 57.52015615471273)",
      },
      LocationSignature: "xKht",
      OfficialLocationName: "Kambshagtorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kopperå",
      Geometry: {
        WGS84: "POINT (12.03564999635043 63.3495841748434)",
      },
      LocationSignature: "xKpr",
      OfficialLocationName: "Kopperå",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kårsta",
      Geometry: {
        WGS84: "POINT (18.267415831880122 59.65667839685008)",
      },
      LocationSignature: "xKår",
      OfficialLocationName: "Kårsta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Löth",
      Geometry: {
        WGS84: "POINT (15.222766304686532 59.43399153171748)",
      },
      LocationSignature: "xL",
      OfficialLocationName: "Löth",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Långbacka",
      Geometry: {
        WGS84: "POINT (16.451540567509696 61.80126068740835)",
      },
      LocationSignature: "xLba",
      OfficialLocationName: "Långbacka",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Länna bruk",
      Geometry: {
        WGS84: "POINT (17.9707806436961 59.87719770393878)",
      },
      LocationSignature: "xLbr",
      OfficialLocationName: "Länna bruk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lindholmen",
      Geometry: {
        WGS84: "POINT (18.109357019599226 59.584275202441916)",
      },
      LocationSignature: "xLdm",
      OfficialLocationName: "Lindholmen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Läggesta nedre",
      Geometry: {
        WGS84: "POINT (17.174168067621 59.24617924200534)",
      },
      LocationSignature: "xLgn",
      OfficialLocationName: "Läggesta nedre",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lövstahagen",
      Geometry: {
        WGS84: "POINT (17.886946736212263 59.847255404116495)",
      },
      LocationSignature: "xLh",
      OfficialLocationName: "Lövstahagen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lahäll",
      Geometry: {
        WGS84: "POINT (18.074093088593884 59.42403923512258)",
      },
      LocationSignature: "xLhl",
      OfficialLocationName: "Lahäll",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lillängen",
      Geometry: {
        WGS84: "POINT (18.161444242312808 59.30512448060616)",
      },
      LocationSignature: "xLiä",
      OfficialLocationName: "Lillängen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ljungbyheds grusgrop",
      Geometry: {
        WGS84: "POINT (13.227972791339253 56.08610380813165)",
      },
      LocationSignature: "xLjd",
      OfficialLocationName: "Ljungbyheds grusgrop",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lilla Mon",
      Geometry: {
        WGS84: "POINT (15.123634971221357 59.47517066226475)",
      },
      LocationSignature: "xLm",
      OfficialLocationName: "Lilla Mon",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Länna",
      Geometry: {
        WGS84: "POINT (17.95870357923155 59.871835839420825)",
      },
      LocationSignature: "xLna",
      OfficialLocationName: "Länna",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Lundsbrunn",
      Geometry: {
        WGS84: "POINT (13.443123940678593 58.47222414212837)",
      },
      LocationSignature: "xLnb",
      OfficialLocationName: "Lundsbrunn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Långsjön",
      Geometry: {
        WGS84: "POINT (16.295450052615593 57.69697383684441)",
      },
      LocationSignature: "xLåö",
      OfficialLocationName: "Långsjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Länninge",
      Geometry: {
        WGS84: "POINT (17.13133280695664 57.32237747024148)",
      },
      LocationSignature: "xLäe",
      OfficialLocationName: "Länninge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Löt",
      Geometry: {
        WGS84: "POINT (17.943689544134624 59.86615226768196)",
      },
      LocationSignature: "xLöt",
      OfficialLocationName: "Löt",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Magnor",
      Geometry: {
        WGS84: "POINT (12.297534522161012 59.88351722261369)",
      },
      LocationSignature: "Xmag",
      OfficialLocationName: "Magnor",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Meråker",
      Geometry: {
        WGS84: "POINT (12.097409536169001 63.31580784000923)",
      },
      LocationSignature: "Xmer",
      OfficialLocationName: "Meråker",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mariefred",
      Geometry: {
        WGS84: "POINT (17.218934355225702 59.25876387594888)",
      },
      LocationSignature: "xMfd",
      OfficialLocationName: "Mariefred",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mariefreds ångbåtsstation",
      Geometry: {
        WGS84: "POINT (17.225598082708178 59.25737440878134)",
      },
      LocationSignature: "xMfå",
      OfficialLocationName: "Mariefreds ångbåtsstation",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Moga",
      Geometry: {
        WGS84: "POINT (18.07770945502647 59.895588431425814)",
      },
      LocationSignature: "xMg",
      OfficialLocationName: "Moga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Marielund",
      Geometry: {
        WGS84: "POINT (17.8749046825506 59.842837837390014)",
      },
      LocationSignature: "xMl",
      OfficialLocationName: "Marielund",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Marielund",
      Geometry: {
        WGS84: "POINT (17.186346447888777 59.25259591778546)",
      },
      LocationSignature: "xMln",
      OfficialLocationName: "Marielund",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Molnby",
      Geometry: {
        WGS84: "POINT (18.085207687052996 59.55742081910097)",
      },
      LocationSignature: "xMnb",
      OfficialLocationName: "Molnby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mosstorp",
      Geometry: {
        WGS84: "POINT (15.535216809736863 57.28859053580462)",
      },
      LocationSignature: "xMop",
      OfficialLocationName: "Mosstorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Målilla Sanatorium",
      Geometry: {
        WGS84: "POINT (15.778387088483424 57.3884460093449)",
      },
      LocationSignature: "xMsa",
      OfficialLocationName: "Målilla Sanatorium",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Munkebos",
      Geometry: {
        WGS84: "POINT (18.51976518301805 57.535945016647446)",
      },
      LocationSignature: "xMus",
      OfficialLocationName: "Munkebos",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Myråsen",
      Geometry: {
        WGS84: "POINT (13.438299542754198 58.44336626969083)",
      },
      LocationSignature: "xMyå",
      OfficialLocationName: "Myråsen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Mörby",
      Geometry: {
        WGS84: "POINT (18.047185484418566 59.39211340077091)",
      },
      LocationSignature: "xMöb",
      OfficialLocationName: "Mörby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nora stad",
      Geometry: {
        WGS84: "POINT (15.042039186412211 59.51617995348818)",
      },
      LocationSignature: "xN",
      OfficialLocationName: "Nora stad",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Näsbyallé",
      Geometry: {
        WGS84: "POINT (18.085168459974835 59.4273364078845)",
      },
      LocationSignature: "xNbe",
      OfficialLocationName: "Näsbyallé",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Näsbypark",
      Geometry: {
        WGS84: "POINT (18.09540968647935 59.430167197571265)",
      },
      LocationSignature: "xNbp",
      OfficialLocationName: "Näsbypark",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Neglinge",
      Geometry: {
        WGS84: "POINT (18.29217669854955 59.288528260593466)",
      },
      LocationSignature: "xNeg",
      OfficialLocationName: "Neglinge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Narvik",
      Geometry: {
        WGS84: "POINT (17.386687780396514 68.43669216907497)",
      },
      LocationSignature: "Xnk",
      OfficialLocationName: "Narvik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nacka",
      Geometry: {
        WGS84: "POINT (18.130172547252563 59.3065721897222)",
      },
      LocationSignature: "xNka",
      OfficialLocationName: "Nacka",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Näsviken",
      Geometry: {
        WGS84: "POINT (16.870426270920632 61.75623540208702)",
      },
      LocationSignature: "xNv",
      OfficialLocationName: "Näsviken",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nygårds Stall Dalhem",
      Geometry: {
        WGS84: "POINT (18.51300281495658 57.529427122763735)",
      },
      LocationSignature: "xNys",
      OfficialLocationName: "Nygårds Stall Dalhem",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ohs Bruk",
      Geometry: {
        WGS84: "POINT (14.331174043381088 57.19283472644888)",
      },
      LocationSignature: "xOhs",
      OfficialLocationName: "Ohs Bruk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ormsta",
      Geometry: {
        WGS84: "POINT (18.079438889749383 59.546216172473514)",
      },
      LocationSignature: "xOta",
      OfficialLocationName: "Ormsta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Oxberg",
      Geometry: {
        WGS84: "POINT (14.17254403843354 61.12986242299123)",
      },
      LocationSignature: "xOxb",
      OfficialLocationName: "Oxberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Pershyttevägen",
      Geometry: {
        WGS84: "POINT (15.016747357269125 59.50667588441735)",
      },
      LocationSignature: "xPgn",
      OfficialLocationName: "Pershyttevägen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Pershyttan",
      Geometry: {
        WGS84: "POINT (15.007063891912594 59.494652613011944)",
      },
      LocationSignature: "xPh",
      OfficialLocationName: "Pershyttan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Pallanite",
      Geometry: {
        WGS84: "POINT (16.443480671331276 60.853009806838045)",
      },
      LocationSignature: "xPte",
      OfficialLocationName: "Pallanite",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kvarnabo",
      Geometry: {
        WGS84: "POINT (12.475060700442278 58.05596998456012)",
      },
      LocationSignature: "xQo",
      OfficialLocationName: "Kvarnabo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Roma",
      Geometry: {
        WGS84: "POINT (18.4568714513221 57.50796484394022)",
      },
      LocationSignature: "xRa",
      OfficialLocationName: "Roma",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ravlundabro",
      Geometry: {
        WGS84: "POINT (14.149018022722705 55.70919835698911)",
      },
      LocationSignature: "xRav",
      OfficialLocationName: "Ravlundabro",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ravlunda bränneri",
      Geometry: {
        WGS84: "POINT (14.152377074144669 55.70768502465267)",
      },
      LocationSignature: "xRaä",
      OfficialLocationName: "Ravlunda bränneri",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rydbo",
      Geometry: {
        WGS84: "POINT (18.18478441087183 59.465410150469886)",
      },
      LocationSignature: "xRdb",
      OfficialLocationName: "Rydbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ranheim",
      Geometry: {
        WGS84: "POINT (12.097409536169001 63.31580784000923)",
      },
      LocationSignature: "Xrhm",
      OfficialLocationName: "Ranheim",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Roslags Näsby",
      Geometry: {
        WGS84: "POINT (18.057934324630494 59.43570564946557)",
      },
      LocationSignature: "xRnb",
      OfficialLocationName: "Roslags Näsby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Roma kungsgård",
      Geometry: {
        WGS84: "POINT (18.468528270418187 57.51269685859589)",
      },
      LocationSignature: "xRok",
      OfficialLocationName: "Roma kungsgård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rombakk",
      Geometry: {
        WGS84: "POINT (17.790981037982274 68.40461174157639)",
      },
      LocationSignature: "Xrom",
      OfficialLocationName: "Rombakk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ringvägen",
      Geometry: {
        WGS84: "POINT (18.30219542991483 59.28315156796585)",
      },
      LocationSignature: "xRvä",
      OfficialLocationName: "Ringvägen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skölsta",
      Geometry: {
        WGS84: "POINT (17.744099388332536 59.87443537007575)",
      },
      LocationSignature: "xSa",
      OfficialLocationName: "Skölsta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skara camping",
      Geometry: {
        WGS84: "POINT (13.456504337730943 58.38640134505134)",
      },
      LocationSignature: "xSac",
      OfficialLocationName: "Skara camping",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Saltsjöbaden",
      Geometry: {
        WGS84: "POINT (18.313033766495227 59.27901141961996)",
      },
      LocationSignature: "xSbn",
      OfficialLocationName: "Saltsjöbaden",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Scoutstugan",
      Geometry: {
        WGS84: "POINT (14.13255970532887 55.64101581077929)",
      },
      LocationSignature: "xScs",
      OfficialLocationName: "Scoutstugan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Saltsjö-Duvnäs",
      Geometry: {
        WGS84: "POINT (18.19864428848485 59.300584934518255)",
      },
      LocationSignature: "xSdn",
      OfficialLocationName: "Saltsjö-Duvnäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sandudden",
      Geometry: {
        WGS84: "POINT (16.70176454303342 61.78225269944235)",
      },
      LocationSignature: "xSdu",
      OfficialLocationName: "Sandudden",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sickla",
      Geometry: {
        WGS84: "POINT (18.121164786583147 59.306851383599756)",
      },
      LocationSignature: "xSik",
      OfficialLocationName: "Sickla",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Saltsjö-Järla",
      Geometry: {
        WGS84: "POINT (18.14965748537283 59.30689302385171)",
      },
      LocationSignature: "xSjl",
      OfficialLocationName: "Saltsjö-Järla",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sjövägen",
      Geometry: {
        WGS84: "POINT (14.175121266489853 57.13153644573291)",
      },
      LocationSignature: "xSjv",
      OfficialLocationName: "Sjövägen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Solsidan",
      Geometry: {
        WGS84: "POINT (18.29607324729065 59.27088626666877)",
      },
      LocationSignature: "xSln",
      OfficialLocationName: "Solsidan",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Slussen",
      Geometry: {
        WGS84: "POINT (18.07333110181467 59.31974366786051)",
      },
      LocationSignature: "xSlu",
      OfficialLocationName: "Slussen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Selknä",
      Geometry: {
        WGS84: "POINT (17.908774109095056 59.85549931353879)",
      },
      LocationSignature: "xSlä",
      OfficialLocationName: "Selknä",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stora Mon",
      Geometry: {
        WGS84: "POINT (15.085300168630395 59.48216976466724)",
      },
      LocationSignature: "xSm",
      OfficialLocationName: "Stora Mon",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skogsmark",
      Geometry: {
        WGS84: "POINT (13.440242266886367 58.45328011869464)",
      },
      LocationSignature: "xSmk",
      OfficialLocationName: "Skogsmark",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stensjön",
      Geometry: {
        WGS84: "POINT (14.201809009935975 57.143486668503556)",
      },
      LocationSignature: "xSn",
      OfficialLocationName: "Stensjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "S:t Olof",
      Geometry: {
        WGS84: "POINT (14.133801921003823 55.63512088343599)",
      },
      LocationSignature: "xSoo",
      OfficialLocationName: "S:t Olof",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Spångenäs",
      Geometry: {
        WGS84: "POINT (16.1007976219144 57.599739780502816)",
      },
      LocationSignature: "xSpä",
      OfficialLocationName: "Spångenäs",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stocksund",
      Geometry: {
        WGS84: "POINT (18.043772668457464 59.3849052920553)",
      },
      LocationSignature: "xSsu",
      OfficialLocationName: "Stocksund",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Storängen",
      Geometry: {
        WGS84: "POINT (18.17795432262495 59.30558518054642)",
      },
      LocationSignature: "xStä",
      OfficialLocationName: "Storängen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Svartbäcken Nedre",
      Geometry: {
        WGS84: "POINT (16.38414102332344 60.87439430956915)",
      },
      LocationSignature: "xSvb",
      OfficialLocationName: "Svartbäcken Nedre",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Sjöbygget",
      Geometry: {
        WGS84: "POINT (17.24957384488916 59.24478033749007)",
      },
      LocationSignature: "xSöy",
      OfficialLocationName: "Sjöbygget",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tattby",
      Geometry: {
        WGS84: "POINT (18.281899946391484 59.279045636673196)",
      },
      LocationSignature: "xTab",
      OfficialLocationName: "Tattby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tibble",
      Geometry: {
        WGS84: "POINT (18.062653782067116 59.44224479479556)",
      },
      LocationSignature: "xTbl",
      OfficialLocationName: "Tibble",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tule",
      Geometry: {
        WGS84: "POINT (18.506695704416128 57.523273815042735)",
      },
      LocationSignature: "xTe",
      OfficialLocationName: "Tule",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tule grusgrop",
      Geometry: {
        WGS84: "POINT (18.509278865173027 57.52580900849313)",
      },
      LocationSignature: "xTeg",
      OfficialLocationName: "Tule grusgrop",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Triabo",
      Geometry: {
        WGS84: "POINT (15.535529723253855 57.237016238079796)",
      },
      LocationSignature: "xTib",
      OfficialLocationName: "Triabo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tippen",
      Geometry: {
        WGS84: "POINT (18.277223624061218 59.28410653085103)",
      },
      LocationSignature: "xTip",
      OfficialLocationName: "Tippen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tuna",
      Geometry: {
        WGS84: "POINT (16.10385552433655 57.576958281975855)",
      },
      LocationSignature: "xTna",
      OfficialLocationName: "Tuna",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Trondheim",
      Geometry: {
        WGS84: "POINT (10.922988176949668 63.45729648117318)",
      },
      LocationSignature: "Xtnd",
      OfficialLocationName: "Trondheim",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Totebo",
      Geometry: {
        WGS84: "POINT (16.180910950093736 57.62799710379302)",
      },
      LocationSignature: "xTob",
      OfficialLocationName: "Totebo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tomtskog",
      Geometry: {
        WGS84: "POINT (17.126103150657087 57.33334753993497)",
      },
      LocationSignature: "xTog",
      OfficialLocationName: "Tomtskog",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Torpa",
      Geometry: {
        WGS84: "POINT (15.170058497470597 59.45161780122982)",
      },
      LocationSignature: "xTp",
      OfficialLocationName: "Torpa",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Trollskogen",
      Geometry: {
        WGS84: "POINT (17.12027727436195 57.341523898124294)",
      },
      LocationSignature: "xTsk",
      OfficialLocationName: "Trollskogen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tunagård",
      Geometry: {
        WGS84: "POINT (18.307335802628376 59.46869920972795)",
      },
      LocationSignature: "xTug",
      OfficialLocationName: "Tunagård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tveta",
      Geometry: {
        WGS84: "POINT (13.430897323337012 58.41297633689919)",
      },
      LocationSignature: "xTve",
      OfficialLocationName: "Tveta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Taxinge-Näsby",
      Geometry: {
        WGS84: "POINT (17.300654057314397 59.24080551593884)",
      },
      LocationSignature: "xTx",
      OfficialLocationName: "Taxinge-Näsby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tallås",
      Geometry: {
        WGS84: "POINT (16.409214257398137 60.869482586199005)",
      },
      LocationSignature: "xTås",
      OfficialLocationName: "Tallås",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Täby kyrkby",
      Geometry: {
        WGS84: "POINT (18.066293874857447 59.492940479861694)",
      },
      LocationSignature: "xTäb",
      OfficialLocationName: "Täby kyrkby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Täby Centrum",
      Geometry: {
        WGS84: "POINT (18.074788735664004 59.44433186169102)",
      },
      LocationSignature: "xTäc",
      OfficialLocationName: "Täby Centrum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Täljö",
      Geometry: {
        WGS84: "POINT (18.23534063178873 59.47318107172185)",
      },
      LocationSignature: "xTäl",
      OfficialLocationName: "Täljö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Universitetet",
      Geometry: {
        WGS84: "POINT (18.05137233663527 59.36481410690195)",
      },
      LocationSignature: "xUnv",
      OfficialLocationName: "Universitetet",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Uppsala Östra",
      Geometry: {
        WGS84: "POINT (17.64946062121286 59.857866265083686)",
      },
      LocationSignature: "xUö",
      OfficialLocationName: "Uppsala Östra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Valstad",
      Geometry: {
        WGS84: "POINT (16.483734075736763 57.73544149936179)",
      },
      LocationSignature: "xVas",
      OfficialLocationName: "Valstad",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Viggbyholm",
      Geometry: {
        WGS84: "POINT (18.104164877840077 59.44928897289631)",
      },
      LocationSignature: "xVbm",
      OfficialLocationName: "Viggbyholm",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Verkebäck",
      Geometry: {
        WGS84: "POINT (16.51576846284091 57.72869266989044)",
      },
      LocationSignature: "xVbä",
      OfficialLocationName: "Verkebäck",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vedalund",
      Geometry: {
        WGS84: "POINT (18.09849244082728 59.568267756760406)",
      },
      LocationSignature: "xVdl",
      OfficialLocationName: "Vedalund",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vena",
      Geometry: {
        WGS84: "POINT (15.970531743884132 57.524279131183896)",
      },
      LocationSignature: "xVen",
      OfficialLocationName: "Vena",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vendevägen",
      Geometry: {
        WGS84: "POINT (18.067687906277655 59.3998461453414)",
      },
      LocationSignature: "xVev",
      OfficialLocationName: "Vendevägen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Värdshuset",
      Geometry: {
        WGS84: "POINT (17.221912298683208 59.25788657615996)",
      },
      LocationSignature: "xVht",
      OfficialLocationName: "Värdshuset",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Virestad",
      Geometry: {
        WGS84: "POINT (14.20846758144333 55.565490786170955)",
      },
      LocationSignature: "xVir",
      OfficialLocationName: "Virestad",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Visinge",
      Geometry: {
        WGS84: "POINT (18.063063842676154 59.462571000760754)",
      },
      LocationSignature: "xVis",
      OfficialLocationName: "Visinge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vallentuna",
      Geometry: {
        WGS84: "POINT (18.080036678438514 59.53416057661871)",
      },
      LocationSignature: "xVlt",
      OfficialLocationName: "Vallentuna",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Virserum",
      Geometry: {
        WGS84: "POINT (15.577482699014372 57.321003723968026)",
      },
      LocationSignature: "xVsm",
      OfficialLocationName: "Virserum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vadstena",
      Geometry: {
        WGS84: "POINT (14.884146125384978 58.444299863032604)",
      },
      LocationSignature: "xVst",
      OfficialLocationName: "Vadstena",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vitaby kyrka",
      Geometry: {
        WGS84: "POINT (14.166670918081085 55.69410655921058)",
      },
      LocationSignature: "xVtk",
      OfficialLocationName: "Vitaby kyrka",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vitaby",
      Geometry: {
        WGS84: "POINT (14.155501903984003 55.68455028155498)",
      },
      LocationSignature: "xVty",
      OfficialLocationName: "Vitaby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Väderum",
      Geometry: {
        WGS84: "POINT (16.069502755387756 57.536873603983565)",
      },
      LocationSignature: "xVäm",
      OfficialLocationName: "Väderum",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åseda (xÅa)",
      Geometry: {
        WGS84: "POINT (15.347998366508474 57.16757014800069)",
      },
      LocationSignature: "xÅa",
      OfficialLocationName: "Åseda (xÅa)",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åkersberga",
      Geometry: {
        WGS84: "POINT (18.299146775379395 59.47907091032556)",
      },
      LocationSignature: "xÅbg",
      OfficialLocationName: "Åkersberga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åbo",
      Geometry: {
        WGS84: "POINT (16.45304797326552 60.84845285653513)",
      },
      LocationSignature: "xÅbo",
      OfficialLocationName: "Åbo",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åkers Runö",
      Geometry: {
        WGS84: "POINT (18.27025740560182 59.480651297017744)",
      },
      LocationSignature: "xÅru",
      OfficialLocationName: "Åkers Runö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Årsta",
      Geometry: {
        WGS84: "POINT (17.702666420281403 59.864205632680964)",
      },
      LocationSignature: "xÅs",
      OfficialLocationName: "Årsta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åtorp",
      Geometry: {
        WGS84: "POINT (11.683837085076481 58.45850367798175)",
      },
      LocationSignature: "xÅtp",
      OfficialLocationName: "Åtorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Älvstorp",
      Geometry: {
        WGS84: "POINT (15.034877293498333 59.511180236294564)",
      },
      LocationSignature: "xÄp",
      OfficialLocationName: "Älvstorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ödhult",
      Geometry: {
        WGS84: "POINT (15.832165677807446 57.43813012773391)",
      },
      LocationSignature: "xÖdh",
      OfficialLocationName: "Ödhult",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Östberga",
      Geometry: {
        WGS84: "POINT (18.073931444756095 59.403985604310876)",
      },
      LocationSignature: "xÖga",
      OfficialLocationName: "Östberga",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Österskär",
      Geometry: {
        WGS84: "POINT (18.311047418607 59.46133444391295)",
      },
      LocationSignature: "xÖsk",
      OfficialLocationName: "Österskär",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Stockholms östra",
      Geometry: {
        WGS84: "POINT (18.07032540786386 59.34662991319712)",
      },
      LocationSignature: "xÖst",
      OfficialLocationName: "Stockholms östra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Östervik",
      Geometry: {
        WGS84: "POINT (18.235536826198125 59.29512555145722)",
      },
      LocationSignature: "xÖsv",
      OfficialLocationName: "Östervik",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Östra Vemmerlöv",
      Geometry: {
        WGS84: "POINT (14.22829570806205 55.586167884098046)",
      },
      LocationSignature: "xÖvl",
      OfficialLocationName: "Östra Vemmerlöv",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ystad",
      Geometry: {
        WGS84: "POINT (13.825460699219333 55.427372002991056)",
      },
      LocationSignature: "Y",
      OfficialLocationName: "Ystad",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ytterby",
      Geometry: {
        WGS84: "POINT (11.917445420699801 57.86230188185646)",
      },
      LocationSignature: "Yb",
      OfficialLocationName: "Ytterby",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ytterhogdal",
      Geometry: {
        WGS84: "POINT (14.855394844494286 62.197288513778865)",
      },
      LocationSignature: "Yl",
      OfficialLocationName: "Ytterhogdal",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Yttermalung",
      Geometry: {
        WGS84: "POINT (13.824086592717391 60.57996136782678)",
      },
      LocationSignature: "Ym",
      OfficialLocationName: "Yttermalung",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Yttersjön",
      Geometry: {
        WGS84: "POINT (19.659404346793703 64.41037350974898)",
      },
      LocationSignature: "Yö",
      OfficialLocationName: "Yttersjön",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Copenhagen Airport",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zcph",
      OfficialLocationName: "Copenhagen Airport",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Drogden",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zdro",
      OfficialLocationName: "Drogden",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Espergaerde",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zgae",
      OfficialLocationName: "Espergaerde",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Helsingör",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zhg",
      OfficialLocationName: "Helsingör",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Hellerup",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zhl",
      OfficialLocationName: "Hellerup",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Humlebaek",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zhum",
      OfficialLocationName: "Humlebaek",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Österport",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zkk",
      OfficialLocationName: "Österport",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Klampenborg",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zkl",
      OfficialLocationName: "Klampenborg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kalvebod",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zklv",
      OfficialLocationName: "Kalvebod",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nörreport",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zkn",
      OfficialLocationName: "Nörreport",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "f.d. Köpingebro",
      Geometry: {
        WGS84: "POINT (13.936188120894993 55.45727591212147)",
      },
      LocationSignature: "zKöp",
      OfficialLocationName: "f.d. Köpingebro",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Nivå",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zni",
      OfficialLocationName: "Nivå",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Kokkedal",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zok",
      OfficialLocationName: "Kokkedal",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Rungsted",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zru",
      OfficialLocationName: "Rungsted",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Snekkersten",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zsq",
      OfficialLocationName: "Snekkersten",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Skodsborg",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zså",
      OfficialLocationName: "Skodsborg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Tårnby",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Ztåt",
      OfficialLocationName: "Tårnby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Vedbaek",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zvb",
      OfficialLocationName: "Vedbaek",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Örestad",
      Geometry: {
        WGS84: "POINT (12.700128437886463 55.600231403463866)",
      },
      LocationSignature: "Zöre",
      OfficialLocationName: "Örestad",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åsbro",
      Geometry: {
        WGS84: "POINT (15.061177971911832 58.998180290882345)",
      },
      LocationSignature: "Å",
      OfficialLocationName: "Åsbro",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åseda",
      Geometry: {
        WGS84: "POINT (15.347852330103017 57.16786700219045)",
      },
      LocationSignature: "Åa",
      OfficialLocationName: "Åseda",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Åsensbruk",
      Geometry: {
        WGS84: "POINT (12.42556422201936 58.805352333360794)",
      },
      LocationSignature: "Åb",
      OfficialLocationName: "Åsensbruk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ålberga",
      Geometry: {
        WGS84: "POINT (16.57740141981295 58.724083639244775)",
      },
      LocationSignature: "Åba",
      OfficialLocationName: "Ålberga",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Årstaberg",
      Geometry: {
        WGS84: "POINT (18.027693219158454 59.2994166097003)",
      },
      LocationSignature: "Åbe",
      OfficialLocationName: "Årstaberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åby södra",
      Geometry: {
        WGS84: "POINT (16.180398683064023 58.65885604827068)",
      },
      LocationSignature: "Åbs",
      OfficialLocationName: "Åby södra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åby södra",
      Geometry: {
        WGS84: "POINT (16.180398683064023 58.65885604827068)",
      },
      LocationSignature: "Åby",
      OfficialLocationName: "Åby södra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åby godsbangård",
      Geometry: {
        WGS84: "POINT (16.196300096510253 58.6485326098994)",
      },
      LocationSignature: "Åbyg",
      OfficialLocationName: "Åby godsbangård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ådalsliden",
      Geometry: {
        WGS84: "POINT (16.879038511141722 63.43959396742716)",
      },
      LocationSignature: "Åd",
      OfficialLocationName: "Ådalsliden",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ånge",
      Geometry: {
        WGS84: "POINT (15.656838659985995 62.52371779619788)",
      },
      LocationSignature: "Åg",
      OfficialLocationName: "Ånge",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ånge godsbangård",
      Geometry: {
        WGS84: "POINT (15.642810127449046 62.525425668925166)",
      },
      LocationSignature: "Åggb",
      OfficialLocationName: "Ånge godsbangård",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ångebyn",
      Geometry: {
        WGS84: "POINT (15.609930877788354 62.52415477567653)",
      },
      LocationSignature: "Ågy",
      OfficialLocationName: "Ångebyn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åshammar",
      Geometry: {
        WGS84: "POINT (16.54788987029424 60.6485241554113)",
      },
      LocationSignature: "Åh",
      OfficialLocationName: "Åshammar",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åhus",
      Geometry: {
        WGS84: "POINT (14.300698801911857 55.927585710350364)",
      },
      LocationSignature: "Åhus",
      OfficialLocationName: "Åhus",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Åkarp",
      Geometry: {
        WGS84: "POINT (13.108224150276582 55.65729391186111)",
      },
      LocationSignature: "Åk",
      OfficialLocationName: "Åkarp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Årskogen",
      Geometry: {
        WGS84: "POINT (17.348409755439985 62.14377251770103)",
      },
      LocationSignature: "Åkg",
      OfficialLocationName: "Årskogen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åkers styckebruk",
      Geometry: {
        WGS84: "POINT (17.095204229938588 59.25225397190195)",
      },
      LocationSignature: "Åks",
      OfficialLocationName: "Åkers styckebruk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åskott",
      Geometry: {
        WGS84: "POINT (14.635213285650043 63.28017365353014)",
      },
      LocationSignature: "Åkt",
      OfficialLocationName: "Åskott",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Åmål",
      Geometry: {
        WGS84: "POINT (12.698337633501968 59.046439406816006)",
      },
      LocationSignature: "Ål",
      OfficialLocationName: "Åmål",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ålsäng",
      Geometry: {
        WGS84: "POINT (15.710231760563884 59.41573563157777)",
      },
      LocationSignature: "Ålg",
      OfficialLocationName: "Ålsäng",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åmsele",
      Geometry: {
        WGS84: "POINT (19.325027499935544 64.48866968053257)",
      },
      LocationSignature: "Åm",
      OfficialLocationName: "Åmsele",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ångsågsmossen",
      Geometry: {
        WGS84: "POINT (14.977476978948824 56.212366133102)",
      },
      LocationSignature: "Åmn",
      OfficialLocationName: "Ångsågsmossen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åmyran",
      Geometry: {
        WGS84: "POINT (17.257917230867687 61.98980049599768)",
      },
      LocationSignature: "Åmy",
      OfficialLocationName: "Åmyran",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ånimskog",
      Geometry: {
        WGS84: "POINT (12.568944927296426 58.877234675480686)",
      },
      LocationSignature: "Ånm",
      OfficialLocationName: "Ånimskog",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ånn",
      Geometry: {
        WGS84: "POINT (12.539021467159916 63.321352292118576)",
      },
      LocationSignature: "Ånn",
      OfficialLocationName: "Ånn",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Åstorp",
      Geometry: {
        WGS84: "POINT (12.951115223843647 56.13330497048648)",
      },
      LocationSignature: "Åp",
      OfficialLocationName: "Åstorp",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åryd",
      Geometry: {
        WGS84: "POINT (14.983453372011274 56.82079440432052)",
      },
      LocationSignature: "Ård",
      OfficialLocationName: "Åryd",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Åre",
      Geometry: {
        WGS84: "POINT (13.074856075628473 63.3993976740134)",
      },
      LocationSignature: "Åre",
      OfficialLocationName: "Åre",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ås",
      Geometry: {
        WGS84: "POINT (12.3424199728652 59.85732524152778)",
      },
      LocationSignature: "Ås",
      OfficialLocationName: "Ås",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Åsa",
      Geometry: {
        WGS84: "POINT (12.13507444082344 57.36429861176986)",
      },
      LocationSignature: "Åsa",
      OfficialLocationName: "Åsa",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Åsarna central",
      Geometry: {
        WGS84: "POINT (14.374511968718771 62.647139731088146)",
      },
      LocationSignature: "Åsal",
      OfficialLocationName: "Åsarna central",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Åsarna Södra",
      Geometry: {
        WGS84: "POINT (14.371992639994378 62.64081830216797)",
      },
      LocationSignature: "Åsra",
      OfficialLocationName: "Åsarna Södra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Åsträsk",
      Geometry: {
        WGS84: "POINT (19.97518855564272 64.6157222754548)",
      },
      LocationSignature: "Åst",
      OfficialLocationName: "Åsträsk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Åmotfors",
      Geometry: {
        WGS84: "POINT (12.366380780707596 59.76840496369997)",
      },
      LocationSignature: "Åt",
      OfficialLocationName: "Åmotfors",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Åtvidaberg",
      Geometry: {
        WGS84: "POINT (16.00206025522281 58.203647880652014)",
      },
      LocationSignature: "Åvg",
      OfficialLocationName: "Åtvidaberg",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ängelholm",
      Geometry: {
        WGS84: "POINT (12.853425440320063 56.24676111334818)",
      },
      LocationSignature: "Ä",
      OfficialLocationName: "Ängelholm",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Älvboda",
      Geometry: {
        WGS84: "POINT (17.40717064514242 60.618067203019024)",
      },
      LocationSignature: "Äba",
      OfficialLocationName: "Älvboda",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ängelsberg",
      Geometry: {
        WGS84: "POINT (16.009245690118707 59.95698077920842)",
      },
      LocationSignature: "Äbg",
      OfficialLocationName: "Ängelsberg",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ängelsbergs bruk",
      Geometry: {
        WGS84: "POINT (16.007249206492432 59.966128699400635)",
      },
      LocationSignature: "Äbgb",
      OfficialLocationName: "Ängelsbergs bruk",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Äggfors",
      Geometry: {
        WGS84: "POINT (13.707411814286063 63.29502662214578)",
      },
      LocationSignature: "Äfs",
      OfficialLocationName: "Äggfors",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Älmhult",
      Geometry: {
        WGS84: "POINT (14.136752817329516 56.54996921844647)",
      },
      LocationSignature: "Äh",
      OfficialLocationName: "Älmhult",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Älvkarleby",
      Geometry: {
        WGS84: "POINT (17.428060189184563 60.55552967413492)",
      },
      LocationSignature: "Äkb",
      OfficialLocationName: "Älvkarleby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Älgarås",
      Geometry: {
        WGS84: "POINT (14.25540874129622 58.81352227497875)",
      },
      LocationSignature: "Äl",
      OfficialLocationName: "Älgarås",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Älvho",
      Geometry: {
        WGS84: "POINT (14.747337466605702 61.49795539139601)",
      },
      LocationSignature: "Älo",
      OfficialLocationName: "Älvho",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Älvros",
      Geometry: {
        WGS84: "POINT (14.576326444452182 62.06289428642223)",
      },
      LocationSignature: "Äls",
      OfficialLocationName: "Älvros",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Älvängen",
      Geometry: {
        WGS84: "POINT (12.106636408564174 57.95440137131778)",
      },
      LocationSignature: "Än",
      OfficialLocationName: "Älvängen",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Äng",
      Geometry: {
        WGS84: "POINT (14.56682091811564 57.692351127247996)",
      },
      LocationSignature: "Äng",
      OfficialLocationName: "Äng",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ängersjö",
      Geometry: {
        WGS84: "POINT (19.804550997023345 63.60377099793241)",
      },
      LocationSignature: "Änö",
      OfficialLocationName: "Ängersjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Äppelbo",
      Geometry: {
        WGS84: "POINT (14.000614075352448 60.49095876494237)",
      },
      LocationSignature: "Äp",
      OfficialLocationName: "Äppelbo",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Älvsjö",
      Geometry: {
        WGS84: "POINT (18.01126835235629 59.27859023113897)",
      },
      LocationSignature: "Äs",
      OfficialLocationName: "Älvsjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Älandsbro",
      Geometry: {
        WGS84: "POINT (17.84473279295137 62.676401917769304)",
      },
      LocationSignature: "Äsb",
      OfficialLocationName: "Älandsbro",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Älvsjö godsbangård",
      Geometry: {
        WGS84: "POINT (18.020750312361056 59.2910679753198)",
      },
      LocationSignature: "Äsg",
      OfficialLocationName: "Älvsjö godsbangård",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Äskekärr",
      Geometry: {
        WGS84: "POINT (13.608936221931133 58.62605901819057)",
      },
      LocationSignature: "Äsr",
      OfficialLocationName: "Äskekärr",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ättekulla",
      Geometry: {
        WGS84: "POINT (12.767715494704804 56.02155848300656)",
      },
      LocationSignature: "Ätk",
      OfficialLocationName: "Ättekulla",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Älvsbyn",
      Geometry: {
        WGS84: "POINT (20.993327001954647 65.67824820325042)",
      },
      LocationSignature: "Äy",
      OfficialLocationName: "Älvsbyn",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Örtofta",
      Geometry: {
        WGS84: "POINT (13.242727367694334 55.77846920460445)",
      },
      LocationSignature: "Ö",
      OfficialLocationName: "Örtofta",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ökna",
      Geometry: {
        WGS84: "POINT (15.72512713883171 59.40123119913266)",
      },
      LocationSignature: "Öa",
      OfficialLocationName: "Ökna",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Österalnö",
      Geometry: {
        WGS84: "POINT (18.51497038587344 63.35513030868467)",
      },
      LocationSignature: "Öal",
      OfficialLocationName: "Österalnö",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Örebro Södra",
      Geometry: {
        WGS84: "POINT (15.202467158375073 59.269992557053094)",
      },
      LocationSignature: "Öb",
      OfficialLocationName: "Örebro södra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ösjöbol",
      Geometry: {
        WGS84: "POINT (15.250122469346081 57.19213003059749)",
      },
      LocationSignature: "Öbl",
      OfficialLocationName: "Ösjöbol",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Örabäcken",
      Geometry: {
        WGS84: "POINT (15.754715976214593 62.111340824466005)",
      },
      LocationSignature: "Öbn",
      OfficialLocationName: "Örabäcken",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ödåkra",
      Geometry: {
        WGS84: "POINT (12.745100556797613 56.10471250850341)",
      },
      LocationSignature: "Öda",
      OfficialLocationName: "Ödåkra",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Östra Grevie",
      Geometry: {
        WGS84: "POINT (13.134462058169955 55.46440567449536)",
      },
      LocationSignature: "Ög",
      OfficialLocationName: "Östra Grevie",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Övergård",
      Geometry: {
        WGS84: "POINT (17.31924052492959 63.16326792665398)",
      },
      LocationSignature: "Ögd",
      OfficialLocationName: "Övergård",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Örbyhus",
      Geometry: {
        WGS84: "POINT (17.703482816120193 60.22932528079214)",
      },
      LocationSignature: "Öh",
      OfficialLocationName: "Örbyhus",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Överhogdal",
      Geometry: {
        WGS84: "POINT (14.797685868461908 62.27792225202071)",
      },
      LocationSignature: "Öhl",
      OfficialLocationName: "Överhogdal",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Överhogdals grusgrop",
      Geometry: {
        WGS84: "POINT (14.823331744737931 62.23185509969483)",
      },
      LocationSignature: "Öhlg",
      OfficialLocationName: "Överhogdals grusgrop",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Östansjö",
      Geometry: {
        WGS84: "POINT (14.98133376536957 59.045629001609704)",
      },
      LocationSignature: "Öj",
      OfficialLocationName: "Östansjö",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Öjervik",
      Geometry: {
        WGS84: "POINT (13.122478360101043 59.773665351502444)",
      },
      LocationSignature: "Öjr",
      OfficialLocationName: "Öjervik",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Örnsköldsvik C",
      Geometry: {
        WGS84: "POINT (18.704691643493067 63.288447675720725)",
      },
      LocationSignature: "Ök",
      OfficialLocationName: "Örnsköldsviks central",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Örnsköldsvik norra",
      Geometry: {
        WGS84: "POINT (18.724879373534005 63.306090834562184)",
      },
      LocationSignature: "Ökn",
      OfficialLocationName: "Örnsköldsvik norra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Ölme",
      Geometry: {
        WGS84: "POINT (14.005410800638506 59.360892712012124)",
      },
      LocationSignature: "Öl",
      OfficialLocationName: "Ölme",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Önaskogen",
      Geometry: {
        WGS84: "POINT (15.121105516818247 58.62146931337175)",
      },
      LocationSignature: "Öna",
      OfficialLocationName: "Önaskogen",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Önnestad",
      Geometry: {
        WGS84: "POINT (14.016756119481466 56.06669123705537)",
      },
      LocationSignature: "Önd",
      OfficialLocationName: "Önnestad",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Örebro C",
      Geometry: {
        WGS84: "POINT (15.210956333462374 59.2782140091467)",
      },
      LocationSignature: "Ör",
      OfficialLocationName: "Örebro central",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Örsjö",
      Geometry: {
        WGS84: "POINT (15.749782017233676 56.6967277555331)",
      },
      LocationSignature: "Örs",
      OfficialLocationName: "Örsjö",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Östersund C",
      Geometry: {
        WGS84: "POINT (14.638147585139462 63.17002070733922)",
      },
      LocationSignature: "Ös",
      OfficialLocationName: "Östersunds central",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Ösmo",
      Geometry: {
        WGS84: "POINT (17.902643771807412 58.98451424987104)",
      },
      LocationSignature: "Öso",
      OfficialLocationName: "Ösmo",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Östersund Västra",
      Geometry: {
        WGS84: "POINT (14.631148538939899 63.17853809665549)",
      },
      LocationSignature: "Ösv",
      OfficialLocationName: "Östersunds västra",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Österås",
      Geometry: {
        WGS84: "POINT (17.184355651495373 63.2112806252443)",
      },
      LocationSignature: "Öså",
      OfficialLocationName: "Österås",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Östertälje",
      Geometry: {
        WGS84: "POINT (17.658394772630633 59.184989919946844)",
      },
      LocationSignature: "Öte",
      OfficialLocationName: "Östertälje",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Östavall",
      Geometry: {
        WGS84: "POINT (15.473193759598685 62.42930419030115)",
      },
      LocationSignature: "Öv",
      OfficialLocationName: "Östavall",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Överby",
      Geometry: {
        WGS84: "POINT (11.26002209308879 58.84355703902712)",
      },
      LocationSignature: "Övb",
      OfficialLocationName: "Överby",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Överum",
      Geometry: {
        WGS84: "POINT (16.32550497953805 57.982368325433434)",
      },
      LocationSignature: "Övm",
      OfficialLocationName: "Överum",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Överums Bruk",
      Geometry: {
        WGS84: "POINT (16.31950229864916 57.989751979329924)",
      },
      LocationSignature: "Övmb",
      OfficialLocationName: "Överums Bruk",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Östervärn",
      Geometry: {
        WGS84: "POINT (13.033559582580143 55.60681507312105)",
      },
      LocationSignature: "Övn",
      OfficialLocationName: "Östervärn",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Övsjö",
      Geometry: {
        WGS84: "POINT (15.986436805899796 62.96494429614953)",
      },
      LocationSignature: "Övö",
      OfficialLocationName: "Övsjö",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Öxnered",
      Geometry: {
        WGS84: "POINT (12.272577388536199 58.35678699965874)",
      },
      LocationSignature: "Öx",
      OfficialLocationName: "Öxnered",
    },
    {
      Advertised: false,
      AdvertisedLocationName: "Öreälv",
      Geometry: {
        WGS84: "POINT (19.343424648943145 63.79358778619285)",
      },
      LocationSignature: "Öä",
      OfficialLocationName: "Öreälv",
    },
    {
      Advertised: true,
      AdvertisedLocationName: "Österäng",
      Geometry: {
        WGS84: "POINT (13.55985044703901 58.634097100051186)",
      },
      LocationSignature: "Öäg",
      OfficialLocationName: "Österäng",
    },
  ],
};
