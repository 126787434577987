export class TrainSchedule {
    constructor() {
      this.AdvertisedTrainIdent = null;
      this.FromLocation = null;
      this.LocationSignature = null;
      this.LocationName = null;
      this.ToLocation = null;
      this.Deviations = null;
      this.ArrivalData = {};
      this.DepartureData = {};
      this.ArrivalState = {};
      this.DepartureState = {};
    }
  }